/* Global styles, variables, and utility classes */
:root {
  --bg-primary: #0a0e17;
  --bg-secondary: #1a1f2e;
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
  --accent-color: #00f0ff;
  --accent-hover: #33f5ff;
  --button-bg: rgba(0, 240, 255, 0.1);
  --button-border: rgba(0, 240, 255, 0.3);
  --card-bg: rgba(255, 255, 255, 0.05);
  --card-border: rgba(255, 255, 255, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.6;
}

/* Add these heading styles to ensure consistent alignment */
/* h1, h2, h3, .section-title, .page-title {
  text-align: center;
} */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

/* Button styles */
.btn {
  background-color: var(--button-bg);
  color: var(--accent-color);
  border: 1px solid var(--button-border);
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

.btn:hover {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

/* ... (other global styles) ... */
