.profile-wizard {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.wizard-progress {
  margin-bottom: 20px;
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.progress-bar {
  height: 10px;
  background-color: #1a1f2e;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #00f0ff;
  transition: width 0.3s ease;
}

.wizard-progress span {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: #00f0ff;
}

.wizard-nav {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: rgba(26, 31, 46, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  margin-bottom: 2rem;
}

.step-button {
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  white-space: normal;
  text-align: center;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-button:hover {
  background-color: #2980b9;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.step-button.active {
  border-color: #00f0ff;
  color: #0a0e17;
}

.wizard-step {
  width: 100%;
  padding: 1.5rem;
  background: rgba(26, 31, 46, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.category {
  margin-bottom: 20px;
}

.category h4 {
  margin-bottom: 10px;
  color: #00f0ff;
}

.category-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
}

.item-label {
  width: 100%;
  flex: none;
}

.item-label:hover {
  background-color: #00f0ff;
  color: #0a0e17;
}

.item-label.selected {
  background-color: #00f0ff;
  color: #0a0e17;
}

.item-label input {
  display: none;
}

.item-label span {
  margin-left: 5px;
}

.wizard-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.wizard-controls button {
  flex: 1;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  margin: 0 5px;
}

.wizard-controls button:hover {
  background-color: #2980b9;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.wizard-step input[type="text"],
.wizard-step input[type="email"],
.wizard-step input[type="tel"],
.wizard-step input[type="url"],
.wizard-step select,
.wizard-step textarea {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

.wizard-step input[type="file"] {
  margin-bottom: 15px;
  color: #a0a0a0;
}

.wizard-step select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%2300f0ff' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
}

.toggle-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.toggle-input label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-input input[type="checkbox"] {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .profile-wizard {
    padding: 1rem;
  }

  .wizard-nav {
    flex-direction: column;
  }

  .step-button {
    width: 100%;
  }

  .wizard-controls {
    flex-direction: column;
    gap: 10px;
  }

  .wizard-controls button {
    width: 100%;
    margin: 5px 0;
  }
}

/* Add these styles to your existing CSS file */

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #c82333;
}

/* Portfolio Gallery Button Styles */
.add-portfolio-btn {
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px dashed rgba(0, 240, 255, 0.3);
  border-radius: 8px;
  color: #00f0ff;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all 0.3s ease;
}

.add-portfolio-btn span {
  font-size: 1.5rem;
  line-height: 1;
}

.add-portfolio-btn:hover {
  background: rgba(0, 240, 255, 0.15);
  border-style: solid;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.1);
}

.add-portfolio-btn:active {
  transform: translateY(0);
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .add-portfolio-btn {
    padding: 0.875rem;
    font-size: 0.95rem;
  }
}

/* Small Screen Optimizations */
@media (max-width: 480px) {
  .add-portfolio-btn {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
  
  .add-portfolio-btn span {
    font-size: 1.25rem;
  }
}

.language-item,
.work-experience-item,
.portfolio-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-inputs {
  display: flex;
  gap: 10px;
}

.date-inputs input {
  flex: 1;
}

/* Adjust the WorldMap component size */
.wizard-step .world-map {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.map-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  margin-bottom: 20px;
}

.map-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.country-search {
  position: relative;
  margin-bottom: 20px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.countries-container {
  display: flex;
  gap: 20px;
}

.map-container {
  flex: 1;
  min-width: 0; /* Allow the container to shrink if needed */
  height: 400px; /* Fixed height for desktop */
}

.selected-countries-container {
  width: 250px;
  display: flex;
  flex-direction: column;
}

.country-search {
  margin-bottom: 20px;
}

.country-search input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-results {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  width: 250px;
  z-index: 1000;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.selected-countries {
  flex-grow: 1;
  overflow-y: auto;
}

.selected-countries h4 {
  margin-bottom: 10px;
}

.selected-countries ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.selected-countries li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.selected-countries button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.selected-countries button:hover {
  background-color: #ff3333;
}

@media (max-width: 768px) {
  .countries-container {
    flex-direction: column;
  }

  .map-container {
    height: 300px; /* Reduced height for mobile */
    overflow: hidden;
  }

  .map-container > div {
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable panning on mobile */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .selected-countries-container {
    width: 100%;
  }

  .search-results {
    width: 100%;
  }
}

.image-upload-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}

.image-upload {
  width: 100%;
}

.image-upload label {
  display: block;
  margin-bottom: 10px;
  color: #00f0ff;
}

.image-preview {
  width: 100%;
  height: 150px;
  border: 2px dashed var(--accent-color);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
  background-color: var(--bg-primary);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload input[type="file"] {
  display: none;
}

.image-upload .btn {
  display: inline-block;
  padding: 8px 15px;
  background-color: #00f0ff;
  color: #0a0e17;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.image-upload .btn:hover {
  background-color: #33f5ff;
}

/* Portfolio gallery styles */
.portfolio-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.portfolio-card {
  background-color: #1a1f2e;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 240, 255, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 240, 255, 0.2);
}

.portfolio-card-image {
  width: 100%;
  height: 150px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-placeholder {
  color: #fff;
}

.portfolio-card-details {
  padding: 15px;
  color: #a0a0a0;
  flex-grow: 1;
}

.portfolio-card-details h4 {
  color: #00f0ff;
  margin-bottom: 10px;
}

.portfolio-card-details a {
  color: #00f0ff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.portfolio-card-details a:hover {
  color: #33f5ff;
}

.portfolio-card-details .btn {
  margin-top: 10px;
  margin-right: 5px;
  padding: 8px 15px;
  background-color: #00f0ff;
  color: #0a0e17;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio-card-details .btn:hover {
  background-color: #33f5ff;
}

.portfolio-card-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Portfolio Modal Base Styles */
.edit-portfolio-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #1a1f2e;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Modal Content */
.portfolio-form {
  padding: 1.5rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.modal-header h3 {
  margin: 0;
  color: #00f0ff;
  font-size: 1.25rem;
}

/* Close Button */
.modal-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0.25rem;
  background: none;
  border: none;
  border-radius: 50%;
  color: #a0a0a0;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-close-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  transform: scale(1.1);
}

/* Scrollbar Styling */
.edit-portfolio-modal::-webkit-scrollbar {
  width: 8px;
}

.edit-portfolio-modal::-webkit-scrollbar-track {
  background: rgba(0, 240, 255, 0.05);
  border-radius: 4px;
}

.edit-portfolio-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 240, 255, 0.2);
  border-radius: 4px;
}

.edit-portfolio-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .edit-portfolio-modal {
    width: 95%;
    max-height: 95vh;
  }

  .portfolio-form {
    padding: 1rem;
  }

  .modal-header h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .edit-portfolio-modal {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  .portfolio-form {
    padding: 0.875rem;
  }
}

.countries-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.country-search {
  width: 100%;
  position: relative;
}

.country-search-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #3a3f4e;
  border-radius: 4px;
  background: #2a2f3e;
  color: #fff;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #2a2f3e;
  border: 1px solid #3a3f4e;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 0.5rem;
  cursor: pointer;
}

.search-result-item:hover {
  background: #3a3f4e;
}

.selected-countries {
  margin-top: 1rem;
}

.selected-countries-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style: none;
  padding: 0;
}

.selected-country-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #2a2f3e00;
  padding: 0.5rem;
  border: 1px solid #3a3f4e;
  border-radius: 15px;
}

.selected-country-item span {
  padding-left: 0.5rem;
  
}


.world-map {
  width: 100% !important;
  height: 100% !important;
  background: transparent;
}

/* Country label styles */
.country-label {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #00f0ff;
  font-size: 11px;
  padding: 0;
  text-align: center;
}

/* Remove old marker styles since we're not using them anymore */
.country-marker,
.marker-dot {
  display: none;
}

/* Map tile styling */
.world-map [class*="pigeon-tiles-"] {
  filter: contrast(80%) brightness(40%) saturate(0%);
}

/* Hover effect for countries */
.leaflet-interactive:hover {
  fill-opacity: 0.5 !important;
  cursor: pointer;
}

/* Pulsing effect for selected countries */
@keyframes countryPulse {
  0% {
    fill-opacity: 0.2;
  }
  50% {
    fill-opacity: 0.4;
  }
  100% {
    fill-opacity: 0.2;
  }
}

.selected-country {
  animation: countryPulse 2s infinite;
}

/* Update map section styles */
.map-section {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  background: transparent;
  margin-bottom: 20px;
  position: relative;
}

/* Remove old map styles that are no longer needed */
.world-map,
.country-marker,
.marker-dot {
  display: none;
}

/* Update selected countries list styles to match the new design */
.selected-countries-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.country-tag {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 16px;
  padding: 4px 12px;
  color: #00f0ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.country-tag:hover {
  background: rgba(0, 240, 255, 0.2);
}

.country-tag button {
  background: none;
  border: none;
  color: #00f0ff;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  opacity: 0.7;
}

.country-tag button:hover {
  opacity: 1;
}

/* Update search styles */
.country-search {
  margin: 20px 0;
  position: relative;
}

.country-search-input {
  width: 100%;
  padding: 12px;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 8px;
  color: #fff;
  transition: all 0.3s ease;
}

.country-search-input:focus {
  outline: none;
  border-color: #00f0ff;
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
}

.search-result-item:hover {
  background: rgba(0, 240, 255, 0.1);
}

/* Availability Section Styles */
.availability-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 10px;
}

.availability-status,
.rates-section,
.availability-types {
  background: rgba(26, 31, 46, 0.8);
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.availability-section h4 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

/* Rate Input Styles */
.rate-input-group {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 200px;
}

.rate-input {
  width: 100%;
  padding: 0.75rem;
  padding-right: 3.5rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 5px;
  color: #fff;
  font-size: 1.1rem;
}

.currency-symbol {
  position: absolute;
  right: 1rem;
  color: rgba(0, 240, 255, 0.7);
  font-size: 0.9rem;
}

/* Types Grid Styles */
.types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.type-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.type-checkbox:hover {
  border-color: rgba(0, 240, 255, 0.5);
  background: rgba(0, 240, 255, 0.1);
}

.type-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #00f0ff;
}

.type-checkbox span {
  color: #a0a0a0;
}

.type-checkbox input[type="checkbox"]:checked + span {
  color: #00f0ff;
}

@media (max-width: 768px) {
  .types-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .rate-input-group {
    max-width: 100%;
  }
}

/* Visibility Section Styles */
.visibility-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 10px;
}

.visibility-group {
  background: rgba(26, 31, 46, 0.8);
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.visibility-group h4 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.toggle-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
}

.visibility-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.visibility-toggle:hover {
  border-color: rgba(0, 240, 255, 0.5);
  background: rgba(0, 240, 255, 0.1);
}

.visibility-toggle input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #00f0ff;
}

.visibility-toggle span {
  color: #a0a0a0;
}

.visibility-toggle input[type="checkbox"]:checked + span {
  color: #00f0ff;
}

.cta-buttons {
  background: rgba(26, 31, 46, 0.8);
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.cta-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 5px;
  color: #fff;
  transition: all 0.3s ease;
}

.cta-input:focus {
  outline: none;
  border-color: #00f0ff;
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

/* Contact Section Image Fix */
.form-image {
  display: none; /* Remove the image since it's causing layout issues */
}

.form-content {
  width: 100%; /* Use full width */
}

.contact-form-container {
  display: block; /* Change to block layout */
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .toggle-grid {
    grid-template-columns: 1fr;
  }
  
  .visibility-group,
  .cta-buttons {
    padding: 1rem;
  }
}

/* Update work experience section styles */
.work-experience-item {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.work-experience-item:hover {
  background: rgba(0, 240, 255, 0.08);
  transform: translateX(5px);
}

.date-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.date-inputs input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
}

/* General form input styles update */
.wizard-step input[type="text"],
.wizard-step input[type="email"],
.wizard-step input[type="tel"],
.wizard-step input[type="url"],
.wizard-step input[type="month"],
.wizard-step select,
.wizard-step textarea {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

.wizard-step textarea {
  min-height: 120px;
  resize: vertical;
}

/* Update container width and padding */
.profile-wizard {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1.5rem;
}

.wizard-step {
  width: 100%;
  max-width: 100%;
  padding: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-wizard {
    padding: 1rem;
  }

  .wizard-step {
    padding: 1rem;
  }

  .date-inputs {
    grid-template-columns: 1fr;
  }

  .work-experience-item {
    padding: 1rem;
  }

  /* Make inputs larger on mobile for better touch targets */
  .wizard-step input[type="text"],
  .wizard-step input[type="email"],
  .wizard-step input[type="tel"],
  .wizard-step input[type="url"],
  .wizard-step input[type="month"],
  .wizard-step select,
  .wizard-step textarea {
    padding: 0.875rem;
    font-size: 1rem;
  }

  /* Adjust button sizes for better touch targets */
  .wizard-controls button {
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
  }
}

/* Update step navigation */
.wizard-nav {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 10px;
}

.step-button {
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  white-space: normal;
  text-align: center;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Update section headings */
.wizard-step h3 {
  color: #00f0ff;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  padding-bottom: 0.75rem;
}

/* Add these styles for better mobile experience */
@media (max-width: 480px) {
  .profile-wizard {
    padding: 0.75rem;
  }

  .wizard-step {
    padding: 0.75rem;
  }

  .step-button {
    font-size: 0.85rem;
    padding: 0.625rem;
  }

  .wizard-nav {
    grid-template-columns: 1fr;
  }

  .work-experience-item {
    margin-bottom: 1rem;
  }

  /* Increase touch targets even more for very small screens */
  .wizard-step input[type="text"],
  .wizard-step input[type="email"],
  .wizard-step input[type="tel"],
  .wizard-step input[type="url"],
  .wizard-step input[type="month"],
  .wizard-step select,
  .wizard-step textarea {
    padding: 1rem;
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }

  .wizard-controls {
    flex-direction: column;
    gap: 0.75rem;
  }

  .wizard-controls button {
    width: 100%;
    padding: 1rem;
  }
}

/* Add styles for better form organization */
.form-section {
  margin-bottom: 2rem;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #00f0ff;
  font-size: 0.95rem;
}

/* Update form input and button styles to match modal theme */
.wizard-step input[type="text"],
.wizard-step input[type="email"],
.wizard-step input[type="tel"],
.wizard-step input[type="url"],
.wizard-step input[type="month"],
.wizard-step select,
.wizard-step textarea {
  width: 100%;
  padding: 12px;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 8px;
  color: #fff;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  margin-bottom: 1rem;
}

.wizard-step input:focus,
.wizard-step select:focus,
.wizard-step textarea:focus {
  outline: none;
  border-color: #00f0ff;
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

.wizard-step label {
  display: block;
  color: #00f0ff;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

/* Update wizard controls to match modal buttons */
.wizard-controls {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
}

.wizard-controls button {
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  background: transparent;
  border: 1px solid #00f0ff;
  color: #00f0ff;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
}

.wizard-controls button:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

/* Update step navigation */
.wizard-nav {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(26, 31, 46, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.step-button {
  padding: 8px 16px;
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  color: #a0a0a0;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.step-button:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  transform: translateY(-2px);
}

.step-button.active {
  background: rgba(0, 240, 255, 0.1);
  border-color: #00f0ff;
  color: #00f0ff;
}

/* Update section headings */
.wizard-step h3 {
  color: #00f0ff;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

/* Update item labels (for skills, technologies, etc.) */
.item-label {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  padding: 8px 16px;
  color: #a0a0a0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.item-label:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  transform: translateY(-2px);
}

.item-label.selected {
  background: rgba(0, 240, 255, 0.1);
  border-color: #00f0ff;
  color: #00f0ff;
}

/* Update progress bar */
.wizard-progress {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.progress-bar {
  background: rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  height: 8px;
}

.progress {
  background: #00f0ff;
  height: 100%;
  transition: width 0.3s ease;
}

.wizard-progress span {
  display: block;
  text-align: center;
  margin-top: 0.75rem;
  color: #00f0ff;
  font-size: 0.9rem;
}

/* Update work experience items */
.work-experience-item {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.work-experience-item:hover {
  background: rgba(0, 240, 255, 0.05);
  transform: translateX(5px);
}

/* Add these styles for better mobile experience */
@media (max-width: 768px) {
  .wizard-nav {
    grid-template-columns: 1fr;
  }

  .wizard-controls {
    flex-direction: column;
  }

  .wizard-controls button {
    width: 100%;
    justify-content: center;
  }

  .item-label {
    width: 100%;
  }
}

/* Update container width and layout */
.profile-wizard {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem;
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

/* Update wizard step container */
.wizard-step {
  width: 100%;
  padding: 1.5rem;
  background: rgba(26, 31, 46, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

/* Portfolio Modal Styles */
.edit-portfolio-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1f2e;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.portfolio-form {
  padding: 1.5rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.modal-header h3 {
  margin: 0;
  color: #00f0ff;
  font-size: 1.25rem;
}

.modal-close-btn {
  background: none;
  border: none;
  color: #a0a0a0;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.25rem;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.modal-close-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  transform: scale(1.1);
}

/* Add scrollbar styling */
.edit-portfolio-modal::-webkit-scrollbar {
  width: 8px;
}

.edit-portfolio-modal::-webkit-scrollbar-track {
  background: rgba(0, 240, 255, 0.05);
  border-radius: 4px;
}

.edit-portfolio-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 240, 255, 0.2);
  border-radius: 4px;
}

.edit-portfolio-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.3);
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .edit-portfolio-modal {
    width: 95%;
    max-height: 95vh;
  }

  .portfolio-form {
    padding: 1rem;
  }

  .modal-header h3 {
    font-size: 1.1rem;
  }
}

/* Small Screen Optimizations */
@media (max-width: 480px) {
  .edit-portfolio-modal {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  .portfolio-form {
    padding: 0.875rem;
  }
}

/* Portfolio Modal Button Styles */
.portfolio-form .form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.portfolio-form .btn-save,
.portfolio-form .btn-cancel {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio-form .btn-save {
  background: #00f0ff;
  color: #0a0e17;
}

.portfolio-form .btn-save:hover {
  background: #33f5ff;
  transform: translateY(-2px);
}

.portfolio-form .btn-save:disabled {
  background: rgba(0, 240, 255, 0.3);
  cursor: not-allowed;
  transform: none;
}

.portfolio-form .btn-cancel {
  background: rgba(255, 255, 255, 0.1);
  color: #a0a0a0;
}

.portfolio-form .btn-cancel:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.portfolio-form .btn-save:active,
.portfolio-form .btn-cancel:active {
  transform: translateY(0);
}

/* Update personal details section */
.image-upload-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  width: 100%;
  margin: 1.5rem 0;
}

.image-upload {
  width: 100%;
}

.image-preview {
  width: 150px;
  height: 150px;
  margin: 1rem auto;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .profile-wizard {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
  }

  .wizard-step {
    padding: 1rem;
    border-radius: 0;
  }

  .image-upload-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .image-preview {
    width: 120px;
    height: 120px;
  }

  .image-instructions {
    margin: 0.5rem 0;
  }

  /* Adjust input fields for better mobile experience */
  .wizard-step input[type="text"],
  .wizard-step input[type="email"],
  .wizard-step input[type="tel"],
  .wizard-step input[type="url"],
  .wizard-step select,
  .wizard-step textarea {
    padding: 0.875rem;
    margin-bottom: 1rem;
  }

  /* Adjust availability toggle for mobile */
  .availability-toggle-container {
    margin: 1rem 0;
  }

  .toggle-switch-new {
    width: 100%;
  }

  /* Remove unnecessary margins and paddings */
  .wizard-nav,
  .wizard-progress,
  .wizard-controls {
    border-radius: 0;
    margin: 0;
    padding: 1rem;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .wizard-step {
    padding: 0.75rem;
  }

  .image-preview {
    width: 100px;
    height: 100px;
  }

  .image-instructions p {
    font-size: 0.75rem;
  }

  .wizard-step input[type="text"],
  .wizard-step input[type="email"],
  .wizard-step input[type="tel"],
  .wizard-step input[type="url"],
  .wizard-step select,
  .wizard-step textarea {
    padding: 0.75rem;
    font-size: 1rem;
  }
}

/* Ensure form elements take full width */
.wizard-step input,
.wizard-step select,
.wizard-step textarea {
  width: 100%;
  box-sizing: border-box;
}

/* Update navigation responsiveness */
.wizard-nav {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .wizard-nav {
    grid-template-columns: 1fr;
  }

  .step-button {
    width: 100%;
    margin: 0;
  }
}

/* Add these new styles */
.skills-limit-notice {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.skills-limit-notice svg {
  color: #00f0ff;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.skills-limit-notice p {
  color: #00f0ff;
  margin: 0;
  font-size: 0.95rem;
}

.skills-limit-notice .limit-reason {
  color: #a0a0a0;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

@media (max-width: 768px) {
  .skills-limit-notice {
    padding: 0.75rem;
  }
  
  .skills-limit-notice p {
    font-size: 0.9rem;
  }
  
  .skills-limit-notice .limit-reason {
    font-size: 0.8rem;
  }
}

/* Add these styles */
.loading-state,
.error-message,
.no-items-message {
  padding: 1rem;
  text-align: center;
  color: #a0a0a0;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.error-message {
  color: #ff4444;
  background: rgba(255, 68, 68, 0.05);
}

.category {
  margin-bottom: 2rem;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.02);
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.category h4 {
  color: #00f0ff;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.category-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.75rem;
}

.item-label {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.item-label:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  transform: translateY(-2px);
}

.item-label.selected {
  background: rgba(0, 240, 255, 0.1);
  border-color: #00f0ff;
  color: #00f0ff;
}

@media (max-width: 768px) {
  .category {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }

  .category-items {
    grid-template-columns: 1fr;
  }

  .item-label {
    padding: 0.625rem 0.875rem;
  }
}

/* Language Section Styles */
.languages-info {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.languages-info svg {
  color: #00f0ff;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.languages-info p {
  color: #00f0ff;
  margin: 0;
  font-size: 0.95rem;
}

.languages-info .info-detail {
  color: #a0a0a0;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.languages-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.language-item {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  position: relative;
  transition: all 0.3s ease;
}

.language-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.language-select,
.language-proficiency {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.language-item label {
  color: #00f0ff;
  font-size: 0.9rem;
}

.language-item select,
.language-item input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  color: #fff;
}

.language-details {
  margin-bottom: 2.5rem;
}

.remove-language-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.3);
  color: #ff4444;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
}

.add-language-btn {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .language-main {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .language-item {
    padding: 0.875rem;
    padding-bottom: 3.5rem;
  }

  .language-details {
    margin-bottom: 2rem;
  }

  .remove-language-btn {
    width: calc(100% - 2rem);
    text-align: center;
    bottom: 0.875rem;
  }
}

/* Small Screen Optimizations */
@media (max-width: 480px) {
  .language-item {
    padding: 0.75rem;
    padding-bottom: 3rem;
  }

  .language-item select,
  .language-item input {
    padding: 0.625rem;
  }

  .remove-language-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
  }

  .add-language-btn {
    padding: 0.625rem;
    font-size: 0.9rem;
  }
}

/* Work Experience Section Styles */
.work-experience-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.work-experience-item {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.work-experience-item:hover {
  background: rgba(0, 240, 255, 0.05);
  transform: translateX(5px);
}

.experience-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.job-title-input,
.company-input {
  width: 100%;
}

.date-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.date-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-input-group label {
  color: #00f0ff;
  font-size: 0.9rem;
}

.month-year-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.month-input {
  width: 60px;
}

.year-input {
  width: 80px;
}

.date-separator {
  color: #00f0ff;
  font-size: 1.2rem;
}

.currently-working {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  color: #a0a0a0;
  font-size: 0.9rem;
}

.currently-working input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #00f0ff;
}

.experience-description {
  width: 100%;
  min-height: 100px;
  margin-bottom: 3rem;
  resize: vertical;
}

.remove-experience-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.3);
  color: #ff4444;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.remove-experience-btn:hover {
  background: rgba(255, 68, 68, 0.2);
  transform: translateY(-2px);
}

.add-experience-btn {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.add-experience-btn:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .experience-header,
  .date-section {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .work-experience-item {
    padding: 1rem;
    padding-bottom: 4rem;
  }

  .remove-experience-btn {
    width: calc(100% - 2rem);
    text-align: center;
    bottom: 1rem;
  }
}

/* Small Screen Optimizations */
@media (max-width: 480px) {
  .work-experience-item {
    padding: 0.875rem;
    padding-bottom: 3.5rem;
  }

  .month-year-input {
    gap: 0.25rem;
  }

  .month-input {
    width: 50px;
  }

  .year-input {
    width: 70px;
  }
}

/* Section Notice Styles */
.section-notice {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.section-notice svg {
  color: #00f0ff;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.section-notice p {
  color: #00f0ff;
  margin: 0;
  font-size: 0.95rem;
}

.notice-details {
  color: #a0a0a0 !important;
  font-size: 0.85rem !important;
  margin-top: 0.25rem !important;
}

.notice-title {
  font-weight: 500;
  margin-bottom: 0.25rem !important;
}

@media (max-width: 768px) {
  .section-notice {
    padding: 0.75rem;
  }
  
  .section-notice p {
    font-size: 0.9rem;
  }
  
  .notice-details {
    font-size: 0.8rem !important;
  }
}

/* Update image instructions styling */
.image-instructions {
  background: rgba(0, 240, 255, 0.05);
  padding: 0.6rem;
  border-radius: 6px;
  margin: 0.5rem 0;
}

.image-instructions p {
  color: #a0a0a0;
  font-size: 0.75rem;
  margin: 0.2rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Availability Section Improvements */
.availability-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 0;
}

.availability-status,
.rates-section,
.availability-types {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
}

.availability-status h4,
.rates-section h4,
.availability-types h4 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  padding-bottom: 0.5rem;
}

.rate-input-group {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  padding: 0.5rem;
}

.rate-input {
  width: 100%;
  padding: 0.5rem;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.1rem;
}

.currency-symbol {
  color: rgba(0, 240, 255, 0.7);
  padding: 0 0.75rem;
  font-size: 0.9rem;
  border-left: 1px solid rgba(0, 240, 255, 0.2);
}

.types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.75rem;
}

.type-checkbox {
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.type-checkbox:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.type-checkbox input[type="checkbox"] {
  accent-color: #00f0ff;
}

.type-checkbox span {
  color: #a0a0a0;
  font-size: 0.9rem;
}

.type-checkbox input:checked + span {
  color: #00f0ff;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .portfolio-gallery {
    grid-template-columns: 1fr;
  }

  .portfolio-card-details {
    padding: 1rem;
  }

  .availability-section {
    grid-template-columns: 1fr;
  }

  .availability-card {
    padding: 1rem;
  }

  .availability-types {
    grid-template-columns: 1fr;
  }
}

/* Small Screen Optimizations */
@media (max-width: 480px) {
  .portfolio-card-image {
    height: 180px;
  }

  .portfolio-card-actions {
    flex-direction: column;
  }

  .rate-input-group {
    padding: 0.375rem 0.75rem;
  }

  .rate-input {
    font-size: 1rem;
  }
}

/* Contact Section Styles */
.input-instructions {
  margin-top: 0.5rem;
}

.format-example {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00f0ff;
  font-size: 0.85rem;
  margin-bottom: 0.25rem;
}

.format-example svg {
  font-size: 1rem;
}

.field-note {
  display: block;
  color: #a0a0a0;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

.contact-section {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.contact-section h4 {
  color: #00f0ff;
  margin-bottom: 1.25rem;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.form-group {
  position: relative;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00f0ff;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: #00f0ff;
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .contact-section {
    padding: 1rem;
  }

  .contact-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .format-example {
    font-size: 0.8rem;
  }

  .field-note {
    font-size: 0.75rem;
  }
}

/* Update Countries Section Styles */
.countries-container {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
}

.country-search-section {
  position: relative;
  margin-bottom: 1.5rem;
}

.country-search-input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  color: #fff;
  transition: all 0.3s ease;
}

.country-search-input:focus {
  border-color: #00f0ff;
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.search-result-item {
  padding: 0.75rem 1rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-result-item:hover {
  background: rgba(0, 240, 255, 0.1);
}

.selected-countries h4 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.selected-countries-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.country-tag {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 20px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00f0ff;
  font-size: 0.9rem;
}

.country-tag button {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.country-tag button:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .countries-container {
    padding: 1rem;
  }

  .country-tag {
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
  }
}

/* Analytics Section Styles */
.analytics-section {
  position: relative;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 1rem;
  min-height: 300px;
}

.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 31, 46, 0.95);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.coming-soon-content {
  text-align: center;
  color: #00f0ff;
  padding: 2rem;
}

.coming-soon-content svg {
  margin-bottom: 1rem;
  filter: drop-shadow(0 0 10px rgba(0, 240, 255, 0.3));
}

.coming-soon-content h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #00f0ff;
}

.coming-soon-content p {
  color: #a0a0a0;
  font-size: 1rem;
}

.analytics-content {
  opacity: 0.1;
  pointer-events: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .analytics-section {
    padding: 1rem;
    min-height: 250px;
  }

  .coming-soon-content {
    padding: 1.5rem;
  }

  .coming-soon-content h3 {
    font-size: 1.2rem;
  }

  .coming-soon-content p {
    font-size: 0.9rem;
  }
}

/* Testimonials Section Styles */
.testimonials-section,
.awards-section {
  position: relative;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 1rem;
  min-height: 300px;
}

.testimonials-content,
.awards-content {
  opacity: 0.1;
  pointer-events: none;
}

/* Common Coming Soon Overlay Styles */
.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(26, 31, 46, 0.95);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 12px;
}

.coming-soon-content {
  text-align: center;
  color: #00f0ff;
  padding: 2rem;
}

.coming-soon-content svg {
  margin-bottom: 1rem;
  filter: drop-shadow(0 0 10px rgba(0, 240, 255, 0.3));
}

.coming-soon-content h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #00f0ff;
}

.coming-soon-content p {
  color: #a0a0a0;
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .testimonials-section,
  .awards-section {
    padding: 1rem;
    min-height: 250px;
  }

  .coming-soon-content {
    padding: 1.5rem;
  }

  .coming-soon-content h3 {
    font-size: 1.2rem;
  }

  .coming-soon-content p {
    font-size: 0.9rem;
  }
}

/* Modal Button Styles */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
}

.modal-btn {
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 6px;
}

.modal-btn-primary {
  background: transparent;
  border: 1px solid #00f0ff;
  color: #00f0ff;
}

.modal-btn-primary:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.modal-btn-cancel {
  background: transparent;
  border: 1px solid #ff4444;
  color: #ff4444;
}

.modal-btn-cancel:hover {
  background: rgba(255, 68, 68, 0.1);
  transform: translateY(-2px);
}

/* File Upload Button Styles */
.upload-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid #00f0ff;
  border-radius: 6px;
  color: #00f0ff;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.upload-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.upload-btn-icon {
  font-size: 1.1rem;
}

/* File Input Helper Text */
.file-helper-text {
  color: #a0a0a0;
  font-size: 0.85rem;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .modal-actions {
    flex-direction: column;
    gap: 8px;
  }

  .modal-btn,
  .upload-btn {
    width: 100%;
    justify-content: center;
    padding: 10px;
  }
}


