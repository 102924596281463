.feedback-modal {
  position: relative;
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border-radius: 15px;
  padding: 32px;
  width: 90%;
  max-width: 600px;
  max-height: 85vh;
  overflow-y: auto;
  box-shadow: 0 0 30px rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 240, 255, 0.3) rgba(0, 0, 0, 0.2);
}

/* Clean scrollbar styles */
.feedback-modal::-webkit-scrollbar {
  width: 6px;
}

.feedback-modal::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.feedback-modal::-webkit-scrollbar-thumb {
  background-color: rgba(0, 240, 255, 0.3);
  border-radius: 3px;
}

.feedback-modal.success {
  text-align: center;
  padding: 40px;
}

.success-message h2 {
  color: #00f0ff;
  font-size: 28px;
  margin-bottom: 16px;
}

.success-message p {
  color: #a0a0a0;
  font-size: 16px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #00f0ff;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.close-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: rotate(90deg);
  opacity: 1;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.feedback-form h2 {
  color: #00f0ff;
  font-size: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #00f0ff;
  padding-bottom: 12px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: #00f0ff;
  margin-bottom: 8px;
}

.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  transition: all 0.3s ease;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 240, 255, 0.3) rgba(0, 0, 0, 0.2);
}

.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #00f0ff;
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
}

.ratings-section {
  background: rgba(0, 240, 255, 0.05);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.ratings-section label {
  color: #00f0ff;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

.ratings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.rating-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rating-item span {
  color: #a0a0a0;
  font-size: 0.9rem;
}

.star-rating {
  display: flex;
  gap: 4px;
}

.star {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
}

.star:hover {
  transform: scale(1.2);
}

.star.active {
  color: #00f0ff;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
}

.screenshot-section {
  background: rgba(0, 240, 255, 0.05);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.screenshot-section label {
  color: #00f0ff;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

.screenshot-buttons {
  display: flex;
  gap: 12px;
}

.screenshot-buttons button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.screenshot-buttons button:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.screenshot-preview {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(0, 240, 255, 0.2);
  background: rgba(10, 14, 23, 0.8);
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.screenshot-preview::-webkit-scrollbar {
  display: none;
}

.screenshot-preview img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.screenshot-preview button {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.screenshot-preview button:hover {
  background: rgba(0, 0, 0, 0.95);
  transform: translateY(-2px);
}

.submit-btn {
  width: 100%;
  padding: 14px;
  background: #00f0ff;
  color: #0a0e17;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-top: 24px;
}

.submit-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.submit-btn:disabled {
  background: rgba(0, 240, 255, 0.2);
  cursor: not-allowed;
}

/* Header feedback button styles */
.header .feedback-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-right: 16px;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.1);
  overflow: hidden;
}

.header .feedback-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(0, 240, 255, 0.1) 0%, transparent 70%);
  transform: rotate(45deg);
  transition: all 0.5s ease;
  z-index: 1;
}

.header .feedback-button svg {
  font-size: 20px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0 0 5px rgba(0, 240, 255, 0.5));
}

.header .feedback-button:hover {
  transform: translateY(-2px);
  border-color: #00f0ff;
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.2), rgba(0, 240, 255, 0.1));
}

.header .feedback-button:hover::before {
  transform: rotate(180deg);
}

.header .feedback-button:hover svg {
  transform: scale(1.1);
  filter: drop-shadow(0 0 8px rgba(0, 240, 255, 0.8));
}

.header .feedback-button::after {
  content: "Share Feedback";
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(0, 240, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.header .feedback-button:hover::after {
  opacity: 1;
  visibility: visible;
  bottom: -40px;
}

/* Pulse animation for the feedback button */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 240, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 240, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 240, 255, 0);
  }
}

.header .feedback-button {
  animation: pulse 2s infinite;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5vh;
  overflow: hidden;
}

@media (max-width: 768px) {
  .feedback-modal {
    width: 95%;
    padding: 24px;
  }

  .ratings-grid {
    grid-template-columns: 1fr;
  }

  .screenshot-buttons {
    flex-direction: column;
  }

  .header .feedback-button {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }

  .header .feedback-button svg {
    font-size: 18px;
  }
}

/* Update header actions styles */
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}

/* Profile icon styles */
.header .profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
  background: none;
  cursor: pointer;
}

.header .profile-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Mobile header styles */
@media (max-width: 768px) {
  .header-actions {
    gap: 12px;
  }

  /* Make all action buttons uniform */
  .header .feedback-button,
  .header .mobile-menu-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.05));
    border: 1px solid rgba(0, 240, 255, 0.3);
    padding: 0;
    margin: 0;
  }

  /* Uniform icon sizes */
  .header .feedback-button svg,
  .header .mobile-menu-button svg {
    font-size: 20px;
  }

  /* Remove any margins that might affect spacing */
  .header .feedback-button,
  .header .profile-menu,
  .header .mobile-menu-button {
    margin: 0;
  }

  /* Container for mobile buttons */
  .header .header-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 8px;
  }

  /* Hide tooltip on mobile */
  .header .feedback-button::after {
    display: none;
  }
}

/* For any textareas in the form */
.feedback-form textarea {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 240, 255, 0.3) rgba(0, 0, 0, 0.2);
}

.feedback-form textarea::-webkit-scrollbar {
  width: 6px;
}

.feedback-form textarea::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.feedback-form textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 240, 255, 0.3);
  border-radius: 3px;
}

/* Remove unnecessary height constraints */
.feedback-form,
.feedback-modal {
  height: auto;
}

/* Prevent body scroll when modal is open */
body.modal-open {
  overflow: hidden;
} 