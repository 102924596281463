.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  background-color: var(--bg-primary);
  padding: 2rem;
}

.register-card {
  background-color: var(--bg-secondary);
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 240, 255, 0.1);
  width: 100%;
  max-width: 480px;
}

.register-title {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.error-message,
.success-message {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.error-message {
  background: rgba(255, 56, 96, 0.1);
  color: #ff3860;
  border: 1px solid rgba(255, 56, 96, 0.2);
}

.success-message {
  background: rgba(0, 240, 255, 0.1);
  color: var(--accent-color);
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.signup-steps {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.invitation-code-section,
.google-signin-section {
  width: 100%;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.invitation-code-wrapper {
  position: relative;
}

.form-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--card-border);
  border-radius: 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(0, 240, 255, 0.1);
  outline: none;
}

.form-input.valid {
  border-color: var(--accent-color);
  padding-right: 100px;
}

.verification-badge {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--accent-color);
  font-size: 0.875rem;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 4px 8px;
  border-radius: 4px;
}

.checkmark {
  color: var(--accent-color);
  font-weight: bold;
}

.form-tip {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.btn {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.btn-primary {
  background-color: var(--accent-color);
  color: var(--bg-primary);
  border: none;
}

.btn-primary:hover:not(:disabled) {
  background-color: var(--accent-hover);
  transform: translateY(-1px);
}

.btn-google {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--card-border);
}

.btn-google:hover:not(:disabled) {
  background-color: var(--bg-secondary);
  border-color: var(--accent-color);
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.request-form-section {
  background: var(--bg-primary);
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid var(--card-border);
}

.request-form-section h3 {
  color: var(--accent-color);
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
}

.form-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}

.btn-secondary:hover {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

.register-footer {
  margin-top: 2rem;
  text-align: center;
  color: var(--text-secondary);
}

.login-link {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-link:hover {
  color: var(--accent-hover);
}

.link-button {
  background: none;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  padding: 0;
  font: inherit;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.link-button:hover {
  color: var(--accent-hover);
}

@media (max-width: 768px) {
  .register-container {
    padding: 1rem;
  }

  .register-card {
    padding: 1.5rem;
  }

  .register-title {
    font-size: 2rem;
  }

  .form-buttons {
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.register-card {
  animation: fadeIn 0.3s ease-out;
}