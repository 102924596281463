.invitation-management {
  padding: 2rem;
}

.management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.management-header h2 {
  color: #00f0ff;
  font-size: 1.5rem;
  margin: 0;
}

.status-tabs {
  display: flex;
  gap: 1rem;
}

.tab-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 0.95rem;
  padding: 0.5rem 1rem;
  transition: color 0.2s;
}

.tab-btn:hover {
  color: rgba(255, 255, 255, 0.8);
}

.select-all {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Request Cards */
.request-card {
  background: rgba(13, 17, 23, 0.4);
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 24px auto 1fr 1fr 1fr 100px;
  align-items: center;
  gap: 2rem;
}

input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.request-name {
  color: #00f0ff;
}

.request-actions {
  display: flex;
  gap: 1rem;
  justify-self: end;
}

.action-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 0.25rem;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
}

.action-btn.approve {
  color: rgba(46, 204, 113, 0.6);
}

.action-btn.approve:hover {
  color: #2ecc71;
}

.action-btn.reject {
  color: rgba(231, 76, 60, 0.6);
}

.action-btn.reject:hover {
  color: #e74c3c;
}

.loading-state {
  text-align: center;
  padding: 2rem;
  color: rgba(255, 255, 255, 0.7);
}
