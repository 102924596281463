.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--bg-primary);
    padding: 20px;
  }
  
  .login-card {
    background-color: var(--bg-secondary);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 240, 255, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .login-title {
    color: var(--accent-color);
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  
  .alert {
    background-color: #dc3545;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    color: var(--text-secondary);
    margin-bottom: 5px;
    display: block;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: var(--bg-primary);
    color: var(--text-secondary);
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary {
    background-color: var(--accent-color);
    color: var(--bg-primary);
  }
  
  .btn-primary:hover {
    background-color: var(--accent-hover);
  }
  
  .divider {
    margin: 15px 0;
    color: var(--text-secondary);
    position: relative;
  }
  
  .divider span {
    background-color: var(--bg-secondary);
    padding: 0 10px;
    position: relative;
    z-index: 1;
  }
  
  .divider::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #333;
    z-index: 0;
  }
  
  .google-signin {
    margin-bottom: 15px;
  }
  
  .btn-google {
    background-color: #db4437;
    color: white;
  }
  
  .btn-google:hover {
    background-color: #c33d2e;
  }
  
  .google-icon {
    margin-right: 10px;
  }
  
  .login-footer {
    color: var(--text-secondary);
  }
  
  .signup-link {
    color: var(--accent-color);
    text-decoration: none;
  }
  
  .signup-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .login-card {
      padding: 20px;
    }
  
    .login-title {
      font-size: 1.5rem;
    }
  }
  
  .spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .error-message {
    color: #ff4444;
    background: rgba(255, 68, 68, 0.1);
    border: 1px solid rgba(255, 68, 68, 0.3);
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    text-align: center;
  }
  
  .success-message {
    color: #00f0ff;
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.3);
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    text-align: center;
  }
  
  .request-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #00f0ff;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  
  .close-button:hover {
    background: rgba(0, 240, 255, 0.1);
    transform: rotate(90deg);
  }
  
  .request-form {
    position: relative;
    margin-top: 20px;
    padding: 15px;
    background: rgba(0, 240, 255, 0.1);
    border-radius: 8px;
    border: 1px solid rgba(0, 240, 255, 0.2);
  }
  
  .invitation-input-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .invitation-input-group .form-input {
    flex: 1;
  }
  
  .btn-submit {
    background-color: #00f0ff;
    color: #0a0e17;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-submit:hover {
    background-color: #33f5ff;
  }
  
  .btn-submit:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: rgba(0, 240, 255, 0.5);
  }
  
  .btn-submit .spinner {
    width: 14px;
    height: 14px;
    border: 2px solid rgba(10, 14, 23, 0.3);
    border-top-color: #0a0e17;
    margin: 0;
  }