/* Modal Base */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.9);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  z-index: 9999;
}

.modal {
  background: #1a1f2e;
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
}

.modal-content {
  padding: 2rem;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.25rem;
  cursor: pointer;
  transition: color 0.2s;
}

.close-button:hover {
  color: #fff;
}

/* Form Header */
.modal-content h2 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  padding-right: 2rem;
}

/* Service Status Toggle */
.status-toggle {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 2rem;
}

.status-toggle > label {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: block;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.toggle-switch {
  position: relative;
  width: 44px;
  height: 22px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 22px;
  transition: 0.3s;
}

.toggle-slider:before {
  content: '';
  position: absolute;
  /* height: 18px;
  width: 18px; */
  left: 2px;
  bottom: 2px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

input:checked + .toggle-slider {
  background: rgba(0, 240, 255, 0.5);
}

input:checked + .toggle-slider:before {
  transform: translateX(22px);
  background: #00f0ff;
}

.toggle-label {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
}

.toggle-hint {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  margin-top: 0.75rem;
}

/* Form Groups */
.form-group {
  margin-bottom: 1.75rem;
}

.form-group > label {
  display: block;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.field-hint {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  margin-top: 0.35rem;
}

.required {
  color: #00f0ff;
  margin-left: 0.25rem;
}

/* Form Controls */
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0.875rem 1rem;
  color: #fff;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: rgba(0, 240, 255, 0.4);
  background: rgba(0, 0, 0, 0.3);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
  line-height: 1.5;
}

/* Form Footer */
.modal-footer {
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 1rem;
}

.btn-submit,
.btn-cancel {
  padding: 0.875rem 1.5rem;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-submit {
  background: #00f0ff;
  color: #1a1f2e;
  border: none;
  flex: 1;
}

.btn-cancel {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-submit:hover {
  background: #33f5ff;
}

.btn-cancel:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* Responsive */
@media (max-width: 640px) {
  .modal-overlay {
    padding: 1rem;
  }
  
  .modal-content {
    padding: 1.5rem;
  }
  
  .modal-footer {
    flex-direction: column;
  }
  
  .btn-submit,
  .btn-cancel {
    width: 100%;
  }
}

.service-card .btn {
  min-width: -webkit-fill-available;
  min-width: fit-content;
  justify-content: center;
} 