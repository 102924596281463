/* Global Styles */
:root {
  --bg-primary: #0a0e17;
  --bg-secondary: #1a1f2e;
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
  --accent-color: #00f0ff;
  --accent-hover: #33f5ff;
  --button-bg: rgba(0, 240, 255, 0.1);
  --button-border: rgba(0, 240, 255, 0.3);
  --card-bg: rgba(255, 255, 255, 0.05);
  --card-border: rgba(255, 255, 255, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--bg-secondary);
  padding: 0.5rem 0;
  transition: all 0.3s ease;
}

.header.scrolled {
  background-color: rgba(26, 31, 46, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  max-width: 200px; /* Increased from 40px */
  height: auto; /* Changed from fixed height to auto */
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-color);
}

.nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.nav a {
  color: var(--text-primary);
  text-decoration: none;
  margin-left: 1.5rem;
  transition: color 0.3s ease;
  font-weight: 500;
  position: relative;
}

.nav a:hover,
.nav a.active {
  color: var(--accent-color);
}

.nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--accent-color);
  transition: width 0.3s ease;
}

.nav a:hover::after,
.nav a.active::after {
  width: 100%;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 1.5rem;
  cursor: pointer;
}

/* Profile Menu Styles */
.profile-menu {
  position: relative;
  margin-left: auto; /* Add this line to align the profile menu to the right */
}

.profile-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-color);
  transition: transform 0.3s ease;
}

.profile-icon:hover .profile-image {
  transform: scale(1.1);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--card-border);
  border-radius: 8px;
  padding: 1rem;
  z-index: 1000;
  min-width: 200px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.dropdown-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--card-border);
}

.dropdown-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.dropdown-user-info {
  display: flex;
  flex-direction: column;
}

.dropdown-username {
  font-weight: bold;
  color: var(--text-primary);
}

.dropdown-email {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: var(--text-primary);
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.dropdown-item:hover {
  background-color: var(--bg-primary);
}

.dropdown-item svg {
  margin-right: 0.5rem;
}

.logout-button {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  font-size: 1rem;
  padding: 0.5rem;
}

.login-button {
  background-color: var(--accent-color);
  color: var(--bg-primary);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: var(--accent-hover);
}

/* Footer Styles */
.footer {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 2rem 0;
  margin-top: auto;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-color);
}

.footer-copyright {
  color: var(--text-secondary);
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-social a {
  color: var(--text-primary);
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: var(--accent-color);
}

/* Responsive Footer */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo {
    margin-bottom: 1rem;
  }

  .footer-copyright {
    margin: 1rem 0;
  }

  .footer-social {
    margin-top: 1rem;
  }
}



@media (min-width: 769px) {
  .mobile-menu-button {
    display: none;
  }

  .nav {
    display: flex !important;
  }
}

/* Main Content Styles */
main {
  flex: 1;
  padding: 4rem 0;
}

.page-title {
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-align: center;
  color: var(--accent-color);
}

/* Button Styles */
.btn {
  background-color: var(--button-bg);
  color: var(--accent-color);
  border: 1px solid var(--button-border);
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--accent-color);
  transition: all 0.3s ease;
  z-index: -1;
}

.btn:hover {
  color: var(--bg-primary);
  border-color: var(--accent-color);
}

.btn:hover::before {
  width: 100%;
}

.btn-danger {
  background-color: #ff3860;
  color: #fff;
  border-color: #ff3860;
}

.btn-danger:hover {
  background-color: #ff1e45;
  border-color: #ff1e45;
}

/* Card Styles */
.card {
  background-color: var(--card-bg);
  border-radius: 15px;
  padding: 2rem;
  border: 1px solid var(--card-border);
  transition: all 0.3s ease;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

/* Grid Layouts */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Animation Keyframes */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Apply animations */
.fade-in {
  animation: fadeIn 1s ease-out;
}

/* Section Styles */
.section {
  margin-bottom: 4rem;
  animation: fadeIn 1s ease-out;
  padding: 4rem 0;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--text-primary);
}

/* Footer Styles */
.footer {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 2rem 0;
  margin-top: auto;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: var(--accent-hover);
}

/* Custom Scrollbar */
/* ::-webkit-scrollbar {
  width: 8px;
} */

::-webkit-scrollbar-track {
  background: var(--bg-primary);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--accent-hover);
}

/* Form Styles */
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

.error {
  color: #ff3860;
  margin-bottom: 1rem;
}

/* ... (rest of the styles) ... */

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.subtitle {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

/* Add these styles to your existing styles.css file */

.admin-nav {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.admin-content {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}

/* Add these styles to the end of your existing styles.css file */

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-primary);
}

.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1rem;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Add these styles to the end of your existing styles.css file */

.user-management table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-management th, .user-management td {
  border: 1px solid var(--card-border);
  padding: 10px;
  text-align: left;
}

.user-management th {
  background-color: var(--bg-secondary);
}

.user-management button {
  padding: 5px 10px;
  background-color: var(--button-bg);
  color: var(--accent-color);
  border: 1px solid var(--button-border);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-management button:hover {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

/* Add these styles to your existing styles.css file */

/* Admin Dashboard Layout */
.admin-dashboard {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.admin-layout {
  display: flex;
  flex: 1;
}

.admin-sidebar {
  width: 200px;
  background-color: var(--bg-secondary);
  padding: 20px;
}

.admin-content {
  flex: 1;
  padding: 20px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.admin-sidebar .btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: var(--bg-secondary);
}

th, td {
  border: 1px solid var(--card-border);
  padding: 10px;
  text-align: left;
}

th {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
textarea {
  padding: 10px;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

/* Sticky Footer */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.footer {
  margin-top: auto;
}

/* Add or update these styles in your styles.css file */

.admin-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-nav {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.admin-nav .btn {
  flex: 1;
  min-width: 150px;
  text-align: center;
}

.admin-content {
  background-color: var(--bg-secondary);
  padding: 20px;
  border-radius: 5px;
}

.content-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--bg-primary);
  border-radius: 5px;
}

.content-section h3 {
  margin-bottom: 10px;
}

.content-section textarea {
  width: 100%;
  min-height: 150px;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
}


.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* Event styles */
.event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.event-countdown {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-color);
  margin: 10px 0;
}

.event-completed {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff3860;
  margin: 10px 0;
}

.card.completed {
  opacity: 0.7;
  position: relative;
}

.card.completed::after {
  content: 'Completed';
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff3860;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
}

.card .btn {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* File input styling */
.file-input-label {
  display: inline-block;
  padding: 10px 15px;
  background-color: var(--button-bg);
  color: var(--accent-color);
  border: 1px solid var(--button-border);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-input-label:hover {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

.file-input {
  display: none;
}

.file-name {
  margin-left: 10px;
  font-style: italic;
}

/* Event card styling */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

.event-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.event-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image {
  transform: scale(1.05);
}

.completed-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.event-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.event-date,
.event-location {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.event-description {
  flex-grow: 1;
  margin-bottom: 1rem;
}

.event-links {
  display: flex;
  gap: 1rem;
}

.event-countdown {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-color);
  margin: 10px 0;
}

.event-completed {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff3860;
  margin: 10px 0;
}

/* Event Management Form Styles */
.event-form {
  max-width: 800px;
  margin: 0 auto 2rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.form-group textarea {
  min-height: 100px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

/* Event Card Styles */
.event-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.event-description {
  flex-grow: 1;
}

.event-links {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.event-btn {
  flex: 1;
  text-align: center;
}

/* Event Management Form Styles */
.event-form {
  max-width: 100%;
  margin-bottom: 2rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

/* Add these styles to your existing styles.css file */

/* Event date and time styling */
.event-datetime {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: var(--accent-color);
}

.event-datetime i {
  margin-right: 10px;
}

.event-date {
  font-weight: bold;
}

/* Admin dashboard home styling */
.admin-home {
  text-align: center;
}

.admin-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.stat-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  margin-bottom: 10px;
  color: var(--accent-color);
}

.stat-card p {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.stat-card .btn {
  width: 100%;
}

/* Add these styles to your existing styles.css file */

.profile-menu {
  position: relative;
}

.profile-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
}

.dropdown-menu a,
.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 5px 10px;
  text-align: left;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover,
.dropdown-menu button:hover {
  background-color: var(--bg-primary);
}

/* App Layout */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 2rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Header Styles */
.header {
  background-color: var(--bg-secondary);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-color);
  text-decoration: none;
}

.nav {
  display: flex;
  align-items: center;
}

.nav a {
  color: var(--text-primary);
  text-decoration: none;
  margin-left: 1.5rem;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: var(--accent-color);
}

/* Profile Icon Styles */
.profile-menu {
  position: relative;
}

.profile-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--accent-color);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-secondary);
  border: 1px solid var(--card-border);
  border-radius: 4px;
  padding: 0.5rem;
  z-index: 1000;
  min-width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu a,
.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  text-align: left;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover,
.dropdown-menu button:hover {
  background-color: var(--bg-primary);
}

/* ... (rest of the existing styles) ... */

/* User Dashboard Styles */
.user-dashboard {
  max-width: 1200px;
  margin-top: 3rem;
  padding: 2rem;
}

.user-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.user-nav .btn {
  flex: 1;
  /* margin: 0 0.5rem; */
}

.user-content {
  background-color: var(--bg-secondary);
  /* padding: 2rem; */
  border-radius: 8px;
}

/* User Services Styles */
.service-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.price-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.service-card {
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.service-price {
  font-weight: bold;
  color: var(--accent-color);
}

.service-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

/* Profile Editor Styles */
.profile-editor {
  max-width: 800px;
  margin: 0 auto;
}

.profile-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.form-group {
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.public-profile-link {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.public-profile-link input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

/* User Dashboard Styles */
.user-home {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-completion {
  background-color: var(--bg-secondary);
  padding: 1rem;
  border-radius: 8px;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: var(--bg-primary);
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: var(--accent-color);
  transition: width 0.5s ease-in-out;
}

.dashboard-summary {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.summary-card {
  flex: 1;
  min-width: 250px;
  background-color: var(--bg-secondary);
  padding: 1rem;
  border-radius: 8px;
}

.summary-card h3 {
  margin-bottom: 1rem;
}

.summary-card ul {
  list-style-type: none;
  padding: 0;
}

.summary-card li {
  margin-bottom: 0.5rem;
}

.quick-actions {
  background-color: var(--bg-secondary);
  padding: 1rem;
  border-radius: 8px;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.public-profile-section {
  background-color: var(--bg-secondary);
  padding: 1rem;
  border-radius: 8px;
}

.public-profile-link {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.public-profile-link input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.btn-google {
  background-color: #4285F4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btn-google:hover {
  background-color: #357ae8;
}

.btn-google::before {
  content: '';
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
}

.google-signin {
  margin-top: 1rem;
}

/* Add these styles to your existing styles.css file */

/* User Dashboard Styles */
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.dashboard-column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-list, .product-list {
  list-style-type: none;
  padding: 0;
}

.event-item, .product-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

/* User Services Styles */
.service-actions {
  display: flex;
  gap: 1rem;
}

.btn-block {
  width: 100%;
}

/* Public Profile Styles */
.public-profile {
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.profile-header {
  position: relative;
  height: 300px;
}

.profile-cover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.profile-info {
  position: absolute;
  bottom: -75px;
  left: 50px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid var(--bg-secondary);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.profile-content {
  padding: 100px 50px 50px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}

.about h1 {
  margin-bottom: 10px;
  color: var(--text-primary);
  font-size: 2.5rem;
}

.about .profession {
  color: var(--accent-color);
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* ... (rest of the styles remain the same) ... */

/* About page styles */
.about {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.about h1, .about h2 {
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.about ul {
  list-style-type: none;
  padding-left: 1rem;
}

.about li::before {
  content: "•";
  color: var(--accent-color);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Contact page styles */
.contact {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.contact h1 {
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.contact form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact .form-group {
  display: flex;
  flex-direction: column;
}

.contact input, .contact textarea {
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.contact textarea {
  min-height: 150px;
}

/* Blog page styles */
.blog {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.blog h1 {
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.blog-post {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.blog-post h2 {
  color: var(--accent-color);
  margin-bottom: 0.5rem;
}

.post-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.read-more {
  display: inline-block;
  margin-top: 1rem;
  color: var(--accent-color);
  text-decoration: none;
}

.read-more:hover {
  text-decoration: underline;
}

.welcome-video {
  margin: 2rem 0;
  text-align: center;
}

.welcome-video h2 {
  margin-bottom: 1rem;
}

.welcome-video iframe {
  max-width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.welcome-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.welcome-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.welcome-video .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.welcome-video .play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.welcome-video.playing .play-button {
  display: none;
}

/* Add these styles to your existing styles.css file */

.cta-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.mission-vision {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.mission, .vision {
  flex: 1;
}

.stats-grid, .offerings-grid, .contact-grid, .faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.stat-item, .offering-item, .contact-item, .faq-item {
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.stat-icon, .contact-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.offering-item ul {
  list-style-type: none;
  padding: 0;
}

.offering-item li {
  margin-bottom: 0.5rem;
}

.cta-section {
  background-color: var(--accent-color);
  color: var(--bg-primary);
  text-align: center;
  padding: 4rem 0;
}

.cta-section .btn {
  margin-top: 1rem;
  background-color: var(--bg-primary);
  color: var(--accent-color);
}

.contact-form form {
  max-width: 600px;
  margin: 0 auto;
}


/* CTA Section Styles */
.cta-section {
  background: linear-gradient(135deg, var(--accent-color), #ff00ff);
  color: var(--bg-primary);
  padding: 6rem 0;
  overflow: hidden;
}

.cta-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cta-content {
  flex: 1;
  padding-right: 2rem;
}

.cta-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--bg-primary);
}

.cta-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  font-size: 1.2rem;
  padding: 1rem 2rem;
  background-color: var(--bg-primary);
  color: var(--accent-color);
  border: none;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: var(--text-primary);
  color: var(--bg-primary);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-icon {
  margin-left: 0.5rem;
}

.cta-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cta-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.cta-img:hover {
  transform: scale(1.05);
}


.connected-future {
  background-color: var(--bg-secondary);
  padding: 6rem 0;
}

.connected-future .section-title {
  text-align: center;
  margin-bottom: 2rem;
}

.connected-future .section-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.mission-vision-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.mission-vision-card {
  background-color: var(--bg-primary);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.mission-vision-card h3 {
  color: var(--accent-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.mission-vision-card p {
  font-size: 1rem;
  line-height: 1.6;
}

.connected-future-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.feature {
  text-align: center;
}

.feature-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.feature h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.feature p {
  font-size: 0.9rem;
}


/* ... (rest of the styles remain the same) */

/* Hero Section Styles */
.hero {
    position: relative;
    background: linear-gradient(135deg, #000000, #02131f);
    background-size: 400% 400%;
    animation: gradientMove 15s ease infinite;
    padding: 8rem 0;
    overflow: hidden;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    box-sizing: border-box;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        radial-gradient(circle at 20% 50%, rgba(62, 146, 204, 0.2) 0%, transparent 50%),
        radial-gradient(circle at 80% 30%, rgba(10, 36, 99, 0.4) 0%, transparent 50%);
    z-index: 1;
}

.hero::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle, rgba(255,255,255,0.15) 1px, transparent 1px) 0 0 / 50px 50px,
                radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px) 25px 25px / 50px 50px;
    background-size: 50px 50px;
    animation: gridMove 20s linear infinite;
    z-index: 1;
}

@keyframes gridMove {
    0% { transform: translate(0, 0); }
    100% { transform: translate(50px, 50px); }
}

.hero .container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    width: calc(100% - (100vw - 100%));
    margin-top: 30px;
}

.post-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.read-more {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: bold;
}

.read-more:hover {
  text-decoration: underline;
}

.welcome-video {
  margin: 2rem 0;
  text-align: center;
}

.welcome-video h2 {
  margin-bottom: 1rem;
}

.welcome-video iframe {
  max-width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.welcome-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.welcome-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.welcome-video .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.welcome-video .play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.welcome-video.playing .play-button {
  display: none;
}

/* Add these styles to your existing styles.css file */

.cta-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.mission-vision {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.mission, .vision {
  flex: 1;
}

.stats-grid, .offerings-grid, .contact-grid, .faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.stat-item, .offering-item, .contact-item, .faq-item {
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
}

.stat-icon, .contact-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.offering-item ul {
  list-style-type: none;
  padding: 0;
}

.offering-item li {
  margin-bottom: 0.5rem;
}

.cta-section {
  background-color: var(--accent-color);
  color: var(--bg-primary);
  text-align: center;
  padding: 4rem 0;
}

.cta-section .btn {
  margin-top: 1rem;
  background-color: var(--bg-primary);
  color: var(--accent-color);
}

.contact-form form {
  max-width: 600px;
  margin: 0 auto;
}


/* CTA Section Styles */
.cta-section {
  background: linear-gradient(135deg, var(--accent-color), #ff00ff);
  color: var(--bg-primary);
  padding: 6rem 0;
  overflow: hidden;
}

.cta-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cta-content {
  flex: 1;
  padding-right: 2rem;
}

.cta-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--bg-primary);
}

.cta-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  font-size: 1.2rem;
  padding: 1rem 2rem;
  background-color: var(--bg-primary);
  color: var(--accent-color);
  border: none;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: var(--text-primary);
  color: var(--bg-primary);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-icon {
  margin-left: 0.5rem;
}

.cta-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cta-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.cta-img:hover {
  transform: scale(1.05);
}


.connected-future {
  background-color: var(--bg-secondary);
  padding: 6rem 0;
}

.connected-future .section-title {
  text-align: center;
  margin-bottom: 2rem;
}

.connected-future .section-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.mission-vision-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.mission-vision-card {
  background-color: var(--bg-primary);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.mission-vision-card h3 {
  color: var(--accent-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.mission-vision-card p {
  font-size: 1rem;
  line-height: 1.6;
}

.connected-future-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.feature {
  text-align: center;
}

.feature-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.feature h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.feature p {
  font-size: 0.9rem;
}


/* ... (rest of the styles remain the same) */

/* Hero Section Styles */
.hero {
    position: relative;
    background: linear-gradient(135deg, #000000, #02131f);
    background-size: 400% 400%;
    animation: gradientMove 15s ease infinite;
    padding: 8rem 0;
    overflow: hidden;
    width: 99vw;
    margin-left: calc(-50vw + 50%);
    box-sizing: border-box;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        radial-gradient(circle at 20% 50%, rgba(62, 146, 204, 0.2) 0%, transparent 50%),
        radial-gradient(circle at 80% 30%, rgba(10, 36, 99, 0.4) 0%, transparent 50%);
    z-index: 1;
}

.hero::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: radial-gradient(circle, rgba(255,255,255,0.15) 1px, transparent 1px) 0 0 / 50px 50px,
                radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px) 25px 25px / 50px 50px;
    background-size: 50px 50px;
    animation: gridMove 20s linear infinite;
    z-index: 1;
}

@keyframes gridMove {
    0% { transform: translate(0, 0); }
    100% { transform: translate(50px, 50px); }
}

.hero .container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    width: calc(100% - (100vw - 100%));
}

.hero .section-title {
    font-size: 4rem;
    font-weight: 800;
    color: #ffffff;
    margin-bottom: 1rem;
    text-shadow: 0 0 10px rgba(62, 146, 204, 0.5), 0 0 20px rgba(62, 146, 204, 0.3), 0 0 30px rgba(62, 146, 204, 0.2);
    animation: titleGlow 2s ease-in-out infinite alternate;
}

@keyframes titleGlow {
    from { text-shadow: 0 0 10px rgba(62, 146, 204, 0.5), 0 0 20px rgba(62, 146, 204, 0.3), 0 0 30px rgba(62, 146, 204, 0.2); }
    to { text-shadow: 0 0 20px rgba(62, 146, 204, 0.8), 0 0 30px rgba(62, 146, 204, 0.6), 0 0 40px rgba(62, 146, 204, 0.4); }
}

.hero .subtitle {
    font-size: 1.5rem;
    color: #e0e0e0;
    max-width: 800px;
    margin: 0 auto 3rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.hero .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.hero .btn {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.hero .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    transition: all 0.5s ease;
    z-index: -1;
}

.hero .btn:hover::before {
    left: 100%;
}

.hero .btn-primary {
    background-color: #00f0ff;
    color: #0a2463;
    box-shadow: 0 0 20px rgba(0, 240, 255, 0.5);
}

.hero .btn-secondary {
    background-color: transparent;
    color: #00f0ff;
    border: 2px solid #00f0ff;
    box-shadow: 0 0 20px rgba(0, 240, 255, 0.3);
}

.hero .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 0 30px rgba(0, 240, 255, 0.7);
}

.hero .container {
    animation: float 6s ease-in-out infinite;
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .header-container {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
  
    .nav {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: var(--bg-secondary);
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
    }
  
    .nav.active {
      display: flex;
    }
  
    .nav a {
      margin: 0.5rem 0;
    }
  
    .mobile-menu-button {
      display: block;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--text-primary);
      padding: 0.5rem;
    }
  
    .profile-menu {
      margin-right: 0.5rem;
    }
  
    .profile-icon {
      order: -1;
      margin-left: 0;
      margin-right: 1rem;
    }
  
    .dropdown-menu {
      margin-right: 0.5rem;
    }
  
    .grid {
      grid-template-columns: 1fr;
    }
  
    .footer .container {
      flex-direction: column;
      gap: 1rem;
    }
  
    /* Hero section adjustments */
    .hero {
      padding: 6rem 0;
      margin-top: 3rem;
      width: 100%;
      margin-left: 0;
    }
  
    .hero .container {
      width: 100%;
      padding: 0 15px;
    }
  
    .hero .section-title {
      font-size: 3rem;
    }
  
    .hero .subtitle {
      font-size: 1.2rem;
    }
  
    .hero .cta-buttons {
      flex-direction: column;
      align-items: center;
    }
  
    .hero .btn {
      width: 100%;
      max-width: 300px;
    }
  
    /* Other section adjustments */
    .section {
      padding: 2rem 0;
    }
  
    .card-grid {
      grid-template-columns: 1fr;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    /* Admin section adjustments */
    .admin-nav {
      flex-direction: column;
    }
  
    .admin-nav .btn {
      width: 100%;
    }
  
    /* Mission and vision section adjustments */
    .mission-vision,
    .cta-buttons {
      flex-direction: column;
    }
  
    .mission-vision-grid,
    .connected-future-features {
      grid-template-columns: 1fr;
    }
  
    /* CTA section adjustments */
    .cta-container {
      flex-direction: column;
      text-align: center;
    }
  
    .cta-content {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  
    .cta-image {
      justify-content: center;
    }
  }

/* Mobile Menu Styles */
@media (max-width: 768px) {
  .nav {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--bg-secondary);
    border: 1px solid var(--card-border);
    border-radius: 8px;
    padding: 1rem;
    z-index: 1000;
    min-width: 200px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
  }

  .nav.active {
    display: flex;
    flex-direction: column;
  }

  .nav a {
    color: var(--text-primary);
    text-decoration: none;
    padding: 0.5rem;
    margin: 0;
    transition: background-color 0.3s ease;
  }

  .nav a:hover {
    background-color: var(--bg-primary);
  }

  .mobile-menu-button {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--text-primary);
    padding: 0.5rem;
  }
}

/* ... (rest of the styles) ... */

/* User Dashboard Styles */
.user-dashboard {
  padding: 2rem 0;
}

.user-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.user-nav .btn {
  flex: 1;
  min-width: 120px;
}

.user-content {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  /* padding: 2rem; */
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.dashboard-column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.card {
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 1.5rem;
}

.progress-bar {
  background-color: var(--bg-secondary);
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  margin: 1rem 0;
}

.progress {
  background-color: var(--accent-color);
  height: 100%;
  transition: width 0.3s ease;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.event-list, .product-list {
  list-style-type: none;
  padding: 0;
}

.event-item, .product-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .user-nav {
    flex-direction: column;
  }

  .user-nav .btn {
    width: 100%;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons .btn {
    width: 100%;
  }
}

/* Blog styles */
.blog-page {
  padding: 2rem 0;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.blog-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.blog-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.blog-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  font-weight: 600;
}

.blog-snippet {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: var(--text-secondary);
  flex-grow: 1;
}

.read-more-btn {
  align-self: flex-start;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: var(--accent-color);
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 0.9rem;
}

.read-more-btn:hover {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

.post-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 2rem;
}

.post-preview {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 2rem;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: var(--accent-color);
}

.post-title {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 0.5rem;
}

.post-date {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.post-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.post-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-primary);
}

.post-paragraph {
  margin-bottom: 1rem;
}

.post-content a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.post-content a:hover {
  color: var(--accent-hover);
  text-decoration: underline;
}

.cta-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--accent-color);
  color: var(--bg-primary);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--accent-hover);
}

.loading, .error {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
  color: var(--text-primary);
}

/* Responsive design for blog */
@media (max-width: 768px) {
  .blog-grid {
    grid-template-columns: 1fr;
  }

  .post-preview {
    padding: 1.5rem;
    margin: 1rem;
  }

  .post-title {
    font-size: 2rem;
  }
}

.user-content {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  /* padding: 2rem; */
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.dashboard-column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.card {
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 1.5rem;
}

.progress-bar {
  background-color: var(--bg-secondary);
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  margin: 1rem 0;
}

.progress {
  background-color: var(--accent-color);
  height: 100%;
  transition: width 0.3s ease;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.event-list, .product-list {
  list-style-type: none;
  padding: 0;
}

.event-item, .product-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .user-nav {
    flex-direction: column;
  }

  .user-nav .btn {
    width: 100%;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons .btn {
    width: 100%;
  }
}

/* Rich Text Editor Styles */
.ql-container {
  min-height: 200px;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: var(--bg-secondary);
}

.ql-toolbar {
  background: var(--bg-primary);
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.ql-editor {
  min-height: 200px;
  font-size: 1rem;
  color: var(--text-primary);
}

.ql-editor.ql-blank::before {
  color: var(--text-secondary);
}

/* Adjust other form styles if needed */
.post-management form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.post-management input,
.post-management .ql-container {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

/* ... (rest of the styles) ... */

/* Post Management Styles */
.post-management {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.post-management-layout {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.post-form-section {
  flex: 2;
}

.post-tools-section {
  flex: 1;
}

.tool-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tool-card h4 {
  margin-bottom: 1rem;
  color: var(--accent-color);
}

.tool-card input,
.tool-card textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--card-border);
  border-radius: 4px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 1rem;
}

.tool-card textarea {
  min-height: 100px;
  resize: vertical;
}

.tool-card .btn {
  width: 100%;
}

.posts-table-section {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-responsive {
  overflow-x: auto;
}

.posts-table {
  width: 100%;
  border-collapse: collapse;
}

.posts-table th,
.posts-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--card-border);
}

.posts-table th {
  background-color: var(--bg-primary);
  color: var(--accent-color);
  font-weight: bold;
}

.posts-table tr:hover {
  background-color: var(--bg-primary);
}

.posts-table .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.btn-edit {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

.btn-delete {
  background-color: #ff3860;
  color: #fff;
}

@media (max-width: 768px) {
  .post-management-layout {
    flex-direction: column;
  }

  .post-form-section,
  .post-tools-section {
    width: 100%;
  }
}

/* Contact Form Section Styles */
.contact-form-section {
  margin-bottom: 1rem;
}

.contact-form-container {
  display: flex;
  background-color: var(--bg-secondary);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-content {
  flex: 1;
  /* padding: 3rem; */
}

.form-image {
  flex: 1;
  background-color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.form-image img {
  max-width: 100%;
  height: auto;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
}

.contact-form-section .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
  }

  .form-content,
  .form-image {
    width: 100%;
  }

  .form-row {
    flex-direction: column;
  }
}

/* FAQ Styles */
.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  overflow: hidden;
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 1rem;
  background-color: var(--bg-secondary);
  border: none;
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: var(--bg-primary);
}

.faq-icon {
  transition: transform 0.3s ease;
}

.faq-icon.rotated {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 1rem;
  background-color: var(--bg-primary);
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.6;
}

/* Adjust spacing for better readability */
.contact-content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.section-title {
  margin-bottom: 2rem;
}

.contact-form-container {
  margin-bottom: 2rem;
}

/* Profile Page Styles */
.profile-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-primary);
}

.profile-header {
  position: relative;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, var(--bg-primary), var(--bg-secondary));
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.profile-cover {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.profile-header:hover .cover-image {
  transform: scale(1.05);
}

.edit-cover-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-cover-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.profile-info {
  display: flex;
  margin-top: -75px;
  padding: 0 2rem 2rem;
}

.profile-picture-container {
  position: relative;
  margin-right: 2rem;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid var(--bg-secondary);
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.3);
  transition: transform 0.3s ease;
}

.profile-picture:hover {
  transform: scale(1.05);
}

.edit-profile-picture {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--accent-color);
  color: var(--bg-primary);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-profile-picture:hover {
  background-color: var(--accent-hover);
  transform: scale(1.1);
}

.profile-details {
  flex: 1;
}

.profile-name {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.profile-title {
  font-size: 1.2rem;
  color: var(--accent-color);
  margin-bottom: 0.5rem;
}

.profile-location {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.profile-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.profile-stats {
  display: flex;
  gap: 2rem;
}

.stat-item {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-color);
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.profile-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
}

.profile-main {
  background-color: var(--bg-secondary);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.tab-btn {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.tab-btn.active {
  background-color: var(--accent-color);
  color: var(--bg-primary);
}

.animated-section {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.skill-item {
  background-color: var(--bg-primary);
  border-radius: 20px;
  padding: 0.5rem 1rem;
  position: relative;
  overflow: hidden;
  animation: slideIn 0.5s ease forwards;
  opacity: 0;
}

@keyframes slideIn {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

.skill-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 240, 255, 0.2);
  z-index: 1;
}

.skill-item span {
  position: relative;
  z-index: 2;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.service-card {
  background-color: var(--bg-primary);
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 2rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.timeline {
  position: relative;
  padding: 2rem 0;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: var(--accent-color);
}

.timeline-item {
  margin-bottom: 2rem;
  position: relative;
}

.timeline-content {
  background-color: var(--bg-primary);
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
  width: calc(50% - 30px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.timeline-item:nth-child(odd) .timeline-content {
  left: 0;
}

.timeline-item:nth-child(even) .timeline-content {
  left: calc(50% + 30px);
}

.timeline-content::before {
  content: '';
  position: absolute;
  top: 20px;
  width: 20px;
  height: 20px;
  background-color: var(--accent-color);
  border-radius: 50%;
}

.timeline-item:nth-child(odd) .timeline-content::before {
  right: -40px;
}

.timeline-item:nth-child(even) .timeline-content::before {
  left: -40px;
}

.fun-fact {
  background-color: var(--bg-primary);
  border-radius: 15px;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.fun-fact-icon {
  font-size: 2rem;
  color: var(--accent-color);
}

/* ... (rest of the styles) ... */

/* ... previous styles ... */

/* Business Card Styles */
.business-card {
  background: linear-gradient(135deg, var(--bg-secondary), var(--bg-primary));
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

.business-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.business-card-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--accent-color);
  margin-right: 1.5rem;
}

.business-card-info {
  flex: 1;
}

.business-card-name {
  font-size: 1.8rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.business-card-title {
  font-size: 1.2rem;
  color: var(--accent-color);
}

.business-card-body {
  margin-bottom: 1.5rem;
}

.business-card-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.contact-info svg {
  /* margin-right: 0.5rem; */
  color: var(--accent-color);
}

/* Updated Skills Styles */
.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.skill-item {
  background-color: var(--bg-secondary);
  border-radius: 30px;
  padding: 0.75rem 1.5rem;
  position: relative;
  overflow: hidden;
  animation: slideIn 0.5s ease forwards;
  opacity: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.skill-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 240, 255, 0.2);
  z-index: 1;
  border-radius: 30px;
}

.skill-name {
  position: relative;
  z-index: 2;
  font-weight: 600;
  color: var(--text-primary);
}

/* Updated Services Styles */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.service-card {
  background-color: var(--bg-secondary);
  border-radius: 15px;
  padding: 2rem;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-right: 1.5rem;
  background-color: rgba(0, 240, 255, 0.1);
  padding: 1rem;
  border-radius: 50%;
}

.service-content {
  flex: 1;
}

.service-content h3 {
  font-size: 1.4rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.service-content p {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.6;
}

/* ... rest of the styles ... */

/* Find and update the .section-title style */
.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center; /* Add this line to ensure center alignment */
  color: var(--text-primary);
}

/* Also ensure page-title remains centered */
.page-title {
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-align: center;
  color: var(--accent-color);
}