.badge-management {
  padding: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.badge-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background: rgba(0, 240, 255, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.badge-stats h2 {
  color: #00f0ff;
  margin: 0 0 0.8rem 0;
  font-size: 1.5rem;
}

.stats-pills {
  display: flex;
  gap: 1rem;
}

.stat-pill {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.create-badge-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  background: #00f0ff;
  color: #0a0e17;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.create-badge-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.badge-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.search-filter {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.search-wrapper, .filter-wrapper {
  position: relative;
  flex: 1;
}

.search-icon, .filter-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #00f0ff;
  opacity: 0.7;
}

.badge-search, .category-filter {
  width: 100%;
  padding: 0.8rem 2.5rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  font-size: 0.9rem;
  color: #fff;
  transition: all 0.3s ease;
}

.badge-search:focus, .category-filter:focus {
  outline: none;
  border-color: #00f0ff;
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.2);
}

.view-toggle {
  display: flex;
  gap: 0.5rem;
}

.view-btn {
  padding: 0.6rem 1.2rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-btn.active {
  background: rgba(0, 240, 255, 0.2);
  border-color: #00f0ff;
}

.badges-container {
  display: grid;
  gap: 1.5rem;
}

.badges-container.grid {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.badges-container.list {
  grid-template-columns: 1fr;
}

.badge-card {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.badge-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.badge-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.badge-icon {
  font-size: 2.5rem;
  color: #00f0ff;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
}

.badge-title {
  flex: 1;
}

.badge-title h3 {
  color: #00f0ff;
  margin: 0;
  font-size: 1.2rem;
}

.badge-category {
  display: inline-block;
  font-size: 0.8rem;
  color: #a0a0a0;
  background: rgba(0, 240, 255, 0.1);
  padding: 0.2rem 0.8rem;
  border-radius: 20px;
  margin-top: 0.5rem;
}

.badge-description {
  color: #a0a0a0;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
}

.badge-actions {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}

.btn-edit, .btn-delete {
  flex: 1;
  padding: 0.8rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.btn-edit {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.btn-delete {
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
  border: 1px solid rgba(255, 68, 68, 0.2);
}

.btn-edit:hover {
  background: rgba(0, 240, 255, 0.2);
}

.btn-delete:hover {
  background: rgba(255, 68, 68, 0.2);
}

.no-results {
  text-align: center;
  padding: 3rem;
  color: #a0a0a0;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

@media (max-width: 1024px) {
  .badge-management-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .stats-pills {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .badge-controls {
    flex-direction: column;
  }

  .search-filter {
    flex-direction: column;
  }

  .view-toggle {
    width: 100%;
  }

  .view-btn {
    flex: 1;
  }

  .badges-container.grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .badge-management {
    padding: 1rem;
  }

  .stats-pills {
    flex-direction: column;
    align-items: center;
  }

  .badge-card {
    padding: 1rem;
  }
} 