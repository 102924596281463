.profile-wizard-mascot {
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #0a0e17;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
}

.profile-wizard-mascot::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    #00ffff,
    #ff00ff,
    #00ff00,
    #ff00ff,
    #00ffff
  );
  animation: gradientBG 15s ease infinite;
  background-size: 400% 400%;
  z-index: 1;
  opacity: 0.2;
}

.mascot-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 1.5rem;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #00f0ff;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.8);
  flex-shrink: 0;
}

.profile-placeholder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(0, 240, 255, 0.1);
  border: 3px solid rgba(0, 240, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 240, 255, 0.5);
  flex-shrink: 0;
}

/* Apply bounce animation only to mascot logo */
.profile-wizard-mascot .profile-image,
.profile-wizard-mascot .profile-placeholder {
  animation: bounce 2s ease infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-15px); }
  60% { transform: translateY(-7px); }
}

.mascot-content p {
  font-size: 24px;
  color: #00ffff;
  margin: 0;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
  text-align: left;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .profile-image,
  .profile-placeholder {
    width: 60px;
    height: 60px;
  }

  .mascot-content p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .mascot-content {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .mascot-content p {
    text-align: center;
    font-size: 16px;
  }
}
