.world-map-container {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: #0a0e17;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.globe-container {
  width: 100%;
  height: 450px;
}

.leaflet-container {
  background-color: #0a0e17 !important;
  width: 100% !important;
  height: 450px !important;
}

.country-tooltip {
  background: rgba(10, 14, 23, 0.95) !important;
  border: 1px solid rgba(0, 240, 255, 0.3) !important;
  border-radius: 6px !important;
  padding: 4px 8px !important;
  font-size: 12px !important;
  color: #00f0ff !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
}

.country-tooltip.selected {
  background: rgba(0, 240, 255, 0.15) !important;
  border-color: #00f0ff !important;
  font-weight: 500 !important;
}

.map-loading {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0a0e17;
  color: #00f0ff;
  font-size: 16px;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.leaflet-tile-container img {
  filter: contrast(90%) brightness(30%) saturate(80%) hue-rotate(180deg);
}

@media (max-width: 768px) {
  .leaflet-container {
    height: 350px !important;
  }
}