/* Header styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--bg-primary);
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0;
}

.logo-image {
  max-width: 200px;
  height: auto;
}

.nav {
  display: flex;
  align-items: center;
}

.nav a {
  color: var(--text-primary);
  text-decoration: none;
  margin-left: 1.5rem;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: var(--accent-color);
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
}

/* Mobile styles */
@media (max-width: 768px) {
  .nav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--bg-secondary);
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .nav.active {
    display: flex;
  }

  .nav a {
    margin: 0.5rem 0;
  }

  .mobile-menu-button {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.05));
    border: 1px solid rgba(0, 240, 255, 0.3);
    color: #00f0ff;
    cursor: pointer;
  }
}

/* Header Actions */
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}

/* Common button styles for header buttons */
.header .feedback-button,
.header .login-button {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
  margin: 0;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.1);
  overflow: hidden;
  text-decoration: none;
}

/* Button hover effects */
.header .feedback-button:hover,
.header .mobile-menu-button:hover,
.header .login-button:hover {
  transform: translateY(-2px);
  border-color: #00f0ff;
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.2), rgba(0, 240, 255, 0.1));
}

/* Icon styles */
.header .feedback-button svg,
.header .mobile-menu-button svg,
.header .login-button svg {
  font-size: 20px;
  transition: all 0.3s ease;
  filter: drop-shadow(0 0 5px rgba(0, 240, 255, 0.5));
}

/* Profile icon styles */
.header .profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
  background: none;
  cursor: pointer;
}

.header .profile-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Mobile styles */
@media (max-width: 768px) {
  .header-actions {
    gap: 12px;
    padding: 0 8px;
  }

  .header .feedback-button,
  .header .mobile-menu-button,
  .header .login-button {
    width: 40px;
    height: 40px;
  }

  .header .feedback-button svg,
  .header .mobile-menu-button svg,
  .header .login-button svg {
    font-size: 20px;
  }
}

/* ... (other header styles) ... */

.theme-toggle-button {
  background: none;
  border: none;
  color: var(--text-primary);
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.theme-toggle-button:hover {
  background-color: var(--bg-secondary);
  transform: scale(1.1);
}

.theme-toggle-button svg {
  font-size: 1.2rem;
}

.header.scrolled {
  background-color: var(--bg-primary);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
}

.dropdown-item {
  color: var(--text-primary);
}

.dropdown-item:hover {
  background-color: var(--bg-secondary);
}

/* Add these styles after the .nav styles */
.member-count {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  padding: 4px 12px;
  background: linear-gradient(135deg, rgba(0, 240, 255, 0.1), rgba(0, 240, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 20px;
  color: #00f0ff;
  font-size: 0.9rem;
}

.member-count span {
  white-space: nowrap;
}

/* Update mobile styles */
@media (max-width: 768px) {
  .member-count {
    margin: 0.5rem 0;
    align-self: center;
  }
}
