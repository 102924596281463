.globe-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #0a0e17;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 240, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.globe-container canvas {
  width: 100% !important;
  height: 100% !important;
  outline: none;
  background: transparent !important;
}

.world-map-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 12px;
  overflow: hidden;
}

/* Glowing border effect */
.globe-container::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(
    45deg,
    rgba(0, 240, 255, 0.1) 0%,
    rgba(0, 240, 255, 0.2) 50%,
    rgba(0, 240, 255, 0.1) 100%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  pointer-events: none;
}

.globe-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0a0e17;
  color: #00f0ff;
  font-size: 16px;
}

/* Marker pulse animation */
.marker-pulse {
  animation: markerPulse 2s ease-out infinite;
}

@keyframes markerPulse {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}

.marker-pulse-inner {
  animation: pulseInner 3s ease-out infinite;
}

.marker-pulse-outer {
  animation: pulseOuter 3s ease-out infinite;
  animation-delay: 0.2s;
}

.marker-pulse-outer-wide {
  animation: pulseOuterWide 3s ease-out infinite;
  animation-delay: 0.4s;
}

.marker-pulse-wave {
  animation: pulseWave 3s ease-out infinite;
  animation-delay: 0.6s;
}

@keyframes pulseInner {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulseOuter {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes pulseOuterWide {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes pulseWave {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
