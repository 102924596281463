.user-management {
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
}

.management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.header-left h2 {
  color: #00f0ff;
  margin: 0;
  font-size: 1.2rem;
}

.quick-stats {
  display: flex;
  gap: 1rem;
}

.quick-stats span {
  font-size: 0.8rem;
  color: #a0a0a0;
}

.add-user-btn {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #00f0ff;
  color: #0a0e17;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.controls-bar {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.search-box {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  position: relative;
}

.search-box i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #00f0ff;
  opacity: 0.7;
  font-size: 0.9rem;
  z-index: 1;
}

.search-box input {
  width: 100%;
  padding: 6px 12px 6px 32px;
  border-radius: 6px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  background: rgba(0, 240, 255, 0.05);
  color: #fff;
  font-size: 0.9rem;
}

.search-box input:hover {
  border-color: rgba(0, 240, 255, 0.3);
}

.search-box input:focus {
  border-color: #00f0ff;
  outline: none;
  box-shadow: 0 0 0 1px rgba(0, 240, 255, 0.1);
}

.search-box input:focus + i {
  opacity: 1;
}

.status-select {
  min-width: 140px;
  flex: 0 0 auto;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  background: rgba(0, 240, 255, 0.05);
  color: #fff;
  font-size: 0.9rem;
}

.table-container {
  background: rgba(0, 240, 255, 0.02);
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  overflow: auto;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.users-table th {
  background: rgba(0, 240, 255, 0.05);
  padding: 12px;
  text-align: left;
  color: #00f0ff;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.users-table td {
  padding: 12px;
  border-bottom: 1px solid rgba(0, 240, 255, 0.05);
  vertical-align: middle;
}

.user-info-cell {
  min-width: 200px;
}

.user-primary {
  color: #fff;
}

.user-secondary {
  font-size: 0.8rem;
  color: #a0a0a0;
}

.role-cell {
  width: 100px;
}

.role {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
}

.role.admin {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.role.mod {
  background: rgba(33, 150, 243, 0.1);
  color: #2196f3;
}

.role.user {
  background: rgba(158, 158, 158, 0.1);
  color: #9e9e9e;
}

.actions-cell {
  width: 180px;
  min-width: 180px;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
}

.icon-btn {
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 4px;
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.icon-btn i {
  font-size: 14px;
  line-height: 1;
}

.icon-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border-color: #00f0ff;
}

.icon-btn.active {
  background: rgba(0, 240, 255, 0.15);
  color: #00f0ff;
  border-color: #00f0ff;
}

.icon-btn.view {
  color: #2196f3;
  border-color: rgba(33, 150, 243, 0.3);
}

.icon-btn.view:hover {
  background: rgba(33, 150, 243, 0.1);
  border-color: #2196f3;
}

.icon-btn.delete {
  color: #ff4444;
  border-color: rgba(255, 68, 68, 0.3);
}

.icon-btn.delete:hover {
  background: rgba(255, 68, 68, 0.1);
  border-color: #ff4444;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.page-btn {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #00f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-btn:not(:disabled):hover {
  background: rgba(0, 240, 255, 0.1);
}

.page-info {
  font-size: 0.9rem;
  color: #a0a0a0;
  margin: 0 0.5rem;
}

@media (max-width: 768px) {
  .controls-bar {
    flex-direction: column;
  }
  
  .search-box {
    max-width: 100%;
  }
  
  .action-buttons {
    flex-wrap: wrap;
  }
}

.email-cell {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}

.name-cell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}

.contact-cell {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a0a0a0;
}

.table-container {
  overflow-x: auto;
  margin-bottom: 1rem;
}

.users-table {
  width: 100%;
  min-width: 900px;
}

/* Add these styles for tooltips */
[data-tooltip] {
  position: relative;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 1000;
}

[data-tooltip]:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: calc(100% + 5px);
}

/* Add responsive styles */
@media (max-width: 480px) {
  .role-toggle {
    flex: 1 1 100%;
  }
  
  .role-toggles {
    gap: 8px;
  }
}

/* Add these new styles for role assignment */
.role-assignment {
  width: 100%;
}

.role-section-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #00f0ff;
  margin-bottom: 12px;
  font-size: 0.9rem;
}

.role-checkboxes {
  display: flex;
  gap: 16px;
}

.role-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.role-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 4px;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #a0a0a0;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.checkbox-label i {
  font-size: 0.9rem;
}

.role-checkbox input:checked + .checkbox-label {
  background: rgba(0, 240, 255, 0.15);
  color: #00f0ff;
  border-color: #00f0ff;
}

.role-checkbox:hover .checkbox-label {
  border-color: #00f0ff;
  background: rgba(0, 240, 255, 0.1);
}

/* Update form section spacing */
.form-section {
  padding: 1.2rem;
  margin-bottom: 0;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .role-checkboxes {
    flex-direction: column;
    gap: 8px;
  }
  
  .role-checkbox {
    width: 100%;
  }
  
  .checkbox-label {
    width: 100%;
    justify-content: center;
  }
}

/* Custom Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: #a0a0a0;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: rgba(0, 240, 255, 0.2);
  border-color: #00f0ff;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
  background-color: #00f0ff;
}

.toggle-switch:hover .toggle-slider:before {
  box-shadow: 0 0 5px rgba(0, 240, 255, 0.5);
}

/* Update the visibility cell styles */
.visibility-cell {
  width: 80px;
}

/* Toggle Switch Styles */
.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.toggle-slider {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 20px;
  transition: all 0.3s ease;
}

.toggle-slider:before {
  content: '';
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #a0a0a0;
  border-radius: 50%;
  transition: all 0.3s ease;
}

input:checked + .toggle-slider {
  background-color: rgba(0, 240, 255, 0.2);
  border-color: #00f0ff;
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
  background-color: #00f0ff;
}

.toggle-label {
  display: none;
}

.toggle-switch:hover .toggle-slider {
  border-color: #00f0ff;
}

/* Sort Controls */
.sort-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
}

.sort-select {
  min-width: 180px;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  background: rgba(0, 240, 255, 0.05);
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
}

.sort-order-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 6px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sort-order-btn:hover {
  background: rgba(0, 240, 255, 0.1);
}

/* Profile Completion Cell */
.completion-cell {
  width: 120px;
}

.completion-wrapper {
  position: relative;
  height: 24px;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
}

.completion-bar {
  height: 100%;
  background: #4CAF50;
  transition: width 0.3s ease;
}

.completion-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 0.8rem;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sort-controls {
    width: 100%;
    justify-content: space-between;
  }

  .sort-select {
    flex: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .controls-bar {
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .search-box {
    max-width: none;
    width: 100%;
  }

  .sort-controls {
    margin-left: 0;
    width: 100%;
  }

  .sort-select {
    flex: 1;
  }
}
  