/* Profile page styles */
.profile-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-primary);
}

.profile-header {
  position: relative;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, var(--bg-primary), var(--bg-secondary));
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.profile-cover {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* ... (other profile styles) ... */
