/* Add at the top of the file */
.public-profile * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.public-profile {
  max-width: 1200px;
  margin: 50px auto 0;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  background: #0a0e17;
}

/* Public profile styles */
.public-profile {
  max-width: 1200px;
  /* margin: 50px auto 0; */
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.profile-header {
  position: relative;
  height: 300px;
  /* margin: 1.5rem 0 2rem; */
  border-radius: 15px;
  overflow: hidden;
}

.profile-cover {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.profile-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
  color: #fff;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin-right: 20px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  transition: transform 0.3s ease;
}

.profile-picture:hover {
  transform: scale(1.05);
}

.profile-details {
  flex: 1;
  text-align: left;
}

.profile-details h1 {
  font-size: 2.5rem;
  margin-bottom: 0.2rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  text-align: left;
}

.profession, .location {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.profile-actions {
  margin-top: 1rem;
}

.profile-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  /* padding: 1rem 0.5rem; */
}

.profile-main {
  flex: 1;
  min-width: 300px;
  margin-top: 1rem;
}

.profile-sidebar {
  width: 300px;
  margin-top: 1rem;
}

.profile-main, .profile-sidebar {
  background-color: rgba(255, 255, 255, 0.00);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  /* padding: 1rem; */
}

.business-card {
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 15px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 240, 255, 0.3);
}

.about-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 1.5rem;
}

.about-header h2 {
  color: #00f0ff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
}

.cyber-line {
  flex: 1;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(0, 240, 255, 0.5), 
    rgba(0, 240, 255, 0.2), 
    transparent
  );
}

.about-content {
  position: relative;
  z-index: 1;
}

.cyber-frame {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
}

.cyber-frame::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    transparent 0%,
    rgba(0, 240, 255, 0.1) 45%,
    rgba(0, 240, 255, 0.1) 55%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  20%, 100% {
    transform: translateX(100%);
  }
}

.cyber-frame p {
  color: #a0a0a0;
  line-height: 1.6;
  font-size: 1.1rem;
  margin: 0;
}

.business-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(0, 240, 255, 0.1) 0%, transparent 50%);
  transform: rotate(30deg);
  transition: all 0.5s ease;
}

.business-card:hover::before {
  transform: rotate(0deg);
}

.business-card h2 {
  color: #00f0ff;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  text-shadow: 0 0 5px rgba(0, 240, 255, 0.7);
}

.business-card p {
  position: relative;
  z-index: 1;
  color: #a0a0a0;
}

.business-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 240, 255, 0.3);
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
}

.skills-section {
  margin-top: 20px;
}

.skill-category {
  margin-bottom: 15px;
}

.skill-category h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #333;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style-type: none;
  padding: 0;
}

.skill-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 6px 35px 6px 12px;
  background: rgba(0, 240, 255, 0.02);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 15px;
  color: #a0a0a0;
  font-size: 0.85rem;
  margin: 4px;
  transition: all 0.2s ease;
}

.skill-level {
  position: absolute;
  right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: rgba(0, 240, 255, 0.8);
  background: rgba(0, 240, 255, 0.05);
  border-radius: 50%;
  padding: 2px;
}

.skills-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px;
  justify-content: flex-start;
}

/* Update level indicators */
.skill-item.level-5 { border-color: rgba(0, 240, 255, 0.5); }
.skill-item.level-4 { border-color: rgba(0, 240, 255, 0.4); }
.skill-item.level-3 { border-color: rgba(0, 240, 255, 0.3); }
.skill-item.level-2 { border-color: rgba(0, 240, 255, 0.2); }
.skill-item.level-1 { border-color: rgba(0, 240, 255, 0.1); }

.skill-item:hover {
  transform: translateY(-1px);
  background: rgba(0, 240, 255, 0.05);
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.service-card {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}

.service-info {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.service-info h3 {
  color: #00f0ff;
  font-size: 1.2rem;
  margin: 0;
}

.service-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.service-price {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 6px;
  display: inline-block;
}

.service-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
}

.service-actions button {
  flex: 1;
  padding: 0.75rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
}

.service-actions button:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
}

.serviced-countries-map {
  margin-top: 2rem;
  padding: 1rem;
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.serviced-countries-map h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.serviced-countries-map svg {
  background-color: #0a0e17;
}

.serviced-countries-map path {
  transition: all 0.3s ease;
}

.serviced-countries-map path[fill="var(--primary-color)"] {
  filter: drop-shadow(0 0 5px var(--primary-color));
}

.profession {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  color: #00f0ff;
  font-weight: bold;
}

.contact-card,
.sidebar-section {
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  /* box-shadow: 0 5px 10px rgba(0, 240, 255, 0.2); */
  color: #a0a0a0;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 240, 255, 0.3);
}

.contact-card::before,
.sidebar-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(0, 240, 255, 0.1) 0%, transparent 50%);
  transform: rotate(30deg);
  transition: all 0.5s ease;
}

.contact-card:hover::before,
.sidebar-section:hover::before {
  transform: rotate(0deg);
}

.contact-card h2,
.sidebar-section h2 {
  color: #00f0ff;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  /* text-shadow: 0 0 5px rgba(0, 240, 255, 0.7); */
}

.contact-card p,
.sidebar-section p {
  position: relative;
  z-index: 1;
  color: #a0a0a0;
}

.contact-card:hover,
.sidebar-section:hover {
  transform: translateY(-3px);
  /* box-shadow: 0 15px 30px rgba(0, 240, 255, 0.3); */
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
}

.contact-card p svg,
.sidebar-section p svg {
  color: #00f0ff;
  margin-right: 0.5rem;
}

.experience-item, .education-item {
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}

.experience-item h3, .education-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #00f0ff;
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  position: relative;
  z-index: 1;
}

.social-icons a {
  color: #00f0ff;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #33f5ff;
  transform: translateY(-3px);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.profile-section {
  animation: fadeIn 0.5s ease forwards;
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .public-profile {
    padding: 1rem;
  }

  .profile-header {
    height: auto;
    margin-bottom: 0;
    border-radius: 0;
  }

  .profile-cover {
    height: 200px;
  }

  .profile-info {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .profile-picture {
    width: 120px;
    height: 120px;
    margin-right: 0;
    margin-top: -60px;
    margin-bottom: 1rem;
  }

  .profile-details h1 {
    font-size: 2rem;
  }

  .profession, .location {
    font-size: 1rem;
  }

  .profile-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .profile-content {
    flex-direction: column;
    gap: 1rem;
  }

  .profile-main, .profile-sidebar {
    width: 100%;
  }

  .business-card {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
  }

  .service-card {
    padding: 0;
  }

  .service-info {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .service-info h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .service-info button {
    margin-top: 0.5rem;
    width: 100%;
  }

  .contact-card, .sidebar-section {
    padding: 1rem;
  }

  .social-icons {
    justify-content: center;
  }

  .service-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .service-details, .service-action {
    width: 100%;
  }

  .service-action {
    margin-top: 0.5rem;
  }

  .service-info button {
    width: 100%;
  }
}

/* For even smaller screens */
@media (max-width: 480px) {
  .public-profile {
    padding: 0.5rem;
  }

  .profile-picture {
    width: 100px;
    height: 100px;
    margin-top: -50px;
  }

  .profile-details h1 {
    font-size: 1.75rem;
  }

  .profession, .location {
    font-size: 0.9rem;
  }

  .profile-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .profile-actions button {
    width: 100%;
  }
}

.skills-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  perspective: 1000px;
}

.skill-item {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 14px;
  cursor: default;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.skill-item:hover {
  transform: translateY(-3px) rotateX(10deg) rotateY(10deg);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.serviced-countries-map {
  margin-top: 2rem;
  padding: 1rem;
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.serviced-countries-map h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.service-card .btn {
  width: 100%;
  text-align: center;
}

.sidebar-section.technologies,
.sidebar-section.platforms {
  margin-top: 1rem;
}

.sidebar-section.technologies ul,
.sidebar-section.platforms ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-section.technologies li,
.sidebar-section.platforms li {
  background-color: rgba(0, 240, 255, 0.1);
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.sidebar-section.technologies li:hover,
.sidebar-section.platforms li:hover {
  background-color: rgba(0, 240, 255, 0.2);
  transform: translateX(5px);
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icons a {
  color: var(--primary-color);
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  color: #33f5ff;
  transform: translateY(-3px);
}

.tech-platform-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding: 0.5rem;
}

.tech-category {
  width: 100%;
  margin-bottom: 1rem;
}

.tech-category h4 {
  color: #00f0ff;
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
  opacity: 0.9;
}

.tech-platform-item {
  display: inline-flex;
  align-items: center;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #fff;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  font-size: 0.85rem;
  margin: 0 0.4rem 0.4rem 0;
  transition: all 0.3s ease;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.4;
  word-break: break-word;
}

.tech-platform-item:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
  border-color: rgba(0, 240, 255, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tech-platform-list {
    gap: 0.6rem;
    padding: 0.4rem;
  }

  .tech-platform-item {
    font-size: 0.8rem;
    padding: 0.4rem 0.6rem;
    margin: 0 0.3rem 0.3rem 0;
  }
}

/* Ensure sidebar sections maintain proper spacing */
.sidebar-section.technologies,
.sidebar-section.platforms {
  padding: 1.2rem;
  margin-bottom: 1rem;
}

.sidebar-section.technologies h2,
.sidebar-section.platforms h2 {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.social-links {
  display: flex;
  /* flex-direction: column; */
  gap: 12px;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #a0a0a0;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 8px;
  border-radius: 5px;
}

.social-link:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  transform: translateX(5px);
}

.social-icon {
  font-size: 1.2rem;
  color: #00f0ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-actions-floating {
    bottom: 20px;
    right: 20px;
    gap: 15px;
  }

  .action-btn {
    font-size: 1.5rem;
    padding: 10px 16px;
  }

  .love-count {
    font-size: 1rem;
  }

  .business-card {
    padding: 1.5rem;
  }

  .about-header h2 {
    font-size: 1.5rem;
  }

  .cyber-frame p {
    font-size: 1rem;
  }
}

/* Experience Tree Styles */
.experience-tree {
  margin-top: 2rem;
}

.tree-container {
  position: relative;
  padding-left: 20px;
}

.tree-node {
  position: relative;
  padding-bottom: 25px;
}

.tree-line {
  position: absolute;
  left: -20px;
  top: 25px;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, 
    rgba(0, 240, 255, 0.5),
    rgba(0, 240, 255, 0.1)
  );
}

.node-content {
  position: relative;
  padding: 15px;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.node-content::before {
  content: '';
  position: absolute;
  left: -25px;
  top: 15px;
  width: 12px;
  height: 12px;
  background: #00f0ff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
}

.node-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.year {
  font-size: 0.8rem;
  color: rgba(0, 240, 255, 0.8);
  background: rgba(0, 240, 255, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
}

.skill-item {
  position: relative;
  padding-right: 30px;
}

.skill-level {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
  opacity: 0.7;
}

.skill-item.level-5 { background-color: #00f0ff; }
.skill-item.level-4 { background-color: rgba(0, 240, 255, 0.8); }
.skill-item.level-3 { background-color: rgba(0, 240, 255, 0.6); }
.skill-item.level-2 { background-color: rgba(0, 240, 255, 0.4); }
.skill-item.level-1 { background-color: rgba(0, 240, 255, 0.2); }

/* Share Modal Styles */
.share-modal {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border-radius: 15px;
  padding: 25px;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.share-modal h3 {
  color: #00f0ff;
  margin-bottom: 20px;
  text-align: center;
}

.share-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.share-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 15px;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 10px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.share-option:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.share-option svg {
  font-size: 1.5rem;
}

.share-option span {
  font-size: 0.9rem;
}

/* New proficiency indicator styles */
.skill-proficiency {
  position: absolute;
  right: 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2px;
}

.proficiency-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
}

.skill-item:hover .proficiency-dot {
  background: rgba(0, 240, 255, 0.4);
}

/* Style filled dots based on proficiency level */
.skill-item[data-level="5"] .proficiency-dot:nth-child(-n+5),
.skill-item[data-level="4"] .proficiency-dot:nth-child(-n+4),
.skill-item[data-level="3"] .proficiency-dot:nth-child(-n+3),
.skill-item[data-level="2"] .proficiency-dot:nth-child(-n+2),
.skill-item[data-level="1"] .proficiency-dot:nth-child(-n+1) {
  background: #00f0ff;
  box-shadow: 0 0 4px rgba(0, 240, 255, 0.5);
}

/* Remove old level styles */
.skill-item.level-5,
.skill-item.level-4,
.skill-item.level-3,
.skill-item.level-2,
.skill-item.level-1 {
  border-color: rgba(0, 240, 255, 0.15);
}

.skill-item:hover {
  transform: translateY(-1px);
  background: rgba(0, 240, 255, 0.05);
  border-color: rgba(0, 240, 255, 0.3);
}

.experience-item {
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
  border-left: 2px solid rgba(0, 240, 255, 0.3);
}

.experience-item::before {
  content: '';
  position: absolute;
  left: -6px;
  top: 0;
  width: 10px;
  height: 10px;
  background: #00f0ff;
  border-radius: 50%;
}

/* Add to existing styles */
.contact-details {
  margin-bottom: 20px;
  padding: 15px;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.contact-input {
  width: 100%;
  padding: 10px;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 6px;
  color: #fff;
  margin-bottom: 10px;
}

.contact-note {
  font-size: 0.9rem;
  color: rgba(0, 240, 255, 0.7);
  margin-top: 10px;
  font-style: italic;
}

/* Update social links icon */
.social-links svg {
  font-size: 1.2rem;
}

/* Add these styles to the existing publicProfile.css */

.profile-badges {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.badges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.badge-item {
  position: relative;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.badge-icon {
  font-size: 2rem;
  color: #00f0ff;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
  display: block;
}

.badge-name {
  font-size: 0.8rem;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
}

.no-badges {
  color: #a0a0a0;
  text-align: center;
  font-style: italic;
  padding: 1rem;
  width: 100%;
}

/* Fresh badge styles */
.sidebar-section.badges {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.sidebar-section.badges h2 {
  color: #00f0ff;
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
}

.badges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  gap: 0.5rem;
}

.badge-item {
  position: relative;
  padding: 0.5rem;
  text-align: center;
  transition: all 0.3s ease;
}

.badge-icon {
  font-size: 1.75rem;
  color: #00f0ff;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
  margin-bottom: 0.25rem;
}

.badge-name {
  font-size: 0.7rem;
  color: #fff;
}

.badge-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 0.75rem;
  width: 180px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 100;
  pointer-events: none;
}

.badge-item:hover {
  transform: translateY(-2px);
}

.badge-item:hover .badge-tooltip {
  visibility: visible;
  opacity: 1;
  bottom: calc(100% + 5px);
}

.no-badges {
  color: #a0a0a0;
  text-align: center;
  font-style: italic;
  padding: 0.5rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .badges-grid {
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  }
  
  .badge-icon {
    font-size: 1.75rem;
  }
  
  .badge-name {
    font-size: 0.75rem;
  }
}

/* Update Language Section Styles */
.sidebar-section.languages {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.2rem;
  margin-bottom: 1rem;
}

.language-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 0.5rem;
}

.language-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.language-item:hover {
  background: rgba(0, 240, 255, 0.08);
  transform: translateX(3px);
  border-color: rgba(0, 240, 255, 0.3);
}

.language-name {
  color: #fff;
  font-size: 0.95rem;
  font-weight: 500;
}

.proficiency-badge {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
  border: 1px solid rgba(0, 240, 255, 0.2);
  text-transform: capitalize;
}

/* Proficiency level specific colors */
.proficiency-badge[data-level="native"] {
  background: rgba(0, 240, 255, 0.15);
  border-color: rgba(0, 240, 255, 0.3);
}

.proficiency-badge[data-level="fluent"] {
  background: rgba(0, 240, 255, 0.12);
  border-color: rgba(0, 240, 255, 0.25);
}

.proficiency-badge[data-level="intermediate"] {
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.2);
}

.proficiency-badge[data-level="basic"] {
  background: rgba(0, 240, 255, 0.05);
  border-color: rgba(0, 240, 255, 0.15);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar-section.languages {
    padding: 1rem;
  }

  .language-list {
    gap: 0.6rem;
  }

  .language-item {
    padding: 0.6rem 0.8rem;
  }

  .language-name {
    font-size: 0.9rem;
  }

  .proficiency-badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.6rem;
  }
}

/* Empty state */
.language-list:empty::after {
  content: 'No languages added';
  color: #a0a0a0;
  font-style: italic;
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: block;
}

/* Update Availability Section Styles */
.sidebar-section.availability {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.2rem;
  margin-bottom: 1rem;
}

.availability-status {
  margin-bottom: 1rem;
  color: #00f0ff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.available .status-dot {
  background: #00f0ff;
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
  animation: pulse 2s infinite;
}

.not-available .status-dot {
  background: #ff4444;
}

/* Hourly Rate Display */
.rate-display {
  background: rgba(0, 240, 255, 0.08);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.rate-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.rate-value {
  color: #00f0ff;
  font-size: 1.4rem;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}

.rate-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
}

@media (max-width: 768px) {
  .rate-display {
    padding: 0.75rem;
  }

  .rate-value {
    font-size: 1.2rem;
  }

  .rate-label {
    font-size: 0.8rem;
  }
}

/* Availability Types - Simplified */
.availability-types {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.type-badge {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.85rem;
  padding: 0.4rem 0.8rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 4px;
}

@media (max-width: 768px) {
  .rate-display {
    padding: 0.6rem 0.8rem;
  }

  .rate-value {
    font-size: 1.2rem;
  }

  .type-badge {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
  }
}

/* Update Profile Actions Styles */
.profile-actions-floating {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  z-index: 10;
  margin-right: 15px;
  margin-bottom: 15px;
}

.action-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.8;
  padding: 0;
}

.action-btn:hover {
  transform: translateY(-2px);
  opacity: 1;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
}

/* Love Button */
.love-btn .loved {
  color: #ff4444;
  filter: drop-shadow(0 0 5px rgba(255, 68, 68, 0.5));
}

.love-count {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin-left: 0.3rem;
}

/* Message and Share Buttons */
.message-btn, .share-btn {
  color: #00f0ff;
}

.message-btn:hover, .share-btn:hover {
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .social-strip {
    gap: 1rem;
  }

  .social-strip a {
    font-size: 1.3rem;
  }

  .profile-actions-floating {
    right: 15px;
    bottom: 15px;
    gap: 1.2rem;
  }

  .action-btn {
    font-size: 1.4rem;
  }

  .love-count {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .social-strip {
    gap: 0.8rem;
  }

  .social-strip a {
    font-size: 1.2rem;
  }

  .profile-actions-floating {
    right: 10px;
    bottom: 10px;
    gap: 1rem;
  }

  .action-btn {
    font-size: 1.3rem;
  }
}

/* Update social icons styles */
.social-strip {
  display: flex;
  gap: 1.2rem;
  margin-top: 1.2rem;
}

/* Desktop social icons (below location) */
.profile-details .social-strip {
  display: flex; /* Show on desktop */
}

/* Mobile social icons (in action row) */
.action-row .social-strip {
  display: none; /* Hide on desktop */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* Hide desktop social icons */
  .profile-details .social-strip {
    display: none;
  }

  /* Show mobile social icons */
  .action-row .social-strip {
    display: flex;
    margin: 0;
    gap: 1rem;
  }

  .action-row {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .action-row {
    padding: 0 15px;
  }

  .action-row .social-strip {
    gap: 0.8rem;
  }
}

/* Update only the social icons and action buttons in hero section */
.social-strip a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.4rem;
  transition: all 0.3s ease;
}

.social-strip a:hover {
  color: #00f0ff;
  transform: translateY(-3px);
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
}

.profile-actions-floating {
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  z-index: 10;
  margin-right: 15px;
  margin-bottom: 15px;
}

/* Keep existing mobile styles */
@media (max-width: 768px) {
  .profile-actions-floating {
    right: 15px;
    bottom: 15px;
    margin: 0;
  }
}

/* Update message modal styles */
.message-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.message-modal {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 15px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: modalFadeIn 0.3s ease;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 2;
}

.modal-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  color: #00f0ff;
  font-size: 1.5rem;
  margin: 0;
}

.recipient-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.recipient-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(0, 240, 255, 0.3);
}

.recipient-details h3 {
  color: #00f0ff;
  margin: 0;
  font-size: 1.2rem;
}

.recipient-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #00f0ff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  transition: all 0.2s ease;
}

.close-btn:hover {
  transform: rotate(90deg);
  color: #fff;
}

.message-form {
  padding: 1.5rem;
}

.message-form .form-group {
  margin-bottom: 1.5rem;
}

.message-form label {
  display: block;
  color: #00f0ff;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.message-form textarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 0.95rem;
  line-height: 1.5;
  resize: vertical;
  transition: all 0.2s ease;
}

.message-form textarea:focus {
  outline: none;
  border-color: #00f0ff;
  background: rgba(0, 240, 255, 0.08);
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.1);
}

.character-count {
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

.message-guidelines {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
}

.message-guidelines h4 {
  color: #00f0ff;
  margin: 0 0 0.75rem 0;
  font-size: 0.95rem;
}

.message-guidelines ul {
  margin: 0;
  padding-left: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.message-guidelines li {
  margin-bottom: 0.4rem;
}

.message-guidelines li:last-child {
  margin-bottom: 0;
}

.modal-footer {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
}

.send-btn, .cancel-btn {
  flex: 1;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.send-btn {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.3);
}

.send-btn:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
}

.cancel-btn {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cancel-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .message-modal {
    width: 95%;
    max-height: 85vh;
  }

  .modal-header {
    padding: 1rem;
  }

  .message-form {
    padding: 1rem;
  }

  .message-form textarea {
    min-height: 120px;
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .message-guidelines {
    padding: 0.75rem;
  }

  .message-guidelines h4 {
    font-size: 0.9rem;
  }

  .message-guidelines ul {
    font-size: 0.85rem;
  }

  .send-btn, .cancel-btn {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}

/* Ensure modal scrolls properly on mobile */
@media (max-height: 600px) {
  .message-modal {
    height: 85vh;
  }
  
  .message-form {
    max-height: calc(85vh - 100px);
    overflow-y: auto;
  }
}

/* Add these styles for section headers */
.profile-section h2 {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 1.5rem;
  color: #00f0ff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 1.5rem 0;
}

.profile-section h2::after {
  content: '';
  flex: 1;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(0, 240, 255, 0.5), 
    rgba(0, 240, 255, 0.2), 
    transparent
  );
}

.profile-map-section h2 {
  margin-left: 20px;
}

/* Specific sections */
.profile-map-section h2,
.profile-section.services h2 {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 1.5rem;
  color: #00f0ff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top:1rem;
}

.profile-map-section h2::after,
.profile-section.services h2::after {
  content: '';
  flex: 1;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(0, 240, 255, 0.5), 
    rgba(0, 240, 255, 0.2), 
    transparent
  );
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-section h2,
  .profile-map-section h2,
  .profile-section.services h2 {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
  }
}

@media (max-width: 480px) {
  .profile-section h2,
  .profile-map-section h2,
  .profile-section.services h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
}

/* Update service card and modal styles */
.service-card {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}

.service-info {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.service-info h3 {
  color: #00f0ff;
  font-size: 1.2rem;
  margin: 0;
}

.service-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.service-price {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 6px;
  display: inline-block;
}

.service-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
}

.service-actions button {
  flex: 1;
  padding: 0.75rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
}

.service-actions button:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
}

/* Service Modal */
.service-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.service-modal-content {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 15px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.service-modal-image-container {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.service-modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-modal-image:hover {
  transform: scale(1.05);
}

.service-modal-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, rgba(26, 31, 46, 1), transparent);
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 2;
}

.modal-header h2 {
  color: #00f0ff;
  font-size: 1.5rem;
  margin: 0;
}

.modal-actions {
  display: flex;
  gap: 1rem;
}

.modal-close-btn,
.modal-back-btn {
  background: none;
  border: none;
  color: #00f0ff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.modal-close-btn:hover,
.modal-back-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: scale(1.1);
}

.modal-body {
  padding: 1.5rem;
}

.service-description-full {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.8;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.service-pricing {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1.5rem 0;
}

.modal-footer {
  padding: 1.5rem;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
  display: flex;
  gap: 1rem;
}

@media (max-width: 768px) {
  .service-modal-content {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    /* border-radius: 0; */
  }

  .modal-header {
    padding: 1rem;
  }

  .modal-header h2 {
    font-size: 1.3rem;
  }

  .modal-body {
    padding: 1rem;
  }

  .service-pricing {
    padding: 1rem;
    margin: 1rem 0;
  }

  .modal-footer {
    padding: 1rem;
    flex-direction: column;
  }

  .modal-footer button {
    width: 100%;
  }

  .service-modal-image-container {
    height: 200px;
  }
  
  .service-modal-content .modal-footer {
    flex-direction: column;
    padding: 1rem;
  }
  
  .service-modal-content .contact-btn {
    width: 100%;
    padding: 0.6rem 1rem;
  }
}

.experience-timeline {
  position: relative;
  padding-left: 40px;
  margin-top: 2rem;
}

/* Update the main vertical line */
.experience-timeline::before {
  content: '';
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, 
    rgba(0, 240, 255, 0.8),
    rgba(0, 240, 255, 0.1)
  );
}

/* Add connecting handle to experience items */
.experience-item {
  position: relative;
  background: rgba(0, 240, 255, 0.02);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  padding: 1.8rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
  height: fit-content;
}

/* Update dot indicator */
.experience-item::before {
  content: '';
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #00f0ff;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.8);
  z-index: 2;
}

/* Add horizontal connecting handle */
.experience-item::after {
  content: '';
  position: absolute;
  left: -30px;
  top: 50%;
  width: 30px;
  height: 2px;
  background: linear-gradient(to right,
    rgba(0, 240, 255, 0.8),
    rgba(0, 240, 255, 0.2)
  );
  transform: translateY(-50%);
}

/* Hover effects */
.experience-item:hover::after {
  background: linear-gradient(to right,
    rgba(0, 240, 255, 1),
    rgba(0, 240, 255, 0.4)
  );
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .experience-timeline {
    padding-left: 30px;
  }

  .experience-item::before {
    left: -30px;
    width: 8px;
    height: 8px;
  }

  .experience-item::after {
    left: -22px;
    width: 22px;
  }
}

@media (max-width: 480px) {
  .experience-timeline {
    padding-left: 25px;
  }

  .experience-item::before {
    left: -25px;
    width: 6px;
    height: 6px;
  }

  .experience-item::after {
    left: -19px;
    width: 19px;
  }
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  padding-bottom: 1rem;
}

.experience-title {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}

.experience-date {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.85rem;
  background: rgba(0, 240, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  white-space: nowrap;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.experience-company {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.experience-company::before {
  display: none;
}

.experience-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.7;
  margin: 0;
  padding-left: 1.2rem;
  border-left: 2px solid rgba(0, 240, 255, 0.1);
}

.experience-item:hover {
  transform: translateX(5px);
  background: rgba(0, 240, 255, 0.04);
  box-shadow: 0 4px 20px rgba(0, 240, 255, 0.05);
}

.experience-item:hover::before {
  box-shadow: 0 0 20px rgba(0, 240, 255, 1);
}

.experience-item:hover .experience-title {
  text-shadow: 0 0 15px rgba(0, 240, 255, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .experience-section {
    padding: 1.5rem;
  }

  .experience-timeline {
    padding-left: 30px;
  }

  .experience-item {
    padding: 1.5rem;
  }

  .experience-item::before {
    left: -30px;
  }

  .experience-item::after {
    left: -26px;
    width: 20px;
  }

  .experience-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }

  .experience-title {
    font-size: 1.1rem;
  }

  .experience-description {
    padding-left: 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .experience-section {
    padding: 1.2rem;
  }

  .experience-timeline {
    padding-left: 25px;
  }

  .experience-item {
    padding: 1.2rem;
  }

  .experience-item::before {
    left: -25px;
    width: 8px;
    height: 8px;
  }

  .experience-item::after {
    left: -21px;
    width: 15px;
  }
}

.portfolio-section h2,
.services-section h2,
.profile-map-section h2 {
  color: #00f0ff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.portfolio-section {
  margin-bottom: 2rem;
}


.portfolio-section h2::after,
.services-section h2::after,
.profile-map-section h2::after {
  content: '';
  flex: 1;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(0, 240, 255, 0.5), 
    rgba(0, 240, 255, 0.2), 
    transparent
  );
  margin-left: 1rem;
}

/* Remove individual service card frames */
.service-card {
  background: rgba(0, 240, 255, 0.03);
  border: none;
  transition: all 0.3s ease;
}

.service-card:hover {
  background: rgba(0, 240, 255, 0.08);
  transform: translateY(-5px);
}

/* Remove glowing effect from sections except bio */
.profile-section,
.portfolio-section,
.services-section,
.experience-section,
.profile-map-section {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
}

/* Keep glowing effect only on bio section */
.business-card {
  background: linear-gradient(135deg, #0a0e17, #1a1f2e);
  border-radius: 15px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 240, 255, 0.3);
}

/* Add bottom margin to countries served and services sections */
.profile-map-section,
.services-section {
  margin-bottom: 2rem;
}

.service-modal-content .contact-btn {
  flex: 1;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  text-decoration: none;
}

.service-modal-content .contact-btn:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

.service-modal-content .contact-btn svg {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .service-modal-image-container {
    height: 200px;
  }
  
  .service-modal-content .modal-footer {
    flex-direction: column;
    padding: 1rem;
  }
  
  .service-modal-content .contact-btn {
    width: 100%;
    padding: 0.6rem 1rem;
  }
}

/* Service Stats Styles */
.service-stats-container {
  position: relative;
  padding: 1rem 1.5rem;
  background: rgba(0, 240, 255, 0.02);
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.service-stats-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 14, 23, 0.2);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.coming-soon-badge {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: pulse 2s infinite;
}
.service-stats {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  flex: 1;
  width: 100%;
  /* max-width: 150px; */
}

.stat-value {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: 500;
}

.stat-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.stat-divider {
  width: 1px;
  height: 30px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 240, 255, 0.3),
    transparent
  );
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 240, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 240, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 240, 255, 0);
  }
}

@media (max-width: 768px) {
  .service-stats {
    flex-wrap: wrap;
    gap: 1rem 0.5rem;
  }

  .stat-item {
    flex: 1;
    min-width: calc(50% - 1rem);
  }

  .stat-divider {
    display: none;
  }

  .stat-value {
    font-size: 1.1rem;
  }

  .stat-label {
    font-size: 0.75rem;
  }
}

/* Update modal overlay z-index */
.message-modal-overlay,
.service-modal-overlay,
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Increased z-index */
  padding: 20px;
}

/* Update modal content z-index */
.message-modal,
.service-modal-content,
.share-modal {
  /* ... existing styles ... */
  z-index: 1001; /* Higher than overlay */
}

/* Add styles for the map container */
.world-map-container {
  position: relative;
  z-index: 1; /* Lower z-index than modals */
}

/* Add styles for country labels */
.world-map-container .country-label {
  z-index: 2; /* Higher than map container but lower than modals */
}

/* Service thumbnail styles */
.service-thumbnail {
  /* width: 100%; */
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.service-thumbnail-container {
  position: relative;
  /* width: 100%; */
  height: 200px;
  overflow: hidden;
  border-radius: 12px;
  background: rgba(0, 240, 255, 0.02);
}

.service-thumbnail-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(10, 14, 23, 0.2) 100%
  );
  z-index: 1;
}

.service-thumbnail-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 240, 255, 0.02);
  color: rgba(0, 240, 255, 0.3);
  font-size: 0.9rem;
}

/* Update service card to accommodate thumbnail */
.service-card {
  display: flex;
  flex-direction: column;
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}

.service-card:hover .service-thumbnail {
  transform: scale(1.05);
}

.service-info {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .service-thumbnail,
  .service-thumbnail-container {
    height: 160px;
  }
}

@media (max-width: 480px) {
  .service-thumbnail,
  .service-thumbnail-container {
    height: 140px;
  }
}

/* Service Card Styles */
.service-card {
  display: flex;
  flex-direction: column;
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  height: 100%;
}

.service-info {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  min-height: 200px;
}

.service-info h3 {
  color: #00f0ff;
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.4;
}

.service-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  flex: 1;
}

.service-price {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 6px;
  display: inline-block;
  align-self: flex-start;
  margin: 0;
}

.service-card-footer {
  margin-top: auto;
  padding-top: 1rem;
}

.service-card .btn-view-details {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.service-card .btn-view-details:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
  border-color: rgba(0, 240, 255, 0.3);
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .service-info {
    padding: 1.2rem;
    min-height: 180px;
  }

  .service-info h3 {
    font-size: 1.1rem;
  }

  .service-description {
    font-size: 0.9rem;
    -webkit-line-clamp: 2;
  }

  .service-price {
    font-size: 1.1rem;
    padding: 0.4rem 0.8rem;
  }

  .service-card .btn-view-details {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr;
  }

  .service-info {
    padding: 1rem;
    min-height: 160px;
  }

  .service-info h3 {
    font-size: 1rem;
  }

  .service-price {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
  }
}

/* Portfolio Section Styles */
.portfolio-section {
  margin-bottom: 2rem;
  padding: 2rem;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.portfolio-item {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.portfolio-item:hover {
  transform: translateY(-5px);
  background: rgba(0, 240, 255, 0.05);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 8px 24px rgba(0, 240, 255, 0.1);
}

.portfolio-image-container {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
  background: rgba(0, 240, 255, 0.02);
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.portfolio-item:hover .portfolio-image {
  transform: scale(1.05);
}

.portfolio-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 240, 255, 0.3);
  font-size: 1rem;
  background: rgba(0, 240, 255, 0.02);
}

.portfolio-details {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.portfolio-details h3 {
  color: #00f0ff;
  font-size: 1.3rem;
  margin: 0;
  line-height: 1.4;
}

.portfolio-details p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 0;
}

.portfolio-details .tools-used {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.portfolio-details .tool-tag {
  background: rgba(0, 240, 255, 0.08);
  color: #00f0ff;
  padding: 0.3rem 0.8rem;
  border-radius: 12px;
  font-size: 0.85rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.portfolio-details .results {
  background: rgba(0, 240, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.15);
  margin-top: auto;
}

.portfolio-details .btn-view {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-size: 0.95rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
  justify-content: center;
  margin-top: 1rem;
}

.portfolio-details .btn-view:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.1);
}

@media (max-width: 768px) {
  .portfolio-section {
    padding: 1.5rem;
  }

  .portfolio-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }

  .portfolio-image-container {
    height: 200px;
  }

  .portfolio-details {
    padding: 1.2rem;
  }

  .portfolio-details h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .portfolio-section {
    padding: 1rem;
  }

  .portfolio-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .portfolio-image-container {
    height: 180px;
  }

  .portfolio-details {
    padding: 1rem;
  }

  .portfolio-details h3 {
    font-size: 1.1rem;
  }
}

/* Portfolio card action buttons */
.portfolio-card-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.portfolio-card-actions button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.portfolio-card-actions .btn-secondary {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.portfolio-card-actions .btn-secondary:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.1);
}

.portfolio-card-actions .btn-cancel {
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
  border: 1px solid rgba(255, 68, 68, 0.2);
}

.portfolio-card-actions .btn-cancel:hover {
  background: rgba(255, 68, 68, 0.15);
  transform: translateY(-2px);
  border-color: rgba(255, 68, 68, 0.3);
  box-shadow: 0 4px 12px rgba(255, 68, 68, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .portfolio-card-actions {
    gap: 0.75rem;
  }

  .portfolio-card-actions button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .portfolio-card-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .portfolio-card-actions button {
    width: 100%;
    padding: 0.5rem 1rem;
  }
}

/* Remove all duplicate experience timeline styles and keep only these */
.experience-section {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.experience-section h2 {
  color: #00f0ff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 15px;
}

.experience-section h2::after {
  content: '';
  flex: 1;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(0, 240, 255, 0.5), 
    rgba(0, 240, 255, 0.2), 
    transparent
  );
}

.experience-timeline {
  position: relative;
  padding-left: 40px;
  margin-top: 2rem;
}

.experience-timeline::before {
  content: '';
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to bottom, 
    rgba(0, 240, 255, 0.8),
    rgba(0, 240, 255, 0.1)
  );
}

.experience-item {
  position: relative;
  background: rgba(0, 240, 255, 0.02);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  padding: 1.8rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
  height: fit-content;
}

.experience-item::before {
  content: '';
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #00f0ff;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.8);
  z-index: 2;
}

.experience-item::after {
  content: '';
  position: absolute;
  left: -30px;
  top: 50%;
  width: 30px;
  height: 2px;
  background: linear-gradient(to right,
    rgba(0, 240, 255, 0.8),
    rgba(0, 240, 255, 0.2)
  );
  transform: translateY(-50%);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  padding-bottom: 1rem;
}

.experience-title {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}

.experience-date {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.85rem;
  background: rgba(0, 240, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  white-space: nowrap;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.experience-company {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.experience-company::before {
  display: none;
}

.experience-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.7;
  margin: 0;
  padding-left: 1.2rem;
  border-left: 2px solid rgba(0, 240, 255, 0.1);
}

.experience-item:hover {
  transform: translateX(5px);
  background: rgba(0, 240, 255, 0.04);
  box-shadow: 0 4px 20px rgba(0, 240, 255, 0.05);
}

.experience-item:hover::before {
  box-shadow: 0 0 20px rgba(0, 240, 255, 1);
}

.experience-item:hover .experience-title {
  text-shadow: 0 0 15px rgba(0, 240, 255, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .experience-section {
    padding: 1.5rem;
  }

  .experience-timeline {
    padding-left: 30px;
  }

  .experience-item {
    padding: 1.5rem;
  }

  .experience-item::before {
    left: -30px;
  }

  .experience-item::after {
    left: -26px;
    width: 20px;
  }

  .experience-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }

  .experience-title {
    font-size: 1.1rem;
  }

  .experience-description {
    padding-left: 1rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .experience-section {
    padding: 1.2rem;
  }

  .experience-timeline {
    padding-left: 25px;
  }

  .experience-item {
    padding: 1.2rem;
  }

  .experience-item::before {
    left: -25px;
    width: 8px;
    height: 8px;
  }

  .experience-item::after {
    left: -21px;
    width: 15px;
  }
}


