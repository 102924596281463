.admin-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  margin-top: 50px;
}

.page-title {
  color: #00f0ff;
  margin-bottom: 30px;
  font-size: 1.8rem;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}



/* Navigation styles */
.admin-nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 buttons per row */
  gap: 0.75rem;
  margin-bottom: 2rem;
  width: 100%;
}

.admin-nav .btn {
  height: 56px;
  padding: 0 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background: rgba(0, 240, 255, 0.05);
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%; /* Full width within grid cell */
}

.admin-nav .btn:hover {
  background: rgba(0, 240, 255, 0.1);
  color: #1a1f2e;
}

.admin-nav .btn.active {
  background: #00f0ff;
  color: #1a1f2e;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .admin-nav {
    grid-template-columns: repeat(3, 1fr); /* 3 buttons per row on smaller screens */
  }
}

@media (max-width: 768px) {
  .admin-dashboard {
    padding: 0;
    margin-top: 30px;
  }

  .admin-nav {
    display: flex;
    flex-direction: column;
    background: rgba(26, 31, 46, 0.8);
    padding: 1rem;
    margin: 0;
    gap: 0.5rem;
  }

  .admin-nav .btn {
    width: 100%;
    height: 52px; /* Increased height on mobile */
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .admin-nav .btn {
    height: 56px; /* Even taller on smaller screens */
  }
}



/* Admin Stats */
.admin-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.25rem;
  padding: 1rem;
  margin: 2rem 0;
}

/* Stat Card Base Styles */
.stat-card {
  background: var(--bg-secondary, rgba(0, 240, 255, 0.05));
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.8rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #00f0ff, rgba(0, 240, 255, 0.2));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 24px rgba(0, 240, 255, 0.1);
}

.stat-card:hover::before {
  opacity: 1;
}

/* Stat Card Header */
.stat-card h3 {
  color: #00f0ff;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Stat Card Main Value */
.stat-card p {
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
  margin: 1rem 0;
  line-height: 1;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

/* Stat Details Section */
.stat-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.2rem 0;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.stat-details span {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Stat Card Button */
.stat-card .btn {
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.stat-card .btn:hover {
  background: rgba(0, 240, 255, 0.2);
  border-color: rgba(0, 240, 255, 0.3);
}

/* Growth Indicators */
.growth-positive {
  color: #4CAF50;
}

.growth-negative {
  color: #f44336;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .admin-stats {
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }

  .stat-card {
    padding: 1.2rem;
  }

  .stat-card p {
    font-size: 2rem;
  }
}

/* Loading State */
.stat-card.loading {
  position: relative;
  overflow: hidden;
}

.stat-card.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 240, 255, 0.05),
    transparent
  );
  animation: loading-shimmer 1.5s infinite;
}

@keyframes loading-shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}



/* Update the invitation management styles */
.invitation-requests-management {
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
}

.management-header {
  background: rgba(0, 240, 255, 0.05);
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.management-header h2 {
  color: #00f0ff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.filter-controls {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 0.6rem 1.2rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
  background: rgba(0, 240, 255, 0.05);
  color: #00f0ff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  min-width: 100px;
  text-align: center;
}

.filter-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-1px);
}

.filter-btn.active {
  background: #00f0ff;
  color: #1a1f2e;
  font-weight: 500;
}

.requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.25rem;
  padding: 0.5rem;
}

.request-card {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 12px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.request-card:hover {
  background: rgba(0, 240, 255, 0.05);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.1);
}

.status-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.4rem 1rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  border-radius: 6px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.status-approved {
  background: rgba(0, 255, 0, 0.1);
  color: #4eff4e;
  border: 1px solid rgba(0, 255, 0, 0.2);
}

.status-rejected {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4e4e;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.status-pending {
  background: rgba(255, 165, 0, 0.1);
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.2);
}

.request-info {
  padding-top: 1rem;
}

.request-info h3 {
  color: #00f0ff;
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  font-weight: 500;
}

.request-details {
  margin: 1rem 0;
  color: rgba(255, 255, 255, 0.9);
}

.request-details p {
  margin: 0;
  line-height: 1.5;
  font-size: 0.95rem;
}

.request-details small {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.75rem;
  font-size: 0.85rem;
}

.invitation-code {
  background: rgba(0, 240, 255, 0.08);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.invitation-code strong {
  color: #00f0ff;
  font-family: monospace;
  font-size: 1.1rem;
  letter-spacing: 1px;
}

.copy-btn {
  padding: 0.4rem 1rem;
  background: rgba(0, 240, 255, 0.15);
  border: 1px solid rgba(0, 240, 255, 0.3);
  border-radius: 6px;
  color: #00f0ff;
  cursor: pointer;
  font-size: 0.85rem;
  transition: all 0.2s ease;
}

.copy-btn:hover {
  background: rgba(0, 240, 255, 0.25);
}

.request-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.request-actions button {
  flex: 1;
  padding: 0.75rem;
  font-size: 0.95rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.btn-success {
  background: rgba(0, 255, 0, 0.1);
  color: #4eff4e;
  border: 1px solid rgba(0, 255, 0, 0.2);
}

.btn-success:hover {
  background: rgba(0, 255, 0, 0.2);
  transform: translateY(-1px);
}

.btn-danger {
  background: rgba(255, 0, 0, 0.1);
  color: #ff4e4e;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.btn-danger:hover {
  background: rgba(255, 0, 0, 0.2);
  transform: translateY(-1px);
}

.btn-success:disabled,
.btn-danger:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.loading-spinner {
  text-align: center;
  padding: 3rem;
  color: #00f0ff;
  font-size: 1.1rem;
}

.error-message {
  color: #ff4e4e;
  background: rgba(255, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin: 1rem 0;
  border: 1px solid rgba(255, 0, 0, 0.2);
}

.no-requests {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  padding: 3rem;
  font-size: 1.1rem;
  background: rgba(0, 240, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

@media (max-width: 768px) {
  .invitation-requests-management {
    padding: 0.75rem;
  }

  .management-header {
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  .filter-controls {
    overflow-x: auto;
    padding-bottom: 0.5rem;
    flex-wrap: nowrap;
  }

  .filter-btn {
    white-space: nowrap;
  }

  .requests-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .request-card {
    padding: 1.25rem;
  }

  .status-badge {
    top: 0.75rem;
    right: 0.75rem;
  }

  .request-actions {
    flex-direction: column;
    gap: 0.75rem;
  }

  .request-actions button {
    width: 100%;
  }
}

/* Listing Management Styles */
.listings-management {
  padding: 2rem;
}

.listings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.listing-card {
  background: rgba(13, 17, 23, 0.4);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.listing-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.listing-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listing-details {
  padding: 1rem;
}

.listing-details h3 {
  margin: 0 0 1rem;
  color: #fff;
}

.category-section {
  margin-bottom: 1rem;
}

.category-label {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-label:hover {
  background: rgba(0, 240, 255, 0.15);
}

.category-section select {
  width: 100%;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.listing-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.btn-danger {
  color: #ff3b30 !important;
  border-color: rgba(255, 59, 48, 0.2) !important;
}

.btn-danger:hover {
  background: rgba(255, 59, 48, 0.1) !important;
}

/* Chart Styles */
.admin-charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  padding: 1rem;
}

.chart-container {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.5rem;
  min-height: 300px;
}

.chart-container h3 {
  color: #00f0ff;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

@media (max-width: 768px) {
  .admin-charts {
    grid-template-columns: 1fr;
  }
  
  .chart-container {
    min-height: 250px;
  }
}

/* Additional stat card styles */
.stat-details .highlight {
  color: #00f0ff;
  font-weight: 500;
}

.stat-card:nth-child(5) p {
  /* Messages count */
  color: #4CAF50;
}

.stat-card:nth-child(6) p {
  /* Store sales */
  color: #00f0ff;
  font-size: 2.4rem;
}

/* Update grid layout to accommodate more cards */
@media (min-width: 1400px) {
  .admin-stats {
    grid-template-columns: repeat(3, 1fr);
  }
} 