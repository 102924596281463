.about-page {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.hero-description {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: var(--text-secondary);
}

.vision-mission-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.vision-mission-card {
  background: rgba(0, 240, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.vision-mission-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 240, 255, 0.3);
}

.separator {
  width: 50px;
  height: 2px;
  background: linear-gradient(90deg, transparent, #00f0ff, transparent);
  border: none;
  margin: 15px auto;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.stat-item {
  text-align: center;
  padding: 2rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.stat-icon {
  font-size: 2.5rem;
  color: #00f0ff;
  margin-bottom: 1rem;
}

.offerings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.offering-card {
  padding: 2rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.offering-icon {
  font-size: 2rem;
  color: #00f0ff;
  margin-bottom: 1rem;
}

.offering-card ul {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.offering-card li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.offering-card li::before {
  content: '•';
  color: #00f0ff;
  position: absolute;
  left: 0;
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.achievement-item {
  text-align: center;
  padding: 2rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.achievement-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 240, 255, 0.3);
}

.stories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.story-card {
  background: rgba(0, 240, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.story-card q {
  display: block;
  font-style: italic;
  margin-bottom: 1rem;
  color: var(--text-primary);
  line-height: 1.6;
}

.story-author {
  color: #00f0ff;
  text-align: right;
}

.future-description {
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
  line-height: 1.8;
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .hero-description {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .vision-mission-grid,
  .stats-grid,
  .offerings-grid,
  .achievements-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .timeline-item {
    width: calc(100% - 50px);
    margin-left: 50px !important;
  }

  .stories-grid {
    grid-template-columns: 1fr;
  }
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-top: 3rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.value-item {
  background: rgba(0, 240, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.value-icon {
  font-size: 3rem;
  color: #00f0ff;
  margin-bottom: 1.5rem;
  filter: drop-shadow(0 0 10px rgba(0, 240, 255, 0.3));
}

.value-item h3 {
  color: #00f0ff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.value-item h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, #00f0ff, transparent);
}

.value-item p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.value-highlight {
  background: rgba(0, 240, 255, 0.1);
  padding: 8px 15px;
  border-radius: 20px;
  display: inline-block;
  margin-top: 10px;
}

.value-highlight span {
  color: #00f0ff;
  font-size: 0.9rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .values-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}


/* Timeline Styles - Grouped together */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, transparent, #00f0ff, transparent);
}

.timeline-item {
  position: relative;
  margin: 30px 0;
  padding: 20px;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  width: calc(50% - 30px);
  transition: all 0.3s ease;
}

.timeline-item:hover {
  transform: translateY(-5px);
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.timeline-item:nth-child(odd) {
  margin-left: auto;
  margin-right: 0px;
}

.timeline-item:nth-child(even) {
  margin-right: auto;
  margin-left: 0px;
}

.timeline-item::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background: linear-gradient(90deg, #00f0ff, transparent);
}

.timeline-item:nth-child(odd)::before {
  left: -30px;
}

.timeline-item:nth-child(even)::before {
  right: -30px;
  background: linear-gradient(90deg, transparent, #00f0ff);
}

.year {
  color: #00f0ff;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}

/* Timeline Mobile Responsiveness - Updated */
@media (max-width: 768px) {
  .timeline {
    padding: 20px 0 20px 40px; /* Adjust padding to accommodate left-aligned items */
  }

  .timeline::before {
    display: none;
  }

  .timeline-item {
    width: calc(100% - 50px); /* Adjust width */
    margin: 30px 0 30px 30px !important; /* Consistent margins */
  }

  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    margin-left: 30px !important;
    margin-right: 0 !important;
  }

  .timeline-item::before {
    left: -30px !important;
    width: 30px;
    background: linear-gradient(90deg, #00f0ff, transparent) !important;
  }

  .year {
    font-size: 1.6rem; /* Slightly smaller on mobile */
  }

  /* Improve other sections responsiveness */
  .hero-description {
    font-size: 1rem;
    padding: 0 15px;
    margin: 20px auto;
  }

  .values-grid {
    grid-template-columns: 1fr !important; /* Force single column */
    gap: 1.5rem;
    padding: 0 15px;
  }

  .value-item {
    padding: 1.5rem;
  }

  .value-icon {
    font-size: 2.5rem;
  }

  .value-item h3 {
    font-size: 1.3rem;
  }

  .stories-grid {
    grid-template-columns: 1fr !important; /* Force single column */
    gap: 1.5rem;
    padding: 0 15px;
  }

  .story-card {
    padding: 1.5rem;
  }

  .story-card q {
    font-size: 1rem;
  }

  .future-description {
    padding: 0 15px;
    font-size: 1rem;
  }

  .section {
    padding: 40px 0; /* Reduce section padding on mobile */
  }

  .container {
    padding: 0 15px;
  }

  .section-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

/* Additional responsive breakpoint for medium screens */
@media (max-width: 1024px) {
  .values-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .stories-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small screen adjustments */
@media (max-width: 480px) {
  .timeline-item {
    padding: 15px;
  }

  .year {
    font-size: 1.4rem;
  }

  .value-item,
  .story-card {
    padding: 1.2rem;
  }

  .section-title {
    font-size: 1.6rem;
  }
}

/* Founder's Message Section */
.founder-content {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 3rem;
  align-items: start;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  padding: 2rem;
}

.founder-image {
  text-align: center;
}

.founder-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
}

.founder-image h3 {
  margin-top: 1rem;
  color: var(--accent-color);
  font-size: 1.2rem;
}

.founder-title {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.founder-text p {
  margin-bottom: 1.5rem;
  line-height: 1.8;
  color: var(--text-secondary);
}

/* Milestones Grid */
.milestones-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.milestone-card {
  background: rgba(0, 240, 255, 0.05);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
}

.milestone-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 240, 255, 0.3);
}

.milestone-icon {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
  filter: drop-shadow(0 0 10px rgba(0, 240, 255, 0.3));
}

.milestone-card h3 {
  color: var(--accent-color);
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}

.milestone-card h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accent-color),
    transparent
  );
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.2);
}

.milestone-card p {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 0.95rem;
}

.milestone-card:hover h3::after {
  width: 80px;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.4);
  transition: all 0.3s ease;
}

/* Ecosystem Section */
.ecosystem-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.ecosystem-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.ecosystem-icon {
  margin-bottom: 1.5rem;
}

.ecosystem-icon .icon {
  font-size: 2.5rem;
  color: #00f0ff;
}

.ecosystem-card h3 {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.ecosystem-card p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.ecosystem-stats {
  background: rgba(0, 240, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  display: inline-block;
}

.ecosystem-stats span {
  color: #00f0ff;
  font-weight: 600;
}

@media (max-width: 768px) {
  .ecosystem-grid {
    grid-template-columns: 1fr;
  }
}

/* Organization Structure Styles */
.org-hierarchy {
  margin-top: 3rem;
  position: relative;
}

.org-social {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
}

.social-link:hover {
  background: rgba(0, 240, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.org-founder {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
}

.org-founder::after {
  content: '';
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 2rem;
  background: linear-gradient(to bottom, var(--accent-color), transparent);
}

.org-level {
  margin-bottom: 4rem;
  position: relative;
}

.level-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent-color);
  font-size: 1.5rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.member-card {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  padding: 1rem;
  text-align: center;
  transition: all 0.3s ease;
}

.member-card:hover {
  transform: translateY(-5px);
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.member-card img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  margin-bottom: 1rem;
}

.member-card.founder img {
  width: 120px;
  height: 120px;
}

.member-info h3, .member-info h4 {
  color: var(--accent-color);
  margin-bottom: 0.5rem;
}

.member-info .role {
  display: block;
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .members-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .members-grid {
    grid-template-columns: 1fr;
  }
}

.member-info .position {
  display: block;
  color: var(--text-secondary);
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0.8rem;
  opacity: 0.8;
}

.member-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.2);
}

/* Founder image specific styles */
.founder-image img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.3);
}

/* Section spacing - remove bottom margins */
.about-page .section {
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  animation: fadeIn 1s ease-out;
}

/* Define the fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Container spacing - remove bottom margins */
.about-page .container {
  margin: 0;
  padding: 0 1rem;
}

/* Title spacing and alignment */
.about-page .section-title {
  color: var(--accent-color);
  text-align: center;
  margin: 2rem 0;  /* Added equal top and bottom margin */
  position: relative;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  width: 100%;  /* Ensure full width */
}

/* Hero section title specific */
.hero .section-title {
  font-size: 2.5rem;
  margin: 2rem auto;  /* Center with auto horizontal margins */
  max-width: 800px;  /* Limit width for better readability */
}

/* Level titles in org structure */
.level-title {
  display: flex;
  align-items: center;
  justify-content: center;  /* Center the title */
  gap: 0.5rem;
  color: var(--accent-color);
  font-size: 1.5rem;
  margin: 2rem 0;  /* Added equal top and bottom margin */
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  text-align: center;  /* Ensure text is centered */
}

/* Container adjustments */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;  /* Center the container */
  padding: 0 1rem;
}

/* Section spacing */
.section {
  width: 100%;  /* Ensure full width */
  padding: 1rem 0;
  margin: 0;
  animation: fadeIn 1s ease-out;
}

/* Organization structure specific spacing */
.org-hierarchy {
  margin-top: 2rem;
  margin-bottom: 0;  /* Remove bottom margin */
}

.org-social {
  margin-bottom: 2rem;
}

.org-founder {
  margin-bottom: 2rem;  /* Reduced margin */
}

.org-level {
  margin-bottom: 2rem;  /* Reduced margin */
}

/* Milestones spacing */
.milestones-grid {
  margin-top: 2rem;
  margin-bottom: 0;  /* Remove bottom margin */
}

/* Ecosystem spacing */
.ecosystem-grid {
  margin-top: 1.5rem;
  margin-bottom: 0;  /* Remove bottom margin */
}

/* Values section spacing */
.values-grid {
  margin-top: 2rem;
  margin-bottom: 0;  /* Remove bottom margin */
}

/* Journey section spacing */
.timeline {
  margin: 2rem auto 0;  /* Remove bottom margin */
}

.timeline-item {
  margin: 1.5rem 0;
}

/* Founder message spacing */
.founder-content {
  margin-top: 1rem;
  margin-bottom: 0;  /* Remove bottom margin */
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  /* Hero section */
  .hero .section-title {
    font-size: 2rem;
    padding: 0 15px;
  }

  .hero-description {
    font-size: 1rem;
    padding: 0 15px;
  }

  /* Founder section */
  .founder-content {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1.5rem;
  }

  /* Organization structure */
  .org-social {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .org-hierarchy {
    margin-top: 1.5rem;
  }

  .members-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }

  .member-card {
    padding: 1rem;
  }

  .member-card img {
    width: 80px;
    height: 80px;
  }

  .member-info h4 {
    font-size: 1.1rem;
  }

  .member-info .position {
    font-size: 0.75rem;
  }

  /* Milestones section */
  .milestones-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  /* Ecosystem section */
  .ecosystem-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  /* Container padding */
  .container {
    padding: 0 15px;
  }

  /* Section titles */
  .section-title {
    font-size: 1.8rem;
  }

  .level-title {
    font-size: 1.3rem;
  }
}

/* Small mobile screens */
@media (max-width: 480px) {
  .hero .section-title {
    font-size: 1.8rem;
  }

  .member-card {
    margin: 0 10px;
  }

  .org-social a {
    width: 100%;
    justify-content: center;
  }

  .section-title {
    font-size: 1.6rem;
  }
}