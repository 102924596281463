.listing-management {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.management-header {
  margin-bottom: 2rem;
}

.management-header h2 {
  color: #00f0ff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.management-controls {
  display: flex;
  gap: 1rem;
}

.search-input, .filter-select {
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  color: #fff;
}

.search-input {
  flex: 1;
}

/* Simple list layout */
.listings-grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Simplified card */
.listing-card {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.2);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.listing-main {
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.listing-info {
  flex: 1;
}

.listing-title {
  color: #00f0ff;
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

.listing-meta {
  display: flex;
  gap: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.meta-label {
  color: rgba(255, 255, 255, 0.5);
}

.listing-actions {
  display: flex;
  gap: 0.75rem;
  margin-left: auto;
  align-items: center;
}

.action-btn {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-1px);
}

.btn-toggle.active {
  color: #2ecc71;
}

.btn-delete:hover {
  color: #e74c3c;
}

.action-btn.btn-edit {
  color: #00f0ff;
}

.action-btn.btn-edit:hover {
  color: #4dffff;
  transform: translateY(-1px);
}

.seller-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.seller-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.seller-name {
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

.listing-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.listing-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 6px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.detail-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
}

.detail-value {
  color: #fff;
  font-size: 0.9rem;
  text-align: right;
}

.status-badge {
  padding: 0.35rem 0.75rem;
  border-radius: 15px;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-badge::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: currentColor;
}

.status-active {
  background: rgba(46, 204, 113, 0.1);
  color: #2ecc71;
}

.status-inactive {
  background: rgba(231, 76, 60, 0.1);
  color: #e74c3c;
}

/* Loading and Empty States */
.loading-state,
.no-listings {
  text-align: center;
  padding: 3rem;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(0, 240, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .listing-meta {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .listing-card {
    grid-template-columns: 1fr;
  }
  
  .listing-meta {
    grid-template-columns: 1fr;
  }
  
  .status-badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  .listing-details {
    grid-template-columns: 1fr;
  }
  
  .listing-actions {
    flex-direction: column;
  }
}

.category-label {
  color: #00f0ff;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.category-label:hover {
  background: rgba(0, 240, 255, 0.1);
}

/* When category is not set */
.category-label:empty::after {
  content: 'Uncategorized';
  color: rgba(255, 255, 255, 0.5);
}

.category-select {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  color: #fff;
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.category-select:hover,
.category-select:focus {
  border-color: rgba(0, 240, 255, 0.4);
  outline: none;
}

.category-select option {
  background: rgb(26, 31, 46);
  color: #fff;
  padding: 0.5rem;
}

.current-category {
  color: #00f0ff;
  font-size: 0.9rem;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.page-btn {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #00f0ff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-btn:hover:not(:disabled) {
  background: rgba(0, 240, 255, 0.2);
  transform: translateY(-1px);
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-btn.active {
  background: #00f0ff;
  color: #1a1f2e;
}

.page-numbers {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page-ellipsis {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 0.5rem;
}

.pagination-info {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pagination-controls {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .page-btn {
    padding: 0.5rem;
    min-width: 36px;
  }

  .page-numbers {
    order: -1;
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
} 