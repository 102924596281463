/* Add or update stat separator styles */
.stat-separator {
  width: 50px;
  height: 2px;
  background: linear-gradient(90deg, transparent, #00f0ff, transparent);
  border: none;
  margin: 15px auto;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.stat-item:hover .stat-separator {
  width: 80px;
  opacity: 1;
  background: linear-gradient(90deg, 
    transparent, 
    rgba(0, 240, 255, 0.8), 
    #00f0ff,
    rgba(0, 240, 255, 0.8), 
    transparent
  );
  box-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
}

/* Update stat item styles to complement the separator */
.stat-item {
  background: rgba(26, 31, 46, 0.8);
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.stat-item:hover {
  background: rgba(26, 31, 46, 0.9);
  border-color: rgba(0, 240, 255, 0.3);
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.stat-icon {
  color: #00f0ff;
  font-size: 2.5rem;
  margin-bottom: 15px;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.stat-item:hover .stat-icon {
  opacity: 1;
  transform: scale(1.1);
}

.stat-item h3 {
  color: #00f0ff;
  margin: 10px 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.stat-description {
  color: #a0aec0;
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 15px;
}

/* Update hero section styles */
.hero {
  text-align: center;
  padding: 100px 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 240, 255, 0.1),
    transparent
  );
}

.highlight-years {
  position: relative;
  color: #00f0ff;
  font-weight: bold;
}

.highlight-years::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, 
    transparent, 
    #00f0ff,
    #00f0ff,
    transparent
  );
  animation: glow 2s infinite;
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 5px rgba(0, 240, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 240, 255, 0.8);
  }
}

.hero-stats {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin: 3rem 0;
}

.hero-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #00f0ff;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: var(--text-secondary);
  font-size: 1rem;
}

/* Update stats section styles */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.stat-item {
  background: rgba(0, 240, 255, 0.05);
  padding: 2.5rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  text-align: center;
  transition: all 0.4s ease;
}

.stat-item:hover {
  transform: translateY(-10px);
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

/* Add success stories styles */
.success-stories {
  background: rgba(0, 240, 255, 0.02);
  padding: 80px 0;
}

.stories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.story-card {
  background: rgba(0, 240, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
}

.story-card:hover {
  transform: translateY(-5px);
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.story-content q {
  display: block;
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
  font-style: italic;
}

.story-author {
  color: #00f0ff;
  text-align: right;
  font-size: 0.9rem;
}

/* Update responsive styles */
@media (max-width: 768px) {
  .hero .section-title {
    font-size: 2rem;
  }

  .hero .subtitle {
    font-size: 1.2rem;
  }

  .stories-grid {
    grid-template-columns: 1fr;
  }
}

/* Update impact section styles */
.impact-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.impact-item {
  background: rgba(0, 240, 255, 0.05);
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.4s ease;
  text-align: center;
}

.impact-item:hover {
  transform: translateY(-5px);
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.impact-icon {
  font-size: 2.5rem;
  color: #00f0ff;
  margin-bottom: 1.5rem;
  filter: drop-shadow(0 0 10px rgba(0, 240, 255, 0.3));
}

.impact-item h3 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.impact-item p {
  color: var(--text-secondary);
  line-height: 1.6;
}

@media (max-width: 1200px) {
  .impact-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-stats {
    flex-direction: column;
    gap: 2rem;
  }

  .impact-grid {
    grid-template-columns: 1fr;
  }

  .stat-number {
    font-size: 2rem;
  }
}

/* Add separator styles for impact items */
.impact-separator {
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, #00f0ff, transparent);
  border: none;
  margin: 15px 0;
  opacity: 0.7;
}

/* Enhanced offering styles */
.offerings-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.offering-item {
  background: rgba(0, 240, 255, 0.05);
  padding: 2.5rem;
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.4s ease;
}

.offering-icon {
  font-size: 2.5rem;
  color: #00f0ff;
  margin-bottom: 1.5rem;
  filter: drop-shadow(0 0 10px rgba(0, 240, 255, 0.3));
}

.offering-separator {
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, #00f0ff, transparent);
  border: none;
  margin: 15px 0;
  opacity: 0.7;
}

.offering-item h3 {
  color: #00f0ff;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.offering-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.offering-item li {
  color: var(--text-secondary);
  margin: 12px 0;
  padding-left: 24px;
  position: relative;
  line-height: 1.6;
}

.offering-item li::before {
  content: '→';
  color: #00f0ff;
  position: absolute;
  left: 0;
  opacity: 0.8;
}

.offering-item:hover {
  background: rgba(0, 240, 255, 0.08);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

/* Enhanced CTA description */
.cta-description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ffffff;
  max-width: 800px;
  margin: 1.5rem auto 2rem;
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.2);
  opacity: 0.9;
}

.cta-description:hover {
  opacity: 1;
  text-shadow: 0 0 15px rgba(0, 240, 255, 0.3);
}

@media (max-width: 1200px) {
  .offerings-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .offerings-grid {
    grid-template-columns: 1fr;
  }
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
}

.hero .section-title {
  display: block;
  font-size: 3.2rem;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 900px;
}

.hero .btn {
  margin: 0 10px;
  display: inline-block;
  min-width: 180px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.hero .btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: all 0.5s ease;
}

.hero .btn:hover::before {
  left: 100%;
}

.hero .btn-primary {
  background: #00f0ff;
  color: #0a0e17;
  border: none;
}

.hero .btn-primary:hover {
  background: #33f5ff;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.4);
}

.hero .btn-secondary {
  background: transparent;
  color: #00f0ff;
  border: 1px solid #00f0ff;
}

.hero .btn-secondary:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.hero .container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .hero .section-title {
    font-size: 2.2rem;
    padding: 0 15px;
  }
  
  .hero .btn {
    margin: 10px;
    width: 100%;
    max-width: 300px;
  }
} 