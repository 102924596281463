.catalog-management {
  padding: 20px;
}

.section-title {
  margin-bottom: 20px;
}

.catalog-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.catalog-content {
  display: flex;
  flex-wrap: wrap;
}

.catalog-category {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.catalog-category h3 {
  margin-bottom: 10px;
}

.catalog-category ul {
  list-style-type: none;
  padding-left: 0;
}

.catalog-category li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.catalog-category input {
  margin-right: 10px;
  padding: 5px;
}

.catalog-category button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.catalog-category button:hover {
  background-color: #c82333;
}

.catalog-category .add-item {
  display: flex;
  margin-top: 10px;
}

.catalog-category .add-item textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.catalog-category .add-item button {
  background-color: #28a745;
}

.catalog-category .add-item button:hover {
  background-color: #218838;
}

.catalog-category .action-buttons {
  margin-top: 10px;
}

.catalog-category .action-buttons button {
  margin-right: 10px;
}

.catalog-category .action-buttons button:last-child {
  margin-right: 0;
}
