.order-management {
  padding: 2rem;
}

.order-management h2 {
  color: #00f0ff;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.filter-controls {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.filter-btn {
  padding: 0.5rem 1rem;
  background: transparent;
  border: none;
  color: #00f0ff;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.filter-btn:hover {
  background: rgba(0, 240, 255, 0.1);
}

.filter-btn.active {
  background: #00f0ff;
  color: #1a1f2e;
}

.table-container {
  background: rgba(26, 31, 46, 0.5);
  border-radius: 8px;
  overflow-x: auto;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th {
  background: rgba(26, 31, 46, 0.8);
  color: #00f0ff;
  font-weight: normal;
  text-align: left;
  padding: 1rem;
}

.orders-table td {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.orders-table tr:hover {
  background: rgba(0, 240, 255, 0.03);
}

.item-name {
  color: #fff;
}

.status-badge {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.85rem;
}

.table-actions {
  display: flex;
  gap: 0.5rem;
}

.btn-process,
.btn-complete,
.btn-cancel {
  padding: 0.3rem 0.8rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: filter 0.2s;
}

.btn-process {
  background: rgba(0, 150, 255, 0.2);
  color: #0096ff;
}

.btn-cancel {
  background: rgba(255, 0, 0, 0.2);
  color: #ff4e4e;
}

.btn-process:hover,
.btn-complete:hover,
.btn-cancel:hover {
  filter: brightness(1.2);
}

@media (max-width: 768px) {
  .order-management {
    padding: 1rem;
  }
  
  .table-container {
    margin: 0 -1rem;
    border-radius: 0;
  }
}

.customer-details {
  font-size: 0.9rem;
  color: #fff;
}

.customer-name {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.customer-email,
.customer-phone,
.customer-address {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
  margin-bottom: 0.25rem;
}

.order-items {
  color: #fff;
}

.item-quantity {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.status-badge {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.85rem;
}

.status-pending {
  background: rgba(255, 165, 0, 0.2);
  color: #ffa500;
}

.status-processing {
  background: rgba(0, 150, 255, 0.2);
  color: #0096ff;
}

.status-completed {
  background: rgba(0, 255, 0, 0.2);
  color: #4eff4e;
}

.status-cancelled {
  background: rgba(255, 0, 0, 0.2);
  color: #ff4e4e;
}

.order-item {
  padding: 0.5rem 0;
}

.item-variations,
.variation-details {
  display: none;
}

.item-row {
  display: flex;
  padding: 0.25rem 0;
}

.item-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.item-name {
  color: #fff;
  font-weight: 500;
  min-width: 120px;
}

.item-variant {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
  min-width: 100px;
}

.item-qty {
  color: #00f0ff;
  font-size: 0.85rem;
  min-width: 40px;
}

.item-price {
  color: #00f0ff;
  font-size: 0.85rem;
  text-align: right;
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-bottom: 0;
  border-bottom: none;
}

.order-id {
  color: #00f0ff;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.order-date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
}

.combined-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.customer-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border-top: none;
}

.customer-name,
.customer-email,
.customer-phone,
.customer-address {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85rem;
}

.customer-name {
  color: #fff;
  font-weight: 500;
}

.item-variations,
.variation-details {
  display: none;
}

.clickable {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: opacity 0.2s;
}

.clickable:hover {
  opacity: 0.8;
}

.copy-icon {
  font-size: 0.8rem;
  opacity: 0.5;
  visibility: hidden;
}

.clickable:hover .copy-icon {
  visibility: visible;
} 