/* Main Container */
.skill-exploration {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 60px;
}

/* Header Section */
.skill-exploration-header {
  margin-bottom: 2rem;
}

.skill-exploration-header h1 {
  color: #00f0ff;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Search and Filters Layout */
.search-filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  background: rgba(26, 31, 46, 0.4);
  border-radius: 12px;
  padding: 1rem;
}

/* Main filters row */
.main-filters-row {
  display: flex;
  gap: 0.75rem;
  width: 100%;
  align-items: flex-start;
}

/* Search Bar */
.search-bar {
  position: relative;
  width: 280px;
  min-width: 280px;
  margin: 0;
  flex: 0 0 auto;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #00f0ff;
  font-size: 1rem;
  z-index: 1;
}

.clear-search-btn {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #00f0ff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  z-index: 1;
}

.search-bar input {
  width: 100%;
  padding: 0.75rem 2.5rem;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 8px;
  color: #fff;
  font-size: 0.95rem;
}

.search-bar input:focus {
  border-color: #00f0ff;
  outline: none;
}

/* Filter Row */
.filter-row {
  display: flex;
  gap: 0.75rem;
  flex: 1;
}

/* Main Skill Select */
.main-skill-select {
  width: 280px;
  min-width: 280px;
  flex: 0 0 auto;
}

.main-skill-select select {
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 8px;
  color: #fff;
  font-size: 0.95rem;
  cursor: pointer;
  appearance: none;
}

/* Sub Skills Container */
.sub-skills-section {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  position: relative;
}

.sub-skills-container {
  flex: 1;
  background: rgba(26, 31, 46, 0.4);
  border-radius: 8px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sub-skills-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.sub-skill-chip {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 20px;
  padding: 0.4rem 1rem;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sub-skill-chip.selected {
  background: rgba(0, 240, 255, 0.1);
  border-color: #00f0ff;
  color: #00f0ff;
}

.sub-skill-chip input {
  display: none;
}

/* Quick Filters */
.quick-filters {
  display: flex;
  gap: 0.75rem;
  flex: 1;
  align-items: center;
}

.quick-filter-select {
  min-width: 160px;
  flex: 1;
  padding: 0.75rem 1rem;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 8px;
  color: #fff;
  font-size: 0.95rem;
  cursor: pointer;
  appearance: none;
}

/* Style for experience and availability selects */
.quick-filter-select option {
  background: rgba(26, 31, 46, 0.95);
  color: #fff;
  padding: 8px;
}

/* More Filters Button */
.filter-toggle-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.2rem;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 8px;
  color: #00f0ff;
  font-size: 0.95rem;
  cursor: pointer;
  white-space: nowrap;
}

/* Clear All Filters */
.clear-all-filters {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.2);
  border-radius: 20px;
  color: #ff4444;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
  height: 36px;
}

.clear-all-filters:hover {
  background: rgba(255, 68, 68, 0.15);
  transform: translateY(-1px);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .main-filters-row {
    flex-wrap: wrap;
  }

  .search-bar,
  .main-skill-select {
    width: 100%;
    min-width: 100%;
  }

  .filter-row {
    width: 100%;
  }

  .quick-filters {
    flex-wrap: wrap;
  }

  .quick-filter-select {
    min-width: calc(50% - 0.375rem);
  }
}

@media (max-width: 768px) {
  .search-filters {
    padding: 0.75rem;
  }

  .main-filters-row,
  .filter-row,
  .quick-filters {
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-bar,
  .main-skill-select,
  .quick-filter-select {
    width: 100%;
    min-width: 100%;
  }

  .sub-skills-section {
    flex-direction: column;
  }

  .clear-all-filters {
    align-self: stretch;
    justify-content: center;
  }
}

/* Scrollbar Styling */
.sub-skills-wrapper::-webkit-scrollbar {
  width: 6px;
}

.sub-skills-wrapper::-webkit-scrollbar-track {
  background: rgba(0, 240, 255, 0.05);
  border-radius: 3px;
}

.sub-skills-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 240, 255, 0.2);
  border-radius: 3px;
}

.sub-skills-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.3);
}

/* Filter selects */
.filter-select {
  position: relative;
  min-width: 160px;
  flex: 1;
}

.filter-select select {
  width: 100%;
  padding: 0.75rem 2rem 0.75rem 1rem;
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  appearance: none;
  font-size: 0.95rem;
}

.filter-select::after {
  content: '';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #00f0ff;
  pointer-events: none;
}

/* More filters button */
.filter-toggle-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.2rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-size: 0.95rem;
}

.filter-toggle-btn:hover {
  background: rgba(0, 240, 255, 0.15);
}

.filter-toggle-btn.active {
  background: rgba(0, 240, 255, 0.2);
  border-color: #00f0ff;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .skill-filter-section {
    grid-template-columns: 1fr;
  }

  .clear-all-filters {
    position: static;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 768px) {
  .filters {
    gap: 0.5rem;
  }

  .filter-select {
    min-width: calc(50% - 0.25rem);
  }

  .filter-toggle-btn {
    width: 100%;
    justify-content: center;
  }

  .sub-skills-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  .filter-select {
    min-width: 100%;
  }

  .sub-skills-grid {
    grid-template-columns: 1fr;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .search-filters {
    flex-direction: column;
    gap: 0.8rem;
  }

  .search-bar {
    width: 100%;
    max-width: none;
  }

  .filters {
    width: 100%;
    gap: 0.5rem;
  }

  .filter-select {
    min-width: calc(50% - 0.25rem);
  }

  .filter-toggle-btn {
    width: 100%;
  }
}

/* Small screen adjustments */
@media (max-width: 480px) {
  .filter-select {
    min-width: 100%;
  }
}

/* Filter Section Styles */
.filter-section {
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.filter-header h3 {
  color: #00f0ff;
  font-size: 1.1rem;
  margin: 0;
}

.filter-groups {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.filter-group h4 {
  color: #00f0ff;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.filter-option {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 20px;
  padding: 0.4rem 1rem;
  color: #a0a0a0;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-option:hover {
  background: rgba(0, 240, 255, 0.1);
  border-color: #00f0ff;
  color: #00f0ff;
}

.filter-option.active {
  background: rgba(0, 240, 255, 0.15);
  border-color: #00f0ff;
  color: #00f0ff;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
}

.filter-actions button {
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.reset-filters {
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.2);
  color: #ff4444;
}

.apply-filters {
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #00f0ff;
}

.filter-actions button:hover {
  transform: translateY(-2px);
}

/* Skill Cards Grid */
.profiles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* Skill Card */
.skill-card {
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 320px;
}

.skill-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 240, 255, 0.15);
  border-color: rgba(0, 240, 255, 0.4);
}

/* Card Header */
.skill-card-header {
  padding: 1.2rem;
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  min-height: 100px;
}

.skill-avatar-wrapper {
  flex-shrink: 0;
}

.skill-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #00f0ff;
}

.skill-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}

.skill-name-wrapper {
  text-align: left;
}

.skill-info h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  line-height: 1.3;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.skill-tagline {
  color: #a0a0a0;
  font-size: 0.85rem;
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 2.4em;
}

.skill-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-top: auto;
}

.location-info {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: #a0a0a0;
  font-size: 0.8rem;
}

.availability-badge {
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  background: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
  border: 1px solid rgba(76, 175, 80, 0.2);
}

.availability-badge.offline {
  background: rgba(158, 158, 158, 0.1);
  color: #9e9e9e;
  border-color: rgba(158, 158, 158, 0.2);
}

/* Card Body */
.skill-card-body {
  padding: 1.2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  min-height: 160px;
}

.expertise-areas h4 {
  color: #00f0ff;
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
}

.expertise-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin-bottom: 1rem;
  max-height: 60px;
  overflow: hidden;
}

.expertise-tag {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  color: #00f0ff;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.expertise-tag:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-1px);
}

.more-skills-tag {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  color: #a0a0a0;
}

.skill-highlights-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.02);
  border-radius: 8px;
  margin: 0;
  width: 100%;
  gap: 0.5rem;
}

.highlight-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  min-width: 0;
  padding: 0.5rem;
}

.highlight-icon {
  color: #00f0ff;
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
}

.highlight-value {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight-label {
  color: #a0a0a0;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight-item.rate {
  background: rgba(0, 240, 255, 0.1);
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
}

.short-bio {
  color: #a0a0a0;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  font-style: italic;
}

.experience-level {
  display: flex;
  align-items: center;
}

.experience-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 215, 0, 0.1);
  border: 1px solid rgba(255, 215, 0, 0.2);
  color: #ffd700;
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  height: 36px;
  white-space: nowrap;
}

.experience-icon {
  font-size: 0.9rem;
}

/* Work Highlights */
.work-highlights {
  display: flex;
  justify-content: space-around;
  margin: 1.2rem 0;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.02);
  border-radius: 8px;
}

.highlight-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}

.highlight-icon {
  color: #00f0ff;
  font-size: 1.2rem;
}

.highlight-item span {
  color: #fff;
  font-size: 1rem;
}

/* Card Footer */
.skill-card-footer {
  padding: 1.2rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1rem;
  height: 76px;
}

.rate-info .rate {
  color: #00f0ff;
  font-size: 1.1rem;
  font-weight: 500;
}

.card-actions {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  height: 36px;
}

.view-profile-btn {
  height: 36px;
  padding: 0 1.2rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 20px;
  color: #00f0ff;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.quick-contact-btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 50%;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-profile-btn:hover,
.quick-contact-btn:hover {
  background: rgba(0, 240, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

/* Loading & Error States */
.loading-spinner {
  display: block;
  width: 40px;
  height: 40px;
  margin: 2rem auto;
  border: 3px solid rgba(0, 240, 255, 0.1);
  border-top-color: #00f0ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-message {
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.2);
  color: #ff4444;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
}

/* Animations */
@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .skill-exploration {
    padding: 1rem;
  }

  .search-filters {
    flex-direction: column;
  }

  .search-bar,
  .filters {
    width: 100%;
    min-width: 100%;
  }

  .profiles-grid {
    grid-template-columns: 1fr;
  }

  .skill-card-header {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .availability-badge {
    align-self: flex-start;
    margin-top: 0.5rem;
  }

  .skill-highlights-row {
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.8rem;
  }

  .highlight-item {
    flex: 1;
    min-width: calc(50% - 0.5rem);
  }

  .expertise-tags {
    gap: 0.4rem;
  }

  .expertise-tag,
  .more-skills-tag {
    font-size: 0.8rem;
    padding: 0.25rem 0.6rem;
  }

  .skill-info {
    gap: 0.4rem;
  }

  .skill-tagline {
    font-size: 0.8rem;
    -webkit-line-clamp: 1;
    min-height: 1.2em;
  }
}

/* Update filter panel styles */
.filter-panel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  background: rgba(26, 31, 46, 0.98);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  z-index: 1000;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.filter-panel.show {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.filter-overlay.show {
  opacity: 1;
  visibility: visible;
}

.filter-panel-content {
  padding: 1.5rem;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.filter-header h3 {
  color: #00f0ff;
  font-size: 1.2rem;
  margin: 0;
}

.close-filters {
  background: none;
  border: none;
  color: #00f0ff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.close-filters:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: rotate(90deg);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .filter-panel {
    width: 95%;
    max-height: 85vh;
  }

  .filter-panel-content {
    padding: 1rem;
  }

  .filter-header {
    margin-bottom: 1rem;
  }

  .close-filters {
    font-size: 1.2rem;
    width: 28px;
    height: 28px;
  }
}

/* Small screen adjustments */
@media (max-width: 480px) {
  .filter-panel {
    width: 100%;
    height: auto;
    max-height: 90vh;
    border-radius: 12px 12px 0 0;
  }
}

/* Update load more button styles */
.load-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem auto;
  padding: 0.75rem 2rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 25px;
  color: #00f0ff;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.load-more-btn:hover {
  background: rgba(0, 240, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.load-more-btn .load-more-icon {
  transition: transform 0.3s ease;
}

.load-more-btn:hover .load-more-icon {
  transform: translateY(2px);
}

/* Update skill name styles */
.skill-name-wrapper h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  line-height: 1.3;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .expertise-tags {
    max-height: 50px;
  }

  .expertise-tag,
  .more-skills-tag {
    font-size: 0.7rem;
    padding: 0.15rem 0.5rem;
  }

  .load-more-btn {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
}

/* Update/Add these mobile-specific styles */
@media (max-width: 768px) {
  .skill-exploration {
    padding: 1rem;
  }

  .skill-exploration-header h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .search-filters {
    flex-direction: column;
    gap: 0.8rem;
  }

  .search-bar,
  .filters {
    width: 100%;
    min-width: 100%;
  }

  .filters {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .filters select,
  .filter-toggle-btn {
    flex: 1;
    min-width: calc(50% - 0.25rem);
    height: 40px;
    font-size: 0.9rem;
  }

  .profiles-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 1rem 0;
  }

  /* Card specific mobile styles */
  .skill-card {
    margin: 0;
    border-radius: 8px;
  }

  .skill-card-header {
    padding: 1rem;
    min-height: auto;
  }

  .skill-avatar {
    width: 50px;
    height: 50px;
  }

  .skill-info h3 {
    font-size: 1rem;
  }

  .skill-tagline {
    font-size: 0.8rem;
    -webkit-line-clamp: 2;
  }

  .skill-meta {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .location-info {
    font-size: 0.75rem;
  }

  .availability-badge {
    font-size: 0.7rem;
    padding: 0.15rem 0.4rem;
  }

  .skill-card-body {
    padding: 0.8rem;
    gap: 0.8rem;
  }

  .expertise-tags {
    gap: 0.3rem;
    max-height: 50px;
  }

  .expertise-tag {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  .more-skills-tag {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  .skill-highlights-row {
    padding: 0.8rem;
    gap: 0.5rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -0.8rem;
    width: calc(100% + 1.6rem);
  }

  .highlight-item {
    flex: 0 0 auto;
    min-width: 80px;
    padding: 0.5rem;
  }

  .highlight-icon {
    font-size: 1rem;
  }

  .highlight-value {
    font-size: 0.9rem;
  }

  .highlight-label {
    font-size: 0.7rem;
  }

  .skill-card-footer {
    padding: 0.8rem;
    height: auto;
  }

  .experience-tag {
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
  }

  .card-actions {
    gap: 0.5rem;
  }

  .view-profile-btn {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }

  .quick-contact-btn {
    width: 32px;
    height: 32px;
  }

  /* Filter panel mobile adjustments */
  .filter-panel {
    width: 100%;
    top: 0;
  }

  .filter-panel-content {
    padding: 1rem;
  }

  /* Load more button mobile style */
  .load-more-btn {
    width: 100%;
    margin: 1rem 0;
    padding: 0.8rem;
    font-size: 0.9rem;
  }
}

/* Small mobile devices */
@media (max-width: 380px) {
  .filters select,
  .filter-toggle-btn {
    min-width: 100%;
  }

  .skill-card-header {
    gap: 0.8rem;
  }

  .skill-avatar {
    width: 45px;
    height: 45px;
  }

  .highlight-item {
    min-width: 70px;
  }

  .expertise-tags {
    max-height: 45px;
  }

  .card-actions {
    flex-direction: column;
    width: 100%;
  }

  .view-profile-btn,
  .quick-contact-btn {
    width: 100%;
    justify-content: center;
  }

  .quick-contact-btn {
    height: 36px;
  }
}

/* Update these specific styles for skill highlights row */
@media (max-width: 768px) {
  .skill-highlights-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    padding: 0.75rem;
    margin: 0;
    width: 100%;
    overflow: visible;
  }

  .highlight-item {
    flex: 1;
    min-width: 0;
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.15rem;
  }

  .highlight-icon {
    font-size: 0.9rem;
    margin-bottom: 0.1rem;
  }

  .highlight-value {
    font-size: 0.85rem;
  }

  .highlight-label {
    font-size: 0.7rem;
  }
}

/* Even smaller screens */
@media (max-width: 380px) {
  .skill-highlights-row {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.4rem;
    padding: 0.6rem;
  }

  .highlight-item {
    padding: 0.3rem;
  }

  .highlight-value {
    font-size: 0.8rem;
  }

  .highlight-label {
    font-size: 0.65rem;
  }
}

/* Update filter panel mobile styles */
@media (max-width: 768px) {
  .filter-panel {
    position: fixed;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: 85vh;
    transform: translateY(100%);
    border-radius: 20px 20px 0 0;
    margin: 0;
  }

  .filter-panel.show {
    transform: translateY(0);
  }

  .filter-panel-content {
    padding: 1.2rem;
    max-height: 85vh;
    overflow-y: auto;
  }

  .filter-header {
    position: sticky;
    top: 0;
    background: rgba(26, 31, 46, 0.98);
    z-index: 2;
    padding: 1rem 0;
    margin-bottom: 1rem;
  }

  .filter-sections {
    padding-bottom: 80px; /* Space for buttons */
  }

  .filter-section {
    margin-bottom: 1rem;
    padding: 1rem;
  }

  .filter-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
  }

  .filter-option {
    width: 100%;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .rate-range {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 0.5rem;
    align-items: center;
  }

  .filter-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: rgba(26, 31, 46, 0.98);
    border-top: 1px solid rgba(0, 240, 255, 0.2);
    display: flex;
    gap: 1rem;
    z-index: 2;
  }
}

/* Contact Modal Styles */
.contact-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.contact-modal {
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 16px;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
}

.contact-modal-header {
  padding: 1.2rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-modal-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #00f0ff;
}

.contact-modal-info h3 {
  color: #fff;
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
}

.contact-modal-info p {
  color: #00f0ff;
  font-size: 0.9rem;
}

.contact-options {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.contact-option {
  padding: 1rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-option:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.contact-option-icon {
  width: 40px;
  height: 40px;
  background: rgba(0, 240, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.contact-option-info {
  flex: 1;
}

.contact-option-label {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.contact-option-value {
  font-size: 0.8rem;
  color: #a0a0a0;
}

.contact-modal-footer {
  padding: 1.2rem;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
  display: flex;
  justify-content: flex-end;
}

.close-modal-btn {
  padding: 0.8rem 1.5rem;
  background: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.2);
  border-radius: 8px;
  color: #ff4444;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-modal-btn:hover {
  background: rgba(255, 68, 68, 0.15);
}

/* Mobile adjustments for contact modal */
@media (max-width: 768px) {
  .contact-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    border-radius: 20px 20px 0 0;
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }

  .contact-modal.show {
    transform: translateY(0);
  }

  .contact-modal-header {
    padding: 1rem;
  }

  .contact-options {
    padding: 1rem;
    max-height: 60vh;
    overflow-y: auto;
  }

  .contact-option {
    padding: 0.8rem;
  }

  .contact-modal-footer {
    padding: 1rem;
  }
}

/* Sign-in prompt styles */
.sign-in-prompt-container {
  position: relative;
  margin-top: 2rem;
  padding: 1rem;
}

.blur-overlay {
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(
    to bottom,
    rgba(10, 14, 23, 0),
    rgba(10, 14, 23, 0.95) 70%
  );
  pointer-events: none;
}

.sign-in-prompt {
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 16px;
  padding: 2rem;
}

.prompt-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.lock-icon {
  color: #00f0ff;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.sign-in-prompt h3 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.sign-in-prompt p {
  color: #a0a0a0;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.audience-benefits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.benefit-group {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  padding: 1.25rem;
}

.benefit-group h4 {
  color: #00f0ff;
  font-size: 1rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.benefit-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefit-list li {
  color: #fff;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.benefit-list li:last-child {
  margin-bottom: 0;
}

.benefit-list li svg {
  color: #00f0ff;
  margin-top: 0.25rem;
  flex-shrink: 0;
  font-size: 0.8rem;
}

.prompt-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.sign-in-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  text-decoration: none;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.sign-in-btn.primary {
  background: linear-gradient(90deg, #00f0ff 0%, #0066ff 100%);
  color: #fff;
  border: none;
}

.sign-in-btn.secondary {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #00f0ff;
}

.sign-in-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .sign-in-prompt {
    padding: 1.5rem;
  }

  .sign-in-prompt h3 {
    font-size: 1.25rem;
  }

  .sign-in-prompt p {
    font-size: 0.9rem;
  }

  .audience-benefits {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .benefit-group {
    padding: 1rem;
  }

  .prompt-actions {
    flex-direction: column;
    gap: 0.75rem;
  }

  .sign-in-btn {
    width: 100%;
    min-width: 0;
  }
}

@media (max-width: 480px) {
  .sign-in-prompt {
    padding: 1.25rem;
  }

  .sign-in-prompt h3 {
    font-size: 1.2rem;
  }

  .benefit-group h4 {
    font-size: 0.95rem;
  }

  .benefit-list li {
    font-size: 0.85rem;
  }
}