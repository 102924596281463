.marketplace-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Clean, minimal header */
.marketplace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(26, 31, 46, 0.6);
  border-radius: 12px;
  border: 1px solid rgba(0, 240, 255, 0.1);
}

/* Update marketplace navigation and actions */
.marketplace-nav {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 1rem;
  align-items: center;
  margin: 5rem 0 2rem;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

/* Update filter styles to remove background */
.filter-section {
  display: flex;
  gap: 1rem;
  margin-left: auto;
  position: relative;
  z-index: 2;
}

/* Clean up select and search input styles */
.filter-select,
.search-input {
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 0.75rem 1rem;
  color: #fff;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%2300f0ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}

.filter-select option {
  background: rgb(26, 31, 46);
  color: #fff;
  padding: 0.75rem;
}

.search-input {
  min-width: 240px;
}

.filter-select:hover,
.search-input:hover,
.filter-select:focus,
.search-input:focus {
  border-color: rgba(0, 240, 255, 0.3);
  outline: none;
}

/* Services grid - update */
.marketplace-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns for desktop */
  gap: 1.5rem;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}

/* Base Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 9999;
  padding: 5rem 1rem 1rem;
  overflow-y: auto;
}

/* Common modal styles */
.modal,
.service-form-modal,
.service-view-modal {
  background: rgb(26, 31, 46);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  width: 95%;
  max-width: 600px;
  max-height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10000;
  margin: auto;
}

.service-view-modal {
  max-width: 800px;
}

/* Modal form styles */
.modal form,
.service-form-modal form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* Update modal header styles */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #fff;
}

.modal-header .close-btn {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s ease;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header .close-btn:hover {
  color: #fff;
}

.modal-header .close-btn svg {
  width: 20px;
  height: 20px;
}

/* Modal body scrolling */
.modal-body {
  overflow-y: auto;
  flex: 1;
  padding: 1.5rem;
}

.modal-footer {
  padding: 1.5rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  background: rgb(26, 31, 46);
}

/* Contact modal specific styles */
.service-details {
  margin-bottom: 1.5rem;
  /* padding: 1.5rem; */
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
}

.service-details h3 {
  margin: 0 0 0.5rem 0;
  color: #fff;
}

.service-details .price {
  color: #00f0ff;
  margin: 0;
  font-size: 1.1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

/* Update checkbox label styles */
.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  line-height: 1.4;
}

.checkbox-label input[type="checkbox"] {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}

/* Base button style */
.btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #fff;
  z-index: 1;
}

.btn:hover {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
  transform: translateY(-1px);
}

.btn-primary {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
}

.btn-secondary {
  color: rgba(255, 255, 255, 0.8);
}

/* Remove all individual button styles and use the base .btn class */
.nav-btn,
.list-service-btn,
.request-service-btn,
.add-feature-btn,
.messages-toggle {
  composes: btn;
}

.nav-btn.active {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
  color: #00f0ff;
}

/* Keep only unique functional styles */
/* .messages-toggle {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 900;
} */

.remove-btn {
  composes: btn;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3b30;
  border-color: rgba(255, 59, 48, 0.2);
}

.remove-btn:hover {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.3);
}

/* Form inputs */
.form-control {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 0.75rem 1rem;
  color: #fff;
  transition: all 0.2s ease;
}

.form-control:hover,
.form-control:focus {
  border-color: rgba(0, 240, 255, 0.3);
  outline: none;
}

/* Messages Section */
.messages-drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 350px;
  background: rgba(26, 31, 46, 0.95);
  border-left: 1px solid rgba(0, 240, 255, 0.1);
  padding: 1rem;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.messages-drawer.open {
  transform: translateX(0);
}

/* Add more styles... */

/* Add to existing Marketplace.css */
.nav-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  color: #fff;
  transition: all 0.2s ease;
  font-size: 0.95rem;
}

.nav-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
}

.nav-btn.active {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
  color: #00f0ff;
}

/* Stats Panel */
.stats-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
}

.stat-card h3 {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.stat-card p {
  color: #00f0ff;
  font-size: 2rem;
  font-weight: 600;
}

/* Form Grid Styles */
.form-grid {
  display: grid;
  gap: 2rem;
}

.form-section {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-section:last-child {
  margin-bottom: 0;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.form-group select,
.form-control {
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.95rem;
  width: 100%;
}

/* Add label to delivery time select */
.form-group select[name="deliveryTime"] {
  margin-top: 1.5rem; /* Align with category select that has label */
}

/* Feature Input Styles */
.feature-input {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.add-feature-btn {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  width: 100%;
  margin-top: 0.5rem;
  transition: all 0.2s ease;
}

.add-feature-btn:hover {
  background: rgba(0, 240, 255, 0.15);
}

/* Tags Input Styles */
.tags-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 16px;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.tag button {
  background: none;
  border: none;
  color: #00f0ff;
  font-size: 1.1rem;
  line-height: 1;
  padding: 0;
  cursor: pointer;
}

.tag-add {
  display: flex;
  gap: 0.5rem;
}

.tag-add input {
  flex: 1;
}

.tag-add button {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
}

.tag-add button:hover {
  background: rgba(0, 240, 255, 0.15);
}

/* Responsive Design */
@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .form-section:nth-child(1) {
    grid-column: 1 / -1;
  }
}

@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
  }
}

@media (max-width: 768px) {
  .marketplace-nav {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .filter-section {
    margin-left: 0;
    width: 100%;
  }

  .search-input {
    min-width: 0;
    flex: 1;
  }

  .services-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
  
}

@media (max-width: 640px) {
  .form-row {
    grid-template-columns: 1fr;
  }

  .modal-header {
    padding: 1rem;
  }

  .modal-body {
    padding: 1rem;
  }

  .modal-footer {
    padding: 1rem;
  }

  .form-section {
    padding: 1rem;
  }
}

/* Update header z-index if needed */
.header {
  z-index: 100;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 240, 255, 0.2);
  border-radius: 4px;
  border: 2px solid rgba(0, 240, 255, 0.1);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.3);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 240, 255, 0.2) rgba(0, 0, 0, 0.2);
}

/* Close button style */
.modal-header .btn {
  padding: 0.5rem;
  border: none;
}

.modal-header .btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: none;
}

/* Remove individual button styles */
.edit-btn,
.delete-btn,
.contact-btn {
  composes: btn;
}

/* Service card buttons - use base button styles */
.service-card .btn {
  flex: 1;
  justify-content: center;
  min-width: -webkit-fill-available;
  min-width: fit-content;
}

.service-card .btn svg {
  font-size: 1.1rem;
}

/* Service status styles */
.service-status {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-active {
  color: #00f0ff;
}

.status-inactive {
  color: rgba(255, 255, 255, 0.6);
}

/* Service card button styles */
.service-card .button-row {
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.service-card .btn-small {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  flex: 1;
  min-width: 0;
}

.service-card .btn-small svg {
  font-size: 1rem;
}

/* Inactive service card */
.service-card.inactive {
  opacity: 0.75;
  background: rgba(26, 31, 46, 0.4);
}

/* Buyer Requests Styles */
.requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.request-card {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  /* display: flex; */
  flex-direction: column;
  gap: 1rem;
}

.request-category {
  color: #00f0ff;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.request-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  line-height: 1.5;
}

.request-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.request-footer {
  display: flex;
  gap: 1rem;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

.budget {
  color: #00f0ff;
  font-weight: 500;
}

.deadline {
  color: rgba(255, 255, 255, 0.6);
}

/* Login prompt styles */
.login-prompt {
  text-align: center;
  padding: 3rem;
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  margin: 2rem auto;
  max-width: 400px;
}

.login-prompt p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
}

.login-prompt .btn {
  margin: 0 auto;
}

/* Empty state styles */
.no-requests {
  text-align: center;
  padding: 3rem;
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  /* margin: 2rem auto;
  max-width: 400px; */
}

.no-requests p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
}

.no-requests .btn {
  margin: 0 auto;
}

/* MyServices grid layout */
.my-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

@media (max-width: 1200px) {
  .my-services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .my-services-grid {
    grid-template-columns: 1fr;
  }
}

/* Contact button styles */
.btn-primary.btn-small {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border-color: rgba(0, 240, 255, 0.3);
}

.btn-primary.btn-small:hover {
  background: rgba(0, 240, 255, 0.2);
  border-color: rgba(0, 240, 255, 0.4);
}

/* Message form styles */
.message-form {
  margin-top: 1rem;
}

.message-form textarea {
  min-height: 100px;
  margin-bottom: 1rem;
}

/* Terms modal styles */
.terms-modal {
  background: rgb(26, 31, 46);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 2rem;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  margin: 2rem;
}

.terms-link {
  color: #00f0ff;
  text-decoration: underline;
  cursor: pointer;
}

.terms-link:hover {
  text-decoration: none;
}

/* Terms content styling */
.terms-content {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
}

.terms-content h3 {
  color: #00f0ff;
  margin: 2rem 0 1rem;
  font-size: 1.25rem;
}

.terms-content h4 {
  color: rgba(255, 255, 255, 0.9);
  margin: 1.5rem 0 0.75rem;
  font-size: 1.1rem;
}

.terms-content p {
  margin: 1rem 0;
}

.terms-content ul, 
.terms-content ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.terms-content li {
  margin: 0.5rem 0;
}

.terms-content .note {
  background: rgba(0, 240, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.terms-content .obligation-group,
.terms-content .ip-section,
.terms-content .communication-section,
.terms-content .legal-section {
  margin: 1.5rem 0;
}

.terms-content .disclaimer {
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

.terms-content .final-section {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
}

/* Image preview styles */
.image-preview {
  margin-top: 1rem;
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 16/9;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #000;
}

/* Image upload styles */
.image-upload {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.image-upload .form-control {
  flex: 1;
}

.image-upload-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.image-upload-btn:hover {
  background: rgba(0, 240, 255, 0.2);
  border-color: rgba(0, 240, 255, 0.3);
}

.image-upload-btn input[type="file"] {
  display: none;
}

.image-upload-btn.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.image-upload-btn.disabled:hover {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.2);
}

.service-provider {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.provider-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.provider-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.provider-name {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
}

.provider-stats {
  display: flex;
  gap: 1rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

.service-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  /* padding: 0.75rem; */
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0.75rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.detail-item .label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

.detail-item .value {
  font-size: 0.9rem;
  color: #00f0ff;
  font-weight: 500;
}

/* Update service card styles */
.service-card {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  max-width: 360px;
}

/* Image section */
.service-image-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-category {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.226);
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  font-size: 0.8rem;
  color: #ffffff;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

/* Content section */
.card-content {
  /* padding-left: 0px;
  padding-right: 0px; */
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Seller info */
.seller-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.seller-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(0, 240, 255, 0.2);
  flex-shrink: 0; /* Prevent avatar from shrinking */
}

.seller-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow: hidden; /* Prevent long names from breaking layout */
}

.seller-name {
  font-size: 0.9rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seller-stats {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

.seller-stats .rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.seller-stats .reviews {
  opacity: 0.8;
}

.rating {
  color: #ffd700;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

/* Service info */
.service-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

/* Update description and read more styles */
.service-description {
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.4;
  margin: 0;
  /* padding: 0.5rem 0.75rem; */
}

.service-description p {
  margin: 0;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more-btn {
  background: none;
  border: none;
  color: #00f0ff;
  padding: 0;
  font-size: 0.85rem;
  cursor: pointer;
  margin-top: 0.25rem;
  display: inline-block;
  transition: color 0.2s ease;
}

.read-more-btn:hover {
  color: #4dffff;
  text-decoration: underline;
}

/* Service View Modal specific styles */
.service-view-modal .service-image-container {
  width: 100%;
  height: 300px;
  margin-bottom: 1.5rem;
}

.service-view-modal .service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.service-view-modal .provider-info {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.service-view-modal .service-description {
  margin-bottom: 1.5rem;
}

.service-view-modal .service-description h3 {
  color: #fff;
  margin-bottom: 0.75rem;
}

.service-view-modal .service-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.service-view-modal .metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service-view-modal .metric .label {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0.25rem;
}

.service-view-modal .metric .value {
  font-size: 1.1rem;
  color: #00f0ff;
  font-weight: 500;
}

.service-view-modal .price-section {
  margin-top: 1.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.service-view-modal .price-header {
  margin-bottom: 0.5rem;
}

.service-view-modal .price-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.service-view-modal .price-amount {
  font-size: 1.5rem;
  color: #00f0ff;
  font-weight: 500;
}

/* Service details */
.service-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  text-align: center;
}

.detail-item .label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

.detail-item .value {
  font-size: 0.9rem;
  color: #00f0ff;
  font-weight: 500;
}

/* Footer section */
.card-footer {
  margin-top: auto;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.price-section {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.price-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

.price-amount {
  font-size: 1.1rem;
  color: #00f0ff;
  font-weight: 500;
}

/* Action buttons */
.card-actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.contact-btn {
  flex: 1;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #00f0ff;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.social-actions {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  /* width: 36px;
  height: 36px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s ease;
}

.action-btn:hover {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
  color: #00f0ff;
}

.action-btn.active {
  background: rgba(0, 240, 255, 0.1);
  border-color: #00f0ff;
  color: #00f0ff;
}

/* Animation for action buttons */
.action-btn svg {
  transition: transform 0.2s ease;
}

.action-btn.active svg {
  transform: scale(1.1);
}

.action-btn:hover svg {
  transform: scale(1.1);
}

.action-btn.active:hover svg {
  transform: scale(1);
}

/* Update new badge styles - remove frame, keep just text */
.new-badge {
  color: #4ade80; /* A nice green color */
  font-size: 0.8rem;
  font-weight: 500;
  /* Remove these properties:
  background: rgba(0, 240, 255, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  */
}

/* Update seller stats to maintain alignment without badge padding */
.seller-stats {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}

.seller-stats .rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.seller-stats .reviews {
  opacity: 0.8;
}

/* Update modal header styles */
.service-view-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  width: 100%;
  margin: 0;
}

/* Update modal footer styles for new layout */
.service-view-modal .modal-footer {
  padding: 1.5rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background: rgb(26, 31, 46);
  width: 100%;
  margin: 0;
}

/* Update modal body to maintain consistent padding */
.service-view-modal .modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  flex: 1;
  width: 100%;
}

/* Ensure the modal container uses proper box sizing */
.service-view-modal.modal {
  box-sizing: border-box;
  width: 95%;
  max-width: 800px; /* Increased from 600px for better layout */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Update Request Feed Styles */
.requests-container {
  max-width: 100%;  /* Changed from 1000px */
  margin: 0 auto;
  /* padding: 1rem 2rem; */
}

.requests-header {
  margin-bottom: 2rem;
  text-align: center;
}

.requests-header h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.requests-header p {
  color: rgba(255, 255, 255, 0.7);
}

.requests-feed {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

/* Update request row layout */
.request-row {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr auto;  /* Updated grid layout */
  gap: 1.5rem;
  align-items: center;
  transition: all 0.2s ease;
  width: 100%;
}

/* Update title section */
.request-title-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 0; /* Allow text truncation */
}

.request-title-section h3 {
  font-size: 1rem;
  color: #fff;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Update category badge */
.request-category {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00f0ff;
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  background: rgba(0, 240, 255, 0.1);
  border-radius: 16px;
  white-space: nowrap;
}

/* Update description */
.request-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0.25rem 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Update details section */
.request-details {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  white-space: nowrap;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.85rem;
}

.detail-item svg {
  color: #00f0ff;
  font-size: 1rem;
  flex-shrink: 0;
}

.detail-item .label {
  color: rgba(255, 255, 255, 0.6);
  margin-right: 0.25rem;
}

.detail-item .value {
  color: #fff;
  font-weight: 500;
}

/* Update actions section */
.request-actions {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
  margin-left: auto;
}

.request-actions .btn {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .request-row {
    grid-template-columns: 2fr 1fr auto;
  }
  
  .request-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .request-row {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .request-details {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .request-actions {
    justify-content: flex-start;
    width: 100%;
  }
  
  .request-actions .btn {
    flex: 1;
  }
}

/* My Services specific styles */
.my-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.my-services .service-card {
  position: relative;
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease;
}

.my-services .service-status {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.8rem;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.6);
}

.my-services .service-card[data-active="true"] .service-status {
  color: #4ade80;
}

.my-services .service-card[data-active="false"] .service-status {
  color: #ff3b30;
}

.my-services .service-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  padding: 1rem;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  margin-top: auto;
}

.my-services .service-actions .btn {
  width: 100%;
  justify-content: center;
  font-size: 0.85rem;
  padding: 0.5rem;
}

.my-services .btn-danger {
  color: #ff3b30;
  border-color: rgba(255, 59, 48, 0.2);
}

.my-services .btn-danger:hover {
  background: rgba(255, 59, 48, 0.1);
  border-color: rgba(255, 59, 48, 0.3);
}

/* Update social actions styling */
.social-actions .action-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: all 0.2s ease;
}

.social-actions .love-count {
  position: absolute;
  top: 0;
  right: -4px;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(26, 31, 46, 0.8);
  padding: 0.1rem 0.3rem;
  border-radius: 8px;
  min-width: 18px;
  text-align: center;
}

.social-actions .action-btn.active {
  color: #00f0ff;
}

.social-actions .action-btn.active .love-count {
  color: #00f0ff;
  background: rgba(0, 240, 255, 0.1);
}

.social-actions .action-btn:hover {
  color: #00f0ff;
}

/* Login prompt modal styles */
.login-prompt-modal {
  background: rgba(26, 31, 46, 0.95);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
  max-width: 400px;
  position: relative;
  text-align: center;
}

.login-prompt-modal h2 {
  color: #fff;
  margin-bottom: 1rem;
}

.login-prompt-modal p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
}

.login-prompt-modal .button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.login-prompt-modal .close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.5rem;
}

.login-prompt-modal .close-btn:hover {
  color: #fff;
}

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 3rem auto 1rem;
  padding: 1.5rem;
  /* max-width: 800px; */
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
}

.page-btn {
  min-width: 36px;
  height: 36px;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.15);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  transition: all 0.2s;
  cursor: pointer;
}

.page-btn.first,
.page-btn.last {
  padding-left: 2rem;
    padding-right: 2rem;
}

.page-btn.prev,
.page-btn.next {
  min-width: 80px;
}

.page-btn:hover:not(:disabled) {
  background: rgba(0, 240, 255, 0.1);
  border-color: rgba(0, 240, 255, 0.3);
  color: #00f0ff;
  transform: translateY(-1px);
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.page-btn.active {
  background: rgba(0, 240, 255, 0.15);
  border-color: rgba(0, 240, 255, 0.4);
  color: #00f0ff;
}

.page-numbers {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  margin: 0 0.5rem;
}

.page-ellipsis {
  color: rgba(255, 255, 255, 0.4);
  padding: 0 0.25rem;
  user-select: none;
}

.pagination-info {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  margin: 1rem 0 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
    padding: 1rem;
    gap: 0.75rem;
  }

  .page-numbers {
    order: -1;
    width: 100%;
    justify-content: center;
    margin: 0 0 0.75rem;
  }

  .page-btn.first,
  .page-btn.last {
    display: none; /* Hide on mobile */
  }

  .page-btn.prev,
  .page-btn.next {
    flex: 1;
  }
} 