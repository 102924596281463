.feedback-management {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 24px;
  padding: 20px;
  height: calc(100vh - 200px);
}

.feedback-list {
  overflow-y: auto;
  border-right: 1px solid rgba(0, 240, 255, 0.2);
  padding-right: 20px;
}

.feedback-item {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.feedback-item:hover {
  transform: translateY(-2px);
  border-color: #00f0ff;
}

.feedback-item.read {
  opacity: 0.7;
}

.feedback-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.feedback-type {
  color: #00f0ff;
  font-size: 0.9rem;
  padding: 2px 8px;
  background: rgba(0, 240, 255, 0.1);
  border-radius: 4px;
}

.feedback-date {
  color: #a0a0a0;
  font-size: 0.8rem;
}

.feedback-preview {
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 12px;
}

.feedback-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.feedback-actions button {
  background: none;
  border: none;
  color: #00f0ff;
  cursor: pointer;
  padding: 4px;
  transition: all 0.3s ease;
}

.feedback-actions button:hover {
  transform: scale(1.1);
}

.feedback-detail {
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
}

.feedback-detail h3 {
  color: #00f0ff;
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(0, 240, 255, 0.2);
  padding-bottom: 12px;
}

.detail-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info,
.message-content,
.ratings-section,
.screenshot-section,
.admin-note-section {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 16px;
}

.user-info h4,
.message-content h4,
.ratings-section h4,
.screenshot-section h4,
.admin-note-section h4 {
  color: #00f0ff;
  margin-bottom: 12px;
}

.rating-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.rating-stars {
  display: flex;
  gap: 4px;
}

.star {
  color: rgba(0, 240, 255, 0.2);
}

.star.active {
  color: #00f0ff;
}

.screenshot-preview {
  max-height: 300px;
  overflow: auto;
  border-radius: 4px;
}

.screenshot-preview img {
  width: 100%;
  height: auto;
}

.admin-note-section textarea {
  width: 100%;
  min-height: 100px;
  background: rgba(10, 14, 23, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 12px;
  color: #fff;
  margin-bottom: 12px;
}

.admin-note-section button {
  background: #00f0ff;
  color: #0a0e17;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-note-section button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

.existing-note {
  margin-top: 16px;
  padding: 12px;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 4px;
}

.note-date {
  display: block;
  font-size: 0.8rem;
  color: #a0a0a0;
  margin-top: 8px;
}

@media (max-width: 1024px) {
  .feedback-management {
    grid-template-columns: 1fr;
  }

  .feedback-list {
    border-right: none;
    border-bottom: 1px solid rgba(0, 240, 255, 0.2);
    padding-right: 0;
    padding-bottom: 20px;
  }
} 