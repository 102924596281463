.messages-drawer {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: rgb(20, 24, 36);
  border-left: 1px solid rgba(0, 240, 255, 0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.messages-drawer.open {
  right: 0;
}

.messages-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-header h3 {
  margin: 0;
  color: #fff;
}

.close-button {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.5rem;
}

.close-button:hover {
  color: #fff;
}

.messages-list {
  flex: 1;
  overflow-y: auto;
  padding: 0;
}

.message-item {
  background: rgba(26, 31, 46, 0.6);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.message-item:hover {
  background: rgba(26, 31, 46, 0.8);
}

.message-item.unread {
  background: rgba(0, 240, 255, 0.05);
  border-color: rgba(0, 240, 255, 0.2);
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.message-header h4 {
  margin: 0;
  color: #fff;
  font-size: 1rem;
}

.message-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.action-btn {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.25rem;
}

.action-btn:hover {
  color: #ff3b30;
}

.message-content {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.message-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.message-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
}

.message-status {
  display: flex;
  align-items: center;
}

.unread-badge {
  background: #00f0ff;
  color: #1a1f2e;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: 500;
}

.no-messages {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  padding: 2rem;
}

.no-messages p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
}

.no-messages small {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .messages-drawer {
    width: 100%;
    right: -100%;
  }
}

.thread-item {
  position: relative;
  background: rgba(26, 31, 46, 0.6);
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  transition: all 0.3s ease;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.thread-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.thread-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.thread-header {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  min-width: 0;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.thread-details {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
}

.thread-details h4 {
  margin: 0;
  color: #fff;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thread-topic {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
}

.messages-container {
  padding: 16px;
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 300px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  gap: 8px;
  padding-bottom: 16px;
}

/* Container for all messages to maintain proper spacing */
.messages-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message {
  max-width: 85%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
}

/* Add scrollbar styling */
.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.messages-container::-webkit-scrollbar-thumb {
  background: rgba(0, 240, 255, 0.2);
  border-radius: 3px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.3);
}

/* Add loading indicator */
.messages-loading {
  text-align: center;
  padding: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
}

/* Ensure messages stack correctly */
.message p {
  margin: 0;
  padding: 8px 12px;
  border-radius: 12px;
  color: #fff;
  line-height: 1.4;
}

.message.sent {
  align-self: flex-end;
}

.message.received {
  align-self: flex-start;
}

.message.sent p {
  background: rgba(0, 240, 255, 0.15);
  border-bottom-right-radius: 4px;
}

.message.received p {
  background: rgba(255, 255, 255, 0.1);
  border-bottom-left-radius: 4px;
}

.message-time {
  display: block;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 0.25rem;
}

.reply-form {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(20, 24, 36);
  border-top: 1px solid rgba(0, 240, 255, 0.1);
  padding: 12px 16px;
  z-index: 1;
  margin-top: auto;
}

.reply-form textarea {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  color: #fff;
  padding: 8px 12px;
  resize: none;
  font-size: 0.9rem;
}

.reply-form button {
  margin-top: 8px;
  align-self: flex-end;
}

.thread-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.thread-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thread-preview {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.85rem;
  margin-top: 4px;
}

.message-direction {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.sent-icon {
  transform: scaleX(-1);
  color: rgba(0, 240, 255, 0.6);
}

.received-icon {
  color: rgba(255, 255, 255, 0.6);
}

.unread-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgb(0, 240, 255);
  position: absolute;
  top: 12px;
  right: 12px;
}

.thread-item.unread {
  background: rgba(0, 240, 255, 0.05);
}

.no-thread-messages {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  padding: 1rem;
}

.no-thread-messages p {
  margin: 0;
  color: #fff;
  font-size: 0.9rem;
}

.no-thread-messages small {
  font-size: 0.8rem;
  opacity: 0.7;
}

.expand-icon {
  display: none;
}

.thread-item.expanded .expand-icon {
  display: block;
  color: rgba(255, 255, 255, 0.4);
  transform: rotate(180deg);
}

.messages-list.has-expanded .thread-item:not(.expanded) {
  display: none;
}

.thread-item.expanded {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}

.thread-item.expanded .messages-container {
  flex: 1;
  max-height: none;
} 