.contact-page {
  min-height: 100vh;
  /* background: linear-gradient(135deg, #0a0e17, #1a1f2e); */
  color: #fff;
}

/* Hero Section */
.hero.section {
  background: linear-gradient(to bottom, rgba(0, 240, 255, 0.1), transparent);
  padding: 4rem 0;
  text-align: center;
  margin-bottom: 3rem;
  /* margin-top: 30px; */
}

.hero .section-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #00f0ff;
}

.hero .subtitle {
  font-size: 1.2rem;
  color: #a0a0a0;
}

/* Contact Content */
.contact-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Contact Info Cards */
.contact-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
  width: 100%;
  box-sizing: border-box;
}

.contact-item {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  overflow: hidden;
}

.contact-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, transparent, #00f0ff, transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.contact-item:hover::before {
  opacity: 1;
}

.contact-item:hover {
  transform: translateY(-3px);
  background: rgba(0, 240, 255, 0.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.contact-icon {
  font-size: 2rem;
  color: #00f0ff;
  background: rgba(0, 240, 255, 0.1);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.contact-item:hover .contact-icon {
  transform: scale(1.1);
  background: rgba(0, 240, 255, 0.15);
  box-shadow: 0 0 20px rgba(0, 240, 255, 0.2);
}

.contact-item h3 {
  color: #00f0ff;
  font-size: 1.2rem;
  margin: 0;
  font-weight: 500;
}

.contact-item a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.1);
}

.contact-item a:hover {
  color: #00f0ff;
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.contact-detail {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Social Media Section */
.social-media-section {
  text-align: center;
  flex-direction: row;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
}

.social-links a {
  color: #00f0ff;
  font-size: 1.5rem;
  background: rgba(0, 240, 255, 0.05);
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 240, 255, 0.2);
  transition: all 0.3s ease;
}

.social-links a:hover {
  transform: translateY(-3px);
  background: rgba(0, 240, 255, 0.1);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

/* Form and FAQ Container */
.form-faq-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;
}

@media (max-width: 768px) {
  .form-faq-container {
    display: block;
  }
  
  .contact-form-section {
    margin-bottom: 2rem;
  }
}

.contact-form-section,
.faq-section {
  background: var(--bg-secondary);
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.1);
  padding: 2rem;
  height: 100%;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.faq-item {
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: rgba(0, 240, 255, 0.05);
  transition: all 0.3s ease;
}

.faq-question {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
  transition: all 0.3s ease;
}

.question-text {
  flex: 1;
  margin-right: 1rem;
}

.faq-icon {
  transition: transform 0.3s ease;
  color: var(--accent-color);
}

.faq-icon.rotated {
  transform: rotate(180deg);
}

.faq-answer-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.faq-item.expanded .faq-answer-wrapper {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

.faq-answer {
  padding: 0 1rem 1rem;
  color: var(--text-secondary);
  line-height: 1.6;
}

.faq-question:hover {
  background: rgba(0, 240, 255, 0.1);
}

.faq-item.expanded {
  box-shadow: 0 0 15px rgba(0, 240, 255, 0.1);
}

/* Custom scrollbar for FAQ list */
.faq-list::-webkit-scrollbar {
  width: 6px;
}

.faq-list::-webkit-scrollbar-track {
  background: rgba(0, 240, 255, 0.02);
  border-radius: 3px;
}

.faq-list::-webkit-scrollbar-thumb {
  background: rgba(0, 240, 255, 0.2);
  border-radius: 3px;
}

.faq-list::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 240, 255, 0.3);
}

/* Success Message */
.success-message {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  color: #00f0ff;
}

/* Responsive Design */
@media (max-width: 992px) {
  .contact-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }
  
  .form-faq-container {
    grid-template-columns: 1fr;
  }
  
  .hero .section-title {
    font-size: 2.5rem;
  }
  
  .contact-content {
    padding: 0 1rem;
  }
}

@media (max-width: 1024px) {
  .contact-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 1rem;
    gap: 1rem;
  }

  .contact-item {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
    margin: 1rem 0;
  }

  .contact-item {
    padding: 1.5rem;
    flex-direction: row;
    text-align: left;
    gap: 1.5rem;
  }

  .contact-icon {
    width: 48px;
    height: 48px;
    font-size: 1.5rem;
    margin: 0;
  }

  .contact-info {
    flex: 1;
  }

  .contact-item h3 {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
  }

  .contact-item a {
    font-size: 1rem;
    display: inline-block;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .contact-grid {
    gap: 0.75rem;
    margin: 0.75rem 0;
  }

  .contact-item {
    padding: 1.25rem;
    gap: 1rem;
  }

  .contact-icon {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
  }

  .contact-item h3 {
    font-size: 1rem;
  }

  .contact-item a {
    font-size: 0.95rem;
    padding: 0.4rem 0.75rem;
  }

  .contact-detail {
    font-size: 0.8rem;
  }
} 