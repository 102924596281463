.user-dashboard {
    /* padding: 20px; */
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
  }
  
  .page-title {
    color: #00f0ff;
    margin-bottom: 20px;
    font-size: 1.8rem;
    text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  }

  @media (max-width: 768px) {
    .page-title {
      margin-top: 40px;
    }
  }

  /* Navigation styles */
.admin-nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 buttons per row */
  gap: 0.75rem;
  margin-bottom: 2rem;
  width: 100%;
}

.admin-nav .btn {
  height: 56px;
  padding: 0 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  background: rgba(0, 240, 255, 0.05);
  color: #00f0ff;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%; /* Full width within grid cell */
}

.admin-nav .btn:hover {
  background: rgba(0, 240, 255, 0.15); /* Slightly increased from 0.1 but not too much */
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

.admin-nav .btn.active {
  background: #00f0ff;
  color: #1a1f2e;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .admin-nav {
    grid-template-columns: repeat(3, 1fr); /* 3 buttons per row on smaller screens */
  }
}

@media (max-width: 768px) {
  .admin-dashboard {
    padding: 0;
    margin-top: 30px;
  }

  .admin-nav {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 0;
  }

  .admin-nav .btn {
    width: 100%;
    height: 76px;
    padding: 1rem;
    font-size: 0.95rem;
    margin: 0;
    border-radius: 0;
  }
}

@media (max-width: 480px) {
  .admin-nav .btn {
    height: 84px;
  }
}

  .user-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 2rem;
  }
  
  .dashboard-grid {
    display: flex;
    gap: 1.5rem;
    padding: 0.5rem;
  }
  
  .dashboard-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .card {
    background: rgba(26, 31, 46, 0.8);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.3s ease;
  }
  
  .card h3 {
    color: #00f0ff;
    margin-bottom: 1.2rem;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  .card p {
    color: #a0a0a0;
    font-size: 0.9rem;
    margin: 1rem 0;
  }
  
  .progress-container {
    margin: 1.2rem 0;
  }
  
  .progress-bar {
    background: rgba(26, 31, 46, 0.9);
    border-radius: 8px;
    height: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 240, 255, 0.1);
  }
  
  .progress {
    background: linear-gradient(90deg, #00f0ff 0%, #0066ff 100%);
    height: 100%;
    transition: width 0.6s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  }
  
  .progress-container p {
    color: #00f0ff;
    font-size: 0.85rem;
    margin: 0.5rem 0;
    text-align: right;
  }
  
  .summary-card .btn {
    width: calc(100% - 2rem);
    height: 48px;
    margin: 1.5rem 1rem 0.5rem;
    padding: 0 24px;
    font-size: 0.95rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 240, 255, 0.3);
    background: rgba(0, 240, 255, 0.1);
    color: #00f0ff;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
  }
  
  .summary-card .btn:hover {
    background: rgba(0, 240, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
  }
  
  .summary-card .btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 240, 255, 0.1);
  }
  
  .invitation-details {
    margin-top: 10px;
  }
  
  .invitation-details .btn {
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    color: #00f0ff;
    height: 48px;
    margin-left: 0rem;
    font-size: 0.95rem;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
  }
  
  .invitation-details .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 240, 255, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .invitation-details .btn:hover {
    transform: translateY(-2px);
    border-color: rgba(0, 240, 255, 0.3);
    box-shadow: 0 4px 15px rgba(0, 240, 255, 0.2);
  }
  
  .invitation-details .btn:hover::before {
    transform: translateX(0);
  }
  
  .invitation-details .btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 240, 255, 0.1);
  }
  
  /* Ensure text stays above the hover effect */
  .invitation-details .btn span {
    position: relative;
    z-index: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .invitation-details .btn {
      height: 44px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .invitation-details .btn {
      height: 42px;
      font-size: 0.85rem;
    }
  }
  
  /* Dashboard Stats */
  .dashboard-stats {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .stat-card {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background: linear-gradient(135deg, #1a1f2e, #0a0e17);
    border-radius: 12px;
    border: 1px solid rgba(0, 240, 255, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .stat-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
  }
  
  .stat-card svg {
    font-size: 2rem;
    color: #00f0ff;
  }
  
  .stat-info {
    display: flex;
    flex-direction: column;
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
  }
  
  .stat-label {
    font-size: 0.9rem;
    color: #a0a0a0;
  }
  
  .message-icon {
    position: relative;
  }
  
  .unread-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #ff4444;
    color: white;
    font-size: 0.8rem;
    padding: 2px 6px;
    border-radius: 10px;
    min-width: 20px;
    text-align: center;
  }
  
  /* Messages Modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .messages-modal {
    background: #1a1f2e;
    width: 100%;
    max-width: 800px;
    height: 85vh;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
  }
  
  .message-view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  }
  
  .message-content {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
  }
  
  .message-body {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1rem 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .modal-overlay {
      padding: 0;
    }
  
    .messages-modal {
      height: 100%;
      border-radius: 0;
    }
  
    .message-content {
      padding: 1rem;
    }
  
    .message-body {
      padding: 1rem;
    }
  }
  
  .messages-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .message-item {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 240, 255, 0.1);
  }
  
  .message-item:hover {
    background: rgba(0, 240, 255, 0.1);
  }
  
  .message-item.unread {
    border-color: #00f0ff;
    background: rgba(0, 240, 255, 0.1);
  }
  
  .message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .sender {
    font-weight: bold;
    color: #00f0ff;
  }
  
  .date {
    color: #a0a0a0;
    font-size: 0.9rem;
  }
  
  .message-preview {
    color: #fff;
    margin: 0;
    font-size: 0.9rem;
  }
  
  .message-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
  }
  
  .action-btn {
    background: none;
    border: none;
    color: #00f0ff;
    cursor: pointer;
    padding: 5px;
    transition: all 0.3s ease;
    opacity: 0.7;
  }
  
  .action-btn:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  .action-btn.delete {
    color: #ff4444;
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #00f0ff;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    line-height: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
    margin: 0;
  }
  
  .close-btn:hover {
    background: rgba(0, 240, 255, 0.1);
    transform: rotate(90deg);
  }
  
  @media (max-width: 768px) {
    .dashboard-stats {
      flex-direction: column;
    }
  
    .messages-modal {
      /* width: 95%; */
      padding: 20px;
    }
  
    .dashboard-grid {
      flex-direction: column;
    }
  
    .dashboard-column {
      width: 100%;
    }
  
    .card {
      margin-bottom: 15px;
    }
  
    .admin-nav {
      flex-direction: column;
      align-items: stretch;
    }
  
    .admin-nav button {
      max-width: 100%;
    }
  }
  
  /* Experience Section Styles */
  .experience-section {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(0, 240, 255, 0.2);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .experience-timeline {
    position: relative;
    padding-left: 20px;
  }
  
  .experience-timeline::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background: linear-gradient(to bottom, 
      rgba(0, 240, 255, 0.5),
      rgba(0, 240, 255, 0.1)
    );
  }
  
  .experience-item {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(0, 240, 255, 0.1);
    transition: all 0.3s ease;
  }
  
  .experience-item::before {
    content: '';
    position: absolute;
    left: -24px;
    top: 50%;
    width: 8px;
    height: 8px;
    background: #00f0ff;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
  }
  
  .experience-item:hover {
    transform: translateX(5px);
    background: rgba(0, 240, 255, 0.08);
  }
  
  .experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .experience-title {
    color: #00f0ff;
    font-size: 1.1rem;
    margin: 0;
  }
  
  .experience-date {
    color: rgba(0, 240, 255, 0.7);
    font-size: 0.8rem;
    background: rgba(0, 240, 255, 0.1);
    padding: 2px 8px;
    border-radius: 12px;
  }
  
  .experience-company {
    color: #a0a0a0;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .experience-description {
    color: #a0a0a0;
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .experience-timeline {
      padding-left: 15px;
    }
  
    .experience-item {
      padding: 0.8rem;
    }
  
    .experience-item::before {
      left: -19px;
    }
  }
  
  /* Add to existing styles */
  .contact-options {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .contact-btn.outline {
    padding: 6px 12px;
    font-size: 0.85rem;
    background: transparent;
    border: 1px solid rgba(0, 240, 255, 0.3);
    border-radius: 4px;
    color: #00f0ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.3s ease;
  }
  
  .contact-btn.outline:hover {
    background: rgba(0, 240, 255, 0.1);
    transform: translateY(-2px);
  }
  
  .contact-btn.outline svg {
    font-size: 1rem;
  }
  
  .contact-btn.outline.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    border-color: rgba(0, 240, 255, 0.1);
    color: rgba(0, 240, 255, 0.5);
  }
  
  .contact-btn.outline.disabled:hover {
    background: transparent;
    transform: none;
  }
  
  .badges-overview {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 200px;
  }
  
  .badges-summary {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .earned-count {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .badge-icon {
    font-size: 1.5rem;
  }
  
  .next-badge {
    font-size: 0.9rem;
    color: #666;
    display: flex;
    flex-direction: column;
  }
  
  .next-label {
    font-size: 0.8rem;
    color: #888;
  }
  
  .badges-progress {
    margin-top: 2rem;
    padding: 1.5rem;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .earned-badges, .next-badges {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .badge-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background: #f8f9fa;
    transition: transform 0.2s;
  }
  
  .badge-item:hover {
    transform: translateY(-2px);
  }
  
  .badge-item.earned {
    background: #e8f5e9;
  }
  
  .badge-item.locked {
    opacity: 0.7;
  }
  
  .badge-icon {
    font-size: 2rem;
    color: #4caf50;
  }
  
  .badge-icon.locked {
    color: #9e9e9e;
  }
  
  .badge-info h4 {
    margin: 0 0 0.5rem 0;
    color: #2c3e50;
  }
  
  .badge-info p {
    margin: 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  .badge-tips {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #2196f3;
    font-style: italic;
  }
  
  /* Badge Modal Styles */
  .badges-modal {
    background: linear-gradient(135deg, #1a1f2e, #0a0e17);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 15px;
    padding: 2rem;
    width: 90%;
    max-width: 1000px;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
  }
  
  .badges-modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .badges-modal h3 {
    color: #00f0ff;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  }
  
  .earned-badges-grid, .upcoming-badges-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .badge-card {
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    gap: 1rem;
    transition: all 0.3s ease;
  }
  
  .badge-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.1);
  }
  
  .badge-card.earned {
    background: rgba(0, 240, 255, 0.1);
  }
  
  .badge-icon-large {
    font-size: 3rem;
    color: #00f0ff;
    text-shadow: 0 0 15px rgba(0, 240, 255, 0.5);
  }
  
  .badge-icon-large.locked {
    opacity: 0.5;
  }
  
  .badge-info h4 {
    color: #00f0ff;
    margin: 0 0 0.5rem 0;
  }
  
  .badge-info p {
    color: #a0a0a0;
    font-size: 0.9rem;
    margin: 0 0 1rem 0;
  }
  
  .earned-date {
    font-size: 0.8rem;
    color: #00f0ff;
    opacity: 0.7;
  }
  
  .badge-requirements {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    padding: 0.8rem;
    margin-top: 1rem;
  }
  
  .badge-requirements h5 {
    color: #00f0ff;
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
  }
  
  .badge-requirements ul {
    margin: 0;
    padding-left: 1.2rem;
    color: #a0a0a0;
    font-size: 0.85rem;
  }
  
  .badge-requirements li {
    margin-bottom: 0.3rem;
  }
  
  /* Update stat card styles */
  .stat-card.badges-overview {
    cursor: pointer;
  }
  
  .stat-card.badges-overview:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
  }
  
  .next-badge-name {
    color: #00f0ff;
    font-size: 0.9rem;
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .badges-modal {
      width: 95%;
      padding: 1.5rem;
    }
  
    .earned-badges-grid, .upcoming-badges-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Update the badge stat card styles */
  .stat-card.badges-overview .badge-icon {
    font-size: 2.5rem;
    margin-right: 1rem;
    color: #00f0ff;
    text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
  }
  
  .stat-card.badges-overview {
    display: flex;
    align-items: center;
    padding: 1.2rem;
  }
  
  .badges-summary {
    flex: 1;
  }
  
  .profile-completion.card {
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    transition: all 0.3s ease;
    padding: 1.5rem;
  }
  
  .profile-completion h3 {
    color: #00f0ff;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(0, 240, 255, 0.2);
    padding-bottom: 0.75rem;
  }
  
  .progress-container {
    margin: 1.5rem 0;
  }
  
  .progress-bar {
    background: rgba(26, 31, 46, 0.8);
    border-radius: 10px;
    height: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 240, 255, 0.1);
  }
  
  .progress {
    background: linear-gradient(90deg, #00f0ff, #0066ff);
    height: 100%;
    transition: width 0.6s ease-in-out;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  }
  
  .progress-container p {
    color: #00f0ff;
    font-size: 0.9rem;
    margin: 0.5rem 0;
    text-align: right;
  }
  
  .completion-details {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .completion-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a0a0a0;
    font-size: 0.9rem;
    padding: 0.5rem;
    background: rgba(26, 31, 46, 0.8);
    border-radius: 6px;
    border: 1px solid rgba(0, 240, 255, 0.1);
  }
  
  .completion-item.completed {
    color: #00f0ff;
    background: rgba(0, 240, 255, 0.05);
  }
  
  .completion-item svg {
    font-size: 1rem;
  }
  
  .incomplete-icon {
    color: #ff4444;
  }
  
  .complete-icon {
    color: #00f0ff;
  }
  
  .next-steps {
    margin: 1.5rem 0;
    padding: 1rem;
    background: rgba(26, 31, 46, 0.8);
    border-radius: 8px;
    border: 1px solid rgba(0, 240, 255, 0.2);
  }
  
  .next-steps h4 {
    color: #00f0ff;
    font-size: 0.95rem;
    margin-bottom: 1rem;
  }
  
  .complete-profile-btn {
    width: 100%;
    padding: 0.8rem;
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.3);
    color: #00f0ff;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .complete-profile-btn:hover {
    background: rgba(0, 240, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .profile-completion.card {
      padding: 1rem;
    }
  
    .completion-item {
      font-size: 0.85rem;
      padding: 0.4rem;
    }
  
    .next-steps {
      padding: 0.75rem;
    }
  
    .complete-profile-btn {
      padding: 0.7rem;
      font-size: 0.9rem;
    }
  }
  
  /* Event List Styles */
  .event-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem 0;
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .event-item:hover {
    background: rgba(0, 240, 255, 0.1);
    transform: translateX(5px);
  }
  
  .event-title {
    color: #fff;
    font-size: 0.95rem;
  }
  
  .event-date {
    color: #00f0ff;
    font-size: 0.85rem;
    padding: 0.3rem 0.8rem;
    background: rgba(0, 240, 255, 0.1);
    border-radius: 12px;
  }
  
  /* Product List Styles */
  .product-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem 0;
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .product-item:hover {
    background: rgba(0, 240, 255, 0.1);
    transform: translateX(5px);
  }
  
  .product-name {
    color: #fff;
    font-size: 0.95rem;
  }
  
  .product-price {
    color: #00f0ff;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  /* Invitation Card Styles */
  .invitation-details {
    background: rgba(26, 31, 46, 0.9);
    border-radius: 12px;
    padding: 1.5rem;
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  
  .invitation-code {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    padding: 0.75rem 1rem;
  }
  
  .code-value {
    color: #00f0ff;
    font-family: monospace;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: 500;
    text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  }
  
  .copy-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;
    height: 36px;
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    color: #00f0ff;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .copy-btn svg {
    font-size: 1rem;
  }
  
  .copy-btn:hover {
    background: rgba(0, 240, 255, 0.15);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 240, 255, 0.1);
  }
  
  .copy-btn:active {
    transform: translateY(0);
  }
  
  .invitation-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 0.5rem 0;
  }
  
  .stat-item {
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 10px;
    padding: 1.2rem;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .stat-item:hover {
    background: rgba(0, 240, 255, 0.08);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 240, 255, 0.1);
  }
  
  .stat-value {
    color: #00f0ff;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    text-shadow: 0 0 10px rgba(0, 240, 255, 0.3);
  }
  
  .stat-label {
    color: #a0a0a0;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  /* Ensure Link components have the same styling */
  .admin-nav a.btn {
    text-decoration: none;
    color: #011516;
  }

  .admin-nav a.btn.active {
    color: #1a1f2e;
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    .admin-nav .btn {
      height: 44px;
      min-width: 120px;
      font-size: 0.9rem;
      padding: 1rem;
    }
  }

  .next-steps {
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(0, 240, 255, 0.1);
  }

  .next-steps h4 {
    color: #00f0ff;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .next-steps ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .next-steps li {
    color: #a0a0a0;
    font-size: 0.85rem;
    padding: 0.3rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .next-steps li::before {
    content: "→";
    color: #00f0ff;
  }

  /* Update Services Section Styles */
  .services-section {
    background: rgba(26, 31, 46, 0.8);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    margin-top: 1.5rem;
  }

  .services-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  }

  .services-header h3 {
    color: #00f0ff;
    font-size: 1.2rem;
    margin: 0;
  }

  .services-completion {
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    padding: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  .service-card {
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .service-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
  }

  .service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  }

  .service-content {
    padding: 1.2rem;
  }

  .service-title {
    color: #00f0ff;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .service-description {
    color: #a0a0a0;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .service-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 240, 255, 0.1);
  }

  .service-price {
    color: #00f0ff;
    font-weight: 500;
  }

  .service-actions {
    display: flex;
    gap: 0.5rem;
  }

  .service-btn {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .service-btn.edit {
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.2);
    color: #00f0ff;
  }

  .service-btn.delete {
    background: rgba(255, 68, 68, 0.1);
    border: 1px solid rgba(255, 68, 68, 0.2);
    color: #ff4444;
  }

  .service-btn:hover {
    transform: translateY(-2px);
  }

  .add-service-btn {
    width: 100%;
    padding: 1rem;
    margin-top: 1.5rem;
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    color: #00f0ff;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
  }

  .add-service-btn:hover {
    background: rgba(0, 240, 255, 0.15);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
  }

  .service-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .stat-box {
    background: rgba(26, 31, 46, 0.8);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
  }

  .stat-box .stat-value {
    color: #00f0ff;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  .stat-box .stat-label {
    color: #a0a0a0;
    font-size: 0.9rem;
  }

  .service-filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
  }

  .filter-btn {
    padding: 0.5rem 1rem;
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 20px;
    color: #a0a0a0;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .filter-btn.active {
    background: rgba(0, 240, 255, 0.1);
    border-color: #00f0ff;
    color: #00f0ff;
  }

  .service-empty-state {
    text-align: center;
    padding: 3rem 1rem;
    background: rgba(26, 31, 46, 0.8);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    color: #a0a0a0;
  }

  .service-empty-state svg {
    font-size: 3rem;
    color: #00f0ff;
    margin-bottom: 1rem;
  }

  .service-empty-state h4 {
    color: #00f0ff;
    margin-bottom: 0.5rem;
  }

  .service-empty-state p {
    margin-bottom: 1.5rem;
  }

  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .services-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

  @media (max-width: 768px) {
    .services-section {
      padding: 1rem;
    }

    .services-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .service-stats {
      grid-template-columns: repeat(2, 1fr);
    }

    .service-filters {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      -webkit-overflow-scrolling: touch;
    }

    .filter-btn {
      flex: 0 0 auto;
    }
  }

  @media (max-width: 480px) {
    .service-stats {
      grid-template-columns: 1fr;
    }

    .service-card {
      margin: 0 -1rem;
      border-radius: 0;
    }

    .service-image {
      height: 180px;
    }

    .service-content {
      padding: 1rem;
    }

    .service-actions {
      flex-direction: column;
    }

    .service-btn {
      width: 100%;
      text-align: center;
    }
  }

  /* Loading States */
  .service-loading {
    background: rgba(26, 31, 46, 0.8);
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    color: #00f0ff;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    margin: 1rem auto;
    border: 3px solid rgba(0, 240, 255, 0.1);
    border-top-color: #00f0ff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  /* Service Modal Styles */
  .service-modal {
    background: rgba(26, 31, 46, 0.95);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 12px;
    padding: 1.5rem;
    max-width: 600px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
  }

  .service-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-group label {
    color: #00f0ff;
    font-size: 0.95rem;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 0.75rem;
    background: rgba(10, 14, 23, 0.8);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 6px;
    color: #fff;
    font-size: 0.95rem;
  }

  .form-group textarea {
    min-height: 120px;
    resize: vertical;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(0, 240, 255, 0.2);
  }

  /* Add these styles for welcome message */
  .welcome-message {
    color: #fff;
    line-height: 1.6;
    font-size: 0.95rem;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(0, 240, 255, 0.1);
  }

  .welcome-message a {
    color: #00f0ff;
    text-decoration: none;
    transition: all 0.2s ease;
  }

  .welcome-message a:hover {
    text-decoration: underline;
    opacity: 0.9;
  }

  /* Update message-item styles for welcome message */
  .message-item.welcome {
    background: rgba(0, 240, 255, 0.08);
    border: 1px solid rgba(0, 240, 255, 0.2);
  }

  .message-item.welcome .sender {
    color: #00f0ff;
    font-size: 1.1rem;
  }

  .message-item.welcome .message-preview {
    color: rgba(255, 255, 255, 0.9);
  }

  /* Update message content styles */
  .message-content {
    margin: 1.5rem 0;
    /* max-height: 60vh; */
    overflow-y: auto;
    padding-right: 1rem;
  }

  .message-content::-webkit-scrollbar {
    width: 6px;
  }

  .message-content::-webkit-scrollbar-track {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 3px;
  }

  .message-content::-webkit-scrollbar-thumb {
    background: rgba(0, 240, 255, 0.2);
    border-radius: 3px;
  }

  /* Update message view styles */
  .message-details {
    padding: 1.5rem;
    background: rgba(26, 31, 46, 0.8);
    border-radius: 12px;
    border: 1px solid rgba(0, 240, 255, 0.2);
  }

  .sender-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 240, 255, 0.2);
  }

  .sender-info h3 {
    color: #00f0ff;
    font-size: 1.2rem;
    margin: 0;
  }

  .message-date {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    margin: 0;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem 0;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #fff;
  }

  .contact-item svg {
    color: #00f0ff;
    font-size: 1.2rem;
  }

  .contact-actions {
    display: flex;
    gap: 0.8rem;
    margin-left: auto;
  }

  .contact-btn {
    padding: 0.5rem 1rem;
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 6px;
    color: #00f0ff;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .contact-btn:hover {
    background: rgba(0, 240, 255, 0.15);
    transform: translateY(-1px);
  }

  /* Update message list styles */
  .messages-list .message-item {
    padding: 1.2rem;
    cursor: pointer;
  }

  .messages-list .message-preview {
    margin: 0.5rem 0;
    color: rgba(255, 255, 255, 0.8);
  }

  /* Back button styling */
  .back-to-messages {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #00f0ff;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    transition: all 0.2s ease;
  }

  .back-to-messages:hover {
    opacity: 0.8;
    transform: translateX(-2px);
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .message-details {
      padding: 1rem;
    }

    .contact-info {
      padding: 0.8rem;
    }

    .contact-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .contact-actions {
      margin-left: 0;
      width: 100%;
      justify-content: flex-start;
    }

    .contact-btn {
      flex: 1;
      justify-content: center;
    }
  }

  /* Message list item styles */
  .message-item {
    position: relative;
    padding: 1.2rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .message-item:hover {
    background: rgba(0, 240, 255, 0.08);
  }

  .message-actions {
    display: flex;
    gap: 0.8rem;
    margin-top: 0.8rem;
    padding-top: 0.8rem;
    border-top: 1px solid rgba(0, 240, 255, 0.1);
  }

  .message-actions .action-btn {
    background: none;
    border: none;
    color: #a0a0a0;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.4rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  .message-actions .action-btn:hover {
    color: #00f0ff;
    transform: translateY(-1px);
  }

  .message-actions .action-btn.delete:hover {
    color: #ff4444;
  }

  /* Starred message styles */
  .message-item.starred {
    background: rgba(0, 240, 255, 0.05);
    border-left: 3px solid #00f0ff;
  }

  .message-actions .action-btn .starred {
    color: #00f0ff;
  }

  /* Unread message styles */
  .message-item.unread {
    background: rgba(0, 240, 255, 0.08);
  }

  .message-item.unread .message-preview {
    color: #fff;
    font-weight: 500;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .message-actions {
      justify-content: flex-end;
    }

    .message-actions .action-btn {
      font-size: 1.1rem;
      padding: 0.5rem;
    }
  }

  /* Single Message View Styles */
  .message-details {
    background: rgba(26, 31, 46, 0.8);
    border-radius: 12px;
    overflow: hidden;
  }

  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border-bottom: 1px solid rgba(0, 240, 255, 0.1);
  }

  .back-btn {
    background: none;
    border: none;
    color: #00f0ff;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    transition: all 0.2s ease;
  }

  .back-btn:hover {
    transform: translateX(-3px);
  }

  .message-actions {
    display: flex;
    gap: 0.8rem;
  }

  .message-actions .action-btn {
    background: none;
    border: none;
    color: #a0a0a0;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 0.4rem;
    transition: all 0.2s ease;
  }

  .message-actions .action-btn:hover {
    color: #00f0ff;
    transform: translateY(-2px);
  }

  .message-actions .action-btn.delete:hover {
    color: #ff4444;
  }

  .message-actions .starred {
    color: #00f0ff;
  }

  .message-body {
    padding: 1.5rem;
  }

  .sender-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }

  .sender-details h3 {
    color: #00f0ff;
    font-size: 1.2rem;
    margin: 0 0 0.3rem 0;
  }

  .message-date {
    color: #a0a0a0;
    font-size: 0.9rem;
  }

  .message-badge {
    background: rgba(0, 240, 255, 0.1);
    color: #00f0ff;
    padding: 0.3rem 0.8rem;
    border-radius: 12px;
    font-size: 0.8rem;
    border: 1px solid rgba(0, 240, 255, 0.2);
  }

  .message-content {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
    line-height: 1.6;
  }

  .contact-section {
    background: rgba(0, 240, 255, 0.05);
    border-radius: 8px;
    padding: 1.5rem;
  }

  .contact-section h4 {
    color: #00f0ff;
    margin: 0 0 1rem 0;
    font-size: 1rem;
  }

  .contact-options {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .contact-option {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
    color: #00f0ff;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    text-align: left;
  }

  .contact-option:hover {
    background: rgba(0, 240, 255, 0.1);
    transform: translateX(3px);
  }

  .contact-option svg {
    font-size: 1.2rem;
    flex-shrink: 0;
  }

  .option-details {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1;
  }

  .option-label {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .option-value {
    color: #a0a0a0;
    font-size: 0.85rem;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .message-body {
      padding: 1rem;
    }

    .contact-option {
      padding: 0.8rem;
    }

    .message-content {
      padding: 1rem;
    }
  }

  @media (max-width: 480px) {
    .sender-info {
      flex-direction: column;
      gap: 0.8rem;
    }

    .message-badge {
      align-self: flex-start;
    }
  }

  /* Add styles for reply section */
  .message-reply-section {
    margin-top: 1.5rem;
    padding: 1rem;
    background: rgba(0, 240, 255, 0.05);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 8px;
  }

  .reply-input {
    width: 100%;
    min-height: 100px;
    padding: 0.8rem;
    background: rgba(26, 31, 46, 0.8);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 6px;
    color: #fff;
    font-size: 0.95rem;
    margin-bottom: 1rem;
    resize: vertical;
  }

  .reply-input:focus {
    outline: none;
    border-color: #00f0ff;
  }

  .reply-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.2rem;
    background: rgba(0, 240, 255, 0.1);
    border: 1px solid rgba(0, 240, 255, 0.2);
    border-radius: 6px;
    color: #00f0ff;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.95rem;
  }

  .reply-btn:hover {
    background: rgba(0, 240, 255, 0.15);
    transform: translateY(-2px);
  }

  .reply-btn svg {
    font-size: 1.1rem;
  }

  .my-services-section {
    padding: 1rem;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .services-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .service-row {
    background: rgba(13, 17, 23, 0.4);
    border: 1px solid rgba(0, 240, 255, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
  }

  .service-info {
    flex: 1;
    min-width: 0;
  }

  .service-main {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.75rem;
  }

  .service-main h3 {
    margin: 0;
    color: #fff;
    font-size: 1.1rem;
  }

  .service-category {
    background: rgba(0, 240, 255, 0.1);
    color: #00f0ff;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.9rem;
  }

  .service-description {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .service-meta {
    display: flex;
    gap: 1.5rem;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }

  .service-meta .status {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }

  .status.active {
    background: rgba(0, 255, 0, 0.1);
    color: #4ade80;
  }

  .status.inactive {
    background: rgba(255, 0, 0, 0.1);
    color: #ff3b30;
  }

  .service-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .no-services {
    text-align: center;
    padding: 3rem;
    color: rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 768px) {
    .service-row {
      flex-direction: column;
    }

    .service-actions {
      flex-direction: row;
      justify-content: flex-end;
    }
  }