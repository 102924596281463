/* Store container with gradient background */
.store-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-primary);
}

/* Store header with gradient background */
.store-header {
  position: relative;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, rgba(26, 31, 46, 0.8), rgba(0, 240, 255, 0.1));
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.store-header h1 {
  color: #00f0ff;
  margin: 0;
  font-size: 2.5rem;
}

.store-actions {
  display: flex;
  gap: 1rem;
}

.store-actions button {
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.3);
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.store-actions button:hover {
  background: rgba(0, 240, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

/* Products grid */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Product card with hover effects */
.product-card {
  background: rgba(26, 31, 46, 0.8);
  border: 1px solid rgba(0, 240, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-card:hover {
  transform: translateY(-5px);
  border-color: rgba(0, 240, 255, 0.3);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.product-images {
  position: relative;
  margin-bottom: 1rem;
}

.image-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}

.product-card-footer {
  margin-top: auto;
  padding-top: 1rem;
}

.read-more {
  color: #00f0ff;
  cursor: pointer;
  font-size: 0.9em;
}

/* Multiple images input styling */
.image-input-group {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.remove-image {
  padding: 0.5rem;
  background: rgba(255, 59, 59, 0.1);
  color: #ff3b3b;
}

.add-image {
  width: auto;
  margin-top: 0.5rem;
}

/* Color tags styling */
.color-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.color-tag {
  background: rgba(0, 240, 255, 0.1);
  padding: 0.3rem 0.8rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.color-tag button {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.product-card h3 {
  color: #00f0ff;
  margin: 1rem 0;
  font-size: 1.3rem;
}

.product-card p {
  color: var(--text-secondary);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.price {
  color: #00f0ff;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Admin actions */
.admin-actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
}

.admin-actions button {
  background: rgba(0, 0, 0, 0.5);
  color: #00f0ff;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-actions button:hover {
  background: rgba(0, 240, 255, 0.2);
}

/* Modal Base Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5vh;
  overflow: hidden;
}

.modal {
  position: relative;
  background: linear-gradient(135deg, #1a1f2e, #0a0e17);
  border-radius: 15px;
  border: 1px solid rgba(0, 240, 255, 0.2);
  width: 90%;
  max-width: 600px;
  max-height: 85vh;
  overflow-y: auto;
  margin: 0;
}

.modal-content {
  padding: 2rem;
}

/* Form Styles */
.modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.2rem;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #fff;
}

/* Prevent body scroll when modal is open */
body.modal-open {
  overflow: hidden;
}

/* Clean scrollbar styles */
.modal::-webkit-scrollbar {
  width: 6px;
}

.modal::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.modal::-webkit-scrollbar-thumb {
  background-color: rgba(0, 240, 255, 0.3);
  border-radius: 3px;
}

/* Cart and Order items */
.cart-item,
.order-item {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 240, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total {
  color: #00f0ff;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1.5rem 0;
  text-align: right;
}

/* Responsive design */
@media (max-width: 768px) {
  .store-header {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .store-actions {
    width: 100%;
    justify-content: center;
  }

  .products-grid {
    grid-template-columns: 1fr;
  }
}

.store-page {
  min-height: 100vh;
  color: #fff;
}

/* Hero Section */
.hero.section {
  background: linear-gradient(to bottom, rgba(0, 240, 255, 0.1), transparent);
  padding: 4rem 0;
  text-align: center;
  margin-bottom: 3rem;
}

.hero .section-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #00f0ff;
}

.hero .subtitle {
  font-size: 1.2rem;
  color: #a0a0a0;
}

/* Store Content */
.store-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Store Actions */
.store-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 2rem;
}

.store-actions button {
  background: rgba(0, 240, 255, 0.05);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.store-actions button:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

/* Keep existing styles for products-grid and below, but update these specific classes: */

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.product-card {
  background: rgba(0, 240, 255, 0.03);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, transparent, #00f0ff, transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-card:hover::before {
  opacity: 1;
}

.product-card:hover {
  transform: translateY(-3px);
  background: rgba(0, 240, 255, 0.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero .section-title {
    font-size: 2.5rem;
  }
  
  .store-content {
    padding: 0 1rem;
  }
  
  .store-actions {
    flex-direction: column;
    align-items: stretch;
  }
  
  .products-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 1rem 0;
  }
}

/* Cart Modal Specific Styles */
.cart-item button,
.order-item button {
  background: rgba(255, 59, 59, 0.1);
  color: #ff3b3b;
  border: 1px solid rgba(255, 59, 59, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cart-item button:hover {
  background: rgba(255, 59, 59, 0.2);
  transform: translateY(-2px);
}

/* Add to Cart Button in Product Card */
.product-card button {
  width: 100%;
  background: rgba(0, 240, 255, 0.1);
  color: #00f0ff;
  border: 1px solid rgba(0, 240, 255, 0.2);
  padding: 0.8rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.product-card button:hover:not(:disabled) {
  background: rgba(0, 240, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 240, 255, 0.2);
}

.product-card button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Close button for modals */
.modal-content .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #00f0ff;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  width: auto;
  margin: 0;
}

.modal-content .close-button:hover {
  transform: rotate(90deg);
}

.modal-content select {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 240, 255, 0.2);
  padding: 0.8rem;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
}

.size-options {
  margin: 1rem 0;
}

.checkbox-group {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  width: auto;
  margin: 0;
}

.modal-content input[type="url"] {
  font-family: monospace;
}

/* Image Slider Modal */
.image-slider-modal .modal-content {
  max-width: 95vw;
  width: auto;
  padding: 2rem;
  background: rgba(26, 31, 46, 0.98);
}

.image-slider {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

/* Remove nav buttons */
.nav-button,
.nav-button.prev,
.nav-button.next {
  display: none;
}

/* Update thumbnails to be more prominent */
.image-thumbnails {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.image-thumbnails img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s;
  border-radius: 4px;
  border: 2px solid transparent;
}

.image-thumbnails img.active {
  opacity: 1;
  border-color: #00f0ff;
}

.image-thumbnails img:hover {
  opacity: 0.8;
}

/* Ensure close button is visible */
.image-slider-modal .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.5);
  color: #00f0ff;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1002;
}

.image-slider-modal .close-button:hover {
  background: rgba(0, 240, 255, 0.2);
  transform: rotate(90deg);
}

/* Variant Selection */
.variant-selection {
  margin: 1.5rem 0;
}

.variant-field {
  margin-bottom: 1.5rem;
}

.variant-field label {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
}

.variant-field select,
.variant-field input {
  width: 100%;
  padding: 0.8rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
}

.variant-field select option {
  background: #1a1f2e;
  color: #fff;
}

.add-to-cart-button {
  width: 100%;
  padding: 1rem;
  background: #00f0ff;
  color: #0a0e17;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-to-cart-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.add-to-cart-button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

/* Variation picker specific styles */
.variation-picker-modal .modal-content {
  max-width: 600px;
}

.customer-details {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 240, 255, 0.2);
}

.customer-details h3 {
  color: #00f0ff;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
}

.form-group input,
.form-group textarea {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 240, 255, 0.2);
  padding: 0.8rem;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
  min-height: 80px;
}

.checkout-button {
  background: #00f0ff !important;
  color: #0a0e17 !important;
  font-weight: bold;
  margin-top: 2rem;
}

.checkout-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(0, 240, 255, 0.3) !important;
}

.cart-items {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.1);
}

.cart-item-details {
  flex: 1;
}

.cart-item-details h4 {
  margin: 0;
  color: #00f0ff;
  font-size: 1rem;
}

.item-variants {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.variant {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(0, 240, 255, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}

.item-price {
  margin: 0 1rem;
  font-weight: bold;
  color: #00f0ff;
}

.remove-item {
  background: none !important;
  border: none !important;
  padding: 0.5rem !important;
  color: rgba(255, 255, 255, 0.5) !important;
  cursor: pointer;
  transition: color 0.3s ease;
  width: auto !important;
  margin: 0 !important;
}

.remove-item:hover {
  color: #ff4444 !important;
}

.auth-prompt {
  text-align: center;
  padding: 2rem;
}

.auth-prompt h2 {
  color: #00f0ff;
  margin-bottom: 1rem;
}

.auth-prompt p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.auth-button {
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.auth-button:first-child {
  background: #00f0ff;
  color: #0a0e17;
}

.auth-button.register {
  background: transparent;
  border: 1px solid #00f0ff;
  color: #00f0ff;
}

.auth-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.2);
}

.product-modal .modal-content {
  max-width: 1200px;
  width: 90%;
  padding: 2rem;
}

.product-view-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.product-images-section {
  width: 100%;
}

.product-details-section {
  padding: 1rem;
}

.product-details-section h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.product-details-section .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

.product-description {
  margin-bottom: 2rem;
}

.specifications ul {
  list-style: none;
  padding: 0;
}

.specifications li {
  margin-bottom: 0.5rem;
}

.available-options {
  margin: 2rem 0;
}

.color-list, .size-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.color-option, .size-option {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f8f8f8;
}

.add-to-cart-btn {
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.add-to-cart-btn:hover {
  background: #0056b3;
}

@media (max-width: 768px) {
  .product-view-container {
    grid-template-columns: 1fr;
  }
}

/* Update product price styling */
.product-price {
  font-size: 2rem;
  font-weight: bold;
  color: #00f0ff;
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

/* Product variations styling */
.product-variations {
  margin: 2rem 0;
}

.variation-section {
  margin-bottom: 2rem;
}

.variation-section h3 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.variation-options {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.variation-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.variation-option:hover {
  background: rgba(0, 240, 255, 0.1);
  transform: translateY(-2px);
}

.color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.variation-label {
  color: #fff;
}

.size-chip {
  min-width: 50px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

/* Update specifications styling */
.specifications {
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.specifications h3 {
  color: #00f0ff;
  margin-bottom: 1rem;
}

.specifications ul {
  list-style: none;
  padding: 0;
}

.specifications li {
  margin-bottom: 1rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.specifications strong {
  color: rgba(0, 240, 255, 0.8);
}

/* Update add to cart button */
.add-to-cart-btn {
  width: 100%;
  padding: 1.2rem;
  margin-top: 2rem;
  background: #00f0ff;
  color: #0a0e17;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.add-to-cart-btn:hover {
  background: #33f5ff;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 240, 255, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-price {
    font-size: 1.5rem;
    padding: 0.8rem;
  }
  
  .variation-options {
    gap: 0.5rem;
  }
  
  .variation-option {
    padding: 0.4rem 0.8rem;
  }
}

/* Update variation option to show selected state */
.variation-option.selected {
  background: rgba(0, 240, 255, 0.2);
  border-color: #00f0ff;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 240, 255, 0.2);
}

/* Quantity selector styles */
.quantity-selector {
  margin: 2rem 0;
}

.quantity-selector h3 {
  color: #00f0ff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 0.5rem;
  width: fit-content;
}

.quantity-controls button {
  background: rgba(0, 240, 255, 0.1);
  border: none;
  color: #00f0ff;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.quantity-controls button:hover {
  background: rgba(0, 240, 255, 0.2);
}

.quantity-controls input {
  width: 60px;
  text-align: center;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  padding: 0.3rem;
}

.quantity-controls input::-webkit-inner-spin-button,
.quantity-controls input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Disable button styles */
.add-to-cart-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Orders Modal Styles */
.orders-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 70vh;
  overflow-y: auto;
}

.order-card {
  background: rgba(0, 240, 255, 0.05);
  border: 1px solid rgba(0, 240, 255, 0.2);
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.order-card:hover {
  transform: translateY(-2px);
  background: rgba(0, 240, 255, 0.1);
}

.order-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.order-card-header h3 {
  color: #00f0ff;
  margin: 0;
}

.order-status {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.order-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.order-summary-brief {
  color: rgba(255, 255, 255, 0.8);
}

.order-date {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.view-details-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: rgba(0, 240, 255, 0.1);
  border: none;
  color: #00f0ff;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-details-btn:hover {
  background: rgba(0, 240, 255, 0.2);
}

/* Order Details Modal */
.order-details-modal {
  max-width: 800px;
}

.order-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 240, 255, 0.2);
}

.order-section {
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
}

.order-section h4 {
  color: #00f0ff;
  margin-bottom: 1rem;
}

.order-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.item-info h5 {
  margin: 0;
  color: #fff;
}

.item-details {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.delivery-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.delivery-details p {
  margin: 0;
}

.order-summary {
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.summary-row.total {
  border-top: 1px solid rgba(0, 240, 255, 0.2);
  margin-top: 1rem;
  padding-top: 1rem;
  font-weight: bold;
  color: #00f0ff;
}

.no-orders {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  padding: 2rem;
}

/* Product options styling */
.product-options {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  padding: 1rem;
  background: rgba(0, 240, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(0, 240, 255, 0.2);
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #fff;
}

.checkbox-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #00f0ff;
}

/* Update disabled button styles */
.add-to-cart-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  transform: none;
  box-shadow: none;
}

/* Add out of stock overlay to product card */
.product-card.out-of-stock .product-images::after {
  content: 'Out of Stock';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
} 